import { useEffect } from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import ImageTool from "@editorjs/image";
import Quote from "@editorjs/quote";
import CodeTool from "@editorjs/code";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import Marker from "@editorjs/marker";
import Warning from "@editorjs/warning";
import RawTool from "@editorjs/raw";
import Paragraph from "@editorjs/paragraph";
import { getValue } from "@utils/lodash";
import { config } from "env";
import { mediaUpload } from "@services/upload.service";
import { ArrowLeft, ArrowRight } from "lucide-react";

const ContentEditor = ({
  params,
  navigate,
  request,
  setRequest,
  isDataFetched,
  viewOnly,
  articleRequestCopy,
  setArticleRequestCopy,
  handleSubmitArticle,
  previousArticle,
  nextArticle,
}) => {
  useEffect(() => {
    const editor = new EditorJS({
      holder: "editorjs",
      tools: {
        header: {
          class: Header,
          inlineToolbar: ["link"],
        },
        list: {
          class: List,
          inlineToolbar: true,
        },
        embed: Embed,
        table: Table,
        image: {
          class: ImageTool,
          config: {
            uploader: {
              uploadByFile: async (file) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("filename", file.name);
                formData.append("is_public", "true");
                formData.append("collection", "public-images");

                try {
                  const response = await mediaUpload(formData);
                  const data = response?.data;

                  if (data?.key) {
                    return {
                      success: 1,
                      file: {
                        url: `https://${config.URL}/${data.key}`,
                      },
                    };
                  } else {
                    throw new Error("Invalid upload response");
                  }
                } catch (error) {
                  return {
                    success: 0,
                    message: "Upload failed. Please try again.",
                  };
                }
              },
              uploadByUrl: async (url) => {
                try {
                  if (!url || !/^https?:\/\/\S+$/.test(url)) {
                    throw new Error("Invalid URL");
                  }
                  return {
                    success: 1,
                    file: {
                      url,
                    },
                  };
                } catch (error) {
                  return {
                    success: 0,
                    message: "Invalid URL. Please provide a valid image URL.",
                  };
                }
              },
            },
          },
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
          config: {
            quotePlaceholder: "Enter a quote",
            captionPlaceholder: "Quote's author",
          },
        },
        code: CodeTool,
        delimiter: Delimiter,
        inlineCode: InlineCode,
        marker: Marker,
        warning: {
          class: Warning,
          config: {
            titlePlaceholder: "Enter a title",
            messagePlaceholder: "Enter a message",
          },
        },
        raw: RawTool,
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
      },
      onChange: async (api) => {
        if (!viewOnly) {
          const content = await api.saver.save();
          setRequest((prev) => ({
            ...prev,
            description: content,
          }));
        }
      },
      data: getValue(request, "description", {}),
      placeholder: "Start writing your article here by typing /",
      disableInlineToolbar: true,
      readOnly: viewOnly,
    });

    return () => {
      editor.isReady
        .then(() => {
          editor.destroy();
        })
        .catch((e) => console.error("ERROR editor cleanup", e));
    };
  }, [setRequest, isDataFetched, viewOnly]);

  return (
    <div className="content-editor">
      <div className="editor-scrollable">
        <div className="content-inputs-wrapper">
          <input
            type="text"
            value={getValue(articleRequestCopy, "title", "")}
            onChange={(e) =>
              setArticleRequestCopy((prev) => ({
                ...prev,
                title: e.target.value,
              }))
            }
            onBlur={() => {
              if (getValue(articleRequestCopy, "title", "") !== "") {
                handleSubmitArticle({
                  folder_id: getValue(articleRequestCopy, "folder_id", ""),
                  title: getValue(articleRequestCopy, "title", ""),
                });
              }
            }}
            className="article-heading-input"
            placeholder="Untitled page"
            maxLength={144}
          />
          {/* <input
            type="textarea"
            value={getValue(articleRequestCopy, "title", "")}
            onChange={(e) =>
              setArticleRequestCopy((prev) => ({
                ...prev,
                title: e.target.value,
              }))
            }
            className="article-description-input mt-2"
            placeholder="Page description) optional"
            limit={144}
          /> */}
        </div>
        <div id="editorjs" className="article-content-viewer"></div>
        <div
          className={`article-nav-buttons-wrapper ${
            previousArticle && nextArticle ? "grid-cols-2" : ""
          }`}
        >
          {previousArticle && (
            <div
              className="article-nav-buttons"
              onClick={() =>
                navigate(
                  `/${getValue(params, "orgId", "")}/solutions/${getValue(
                    params,
                    "id",
                    ""
                  )}/folders/${getValue(
                    params,
                    "folderId",
                    ""
                  )}/article/${getValue(previousArticle, "id", "")}`
                )
              }
            >
              <ArrowLeft />
              <div className="d-flex flex-column gap-2">
                <span className="text-end">Previous</span>
                <p className="header_text__15 text-end">
                  {getValue(previousArticle, "title", "")}
                </p>
              </div>
            </div>
          )}
          {nextArticle && (
            <div
              className="article-nav-buttons"
              onClick={() =>
                navigate(
                  `/${getValue(params, "orgId", "")}/solutions/${getValue(
                    params,
                    "id",
                    ""
                  )}/folders/${getValue(
                    params,
                    "folderId",
                    ""
                  )}/article/${getValue(nextArticle, "id", "")}`
                )
              }
            >
              <div className="d-flex flex-column gap-2">
                <span>Next</span>
                <p className="header_text__15">
                  {getValue(nextArticle, "title", "")}
                </p>
              </div>
              <ArrowRight />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentEditor;
