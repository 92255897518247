import PendingDealComponent from "./components/TicketDetail";
import "./index.scss";
import { Badge, Collapse, Segmented } from "antd";
import { getValue } from "@utils/lodash";
import AddInfoPopup from "./common/AddPopup/AddInfoPopup";
import { createSubPipeline } from "@services/common.service";
import { toast } from "sonner";
import { generatePipelineAssociationPayload } from "@components/helpers/request-helper";
import SimpleReactValidator from "simple-react-validator";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { handleFormValidation } from "@components/custom/validation";
import { PlusCircle } from "lucide-react";
import TaskCompanies from "./components/TicketCompanies";
import TaskContacts from "./components/TicketContacts";
import PendingLoanComponent from "./components/TicketDetail/components";

function TicketsRightSection(props: any) {
  const [isRollsPopupVisibile, setRollsPopupVisisbility] = useState(false);
  const [
    isCompaniesRollsPopupVisibile,
    setCompaniesRollsPopupVisisbility,
  ] = useState(false);
  const handleOpenForm = (module: string) => {
    props.getForm(module);
    if (module === "contacts") {
      setRollsPopupVisisbility(true);
    } else {
      setCompaniesRollsPopupVisisbility(true);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                     Generating Contact Section                             */
  /* -------------------------------------------------------------------------- */
  const simpleValidatorContact = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const params = useParams();
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const handleSubmit = async (module: string) => {
    if (module) {
      // const formValid = simpleValidator.current.allValid();
      // if (!formValid) {
      //   simpleValidator.current.showMessages();
      //   forceUpdate(1);
      // } else {
      let fields = JSON.parse(JSON.stringify(props.form));
      let associations = [
        { api_name: props.module, record_id: getValue(params, `id`, "") },
      ];
      let payloadRequest = generatePipelineAssociationPayload(
        module,
        fields,
        props.staticFieldRequest,
        associations
      );
      try {
        setFormSubmitLoading(true);
        let resp = await createSubPipeline(payloadRequest, module);
        if (resp) {
          toast.success("Created & Associated Successfully");
          props.getData(false);
          simpleValidatorContact.current.hideMessages();
          forceUpdate(0);
          setRollsPopupVisisbility(false);
          setFormSubmitLoading(false);
        } else {
          setFormSubmitLoading(false);
        }
      } catch (error) {
        setFormSubmitLoading(false);
      }
    }
    // }
  };

  const errors = handleFormValidation(getValue(props, `form`, []));
  const hasErrors = errors.length > 0;

  /* -------------------------------------------------------------------------- */
  /*                     Generating Contact Section                             */
  /* -------------------------------------------------------------------------- */
  const companiesValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate1] = useState(0);
  const [companiesformSubmitLoading, setCompaniesFormSubmitLoading] = useState(
    false
  );
  const handleComapniesSubmit = async (module: string) => {
    if (module) {
      const formValid = companiesValidator.current.allValid();
      if (!formValid) {
        companiesValidator.current.showMessages();
        forceUpdate1(1);
      } else {
        let fields = JSON.parse(JSON.stringify(props.form));
        let associations = [
          { api_name: props.module, record_id: getValue(params, `id`, "") },
        ];
        let payloadRequest = generatePipelineAssociationPayload(
          module,
          fields,
          props.staticFieldRequest,
          associations
        );
        try {
          setCompaniesFormSubmitLoading(true);
          let resp = await createSubPipeline(payloadRequest, module);
          if (resp) {
            toast.success("Created & Associated Successfully");
            props.getData(false);
            companiesValidator.current.hideMessages();
            forceUpdate(0);
            setRollsPopupVisisbility(false);
            setCompaniesFormSubmitLoading(false);
          } else {
            setCompaniesFormSubmitLoading(false);
          }
        } catch (error) {
          setCompaniesFormSubmitLoading(false);
        }
      }
    }
  };
  const companiesErrors = handleFormValidation(props.form);
  const companiesHasErrors = companiesErrors.length > 0;

  const getItems = () => {
    if (alignValue === "details") {
      return [
        {
          key: "1",
          label: "About this ticket",
          children: <PendingDealComponent {...props} />,
        },
      ];
    }
    if (alignValue === "contacts") {
      return [
        {
          key: "2",
          label: (
            <div className="d-flex justify-content-between gap-2 align-items-center">
              <div className="d-flex gap-2 align-items-center">
                Contacts
                <Badge
                  count={
                    getValue(props, `data.contacts.length`, 0) < 9 &&
                    getValue(props, `data.contacts.length`, 0) !== 0
                      ? `${getValue(props, `data.contacts.length`, 0)}`
                      : getValue(props, `data.contacts.length`, 0)
                  }
                  className="ms-1"
                ></Badge>
              </div>
              <PlusCircle
                size={18}
                onClick={() => handleOpenForm("contacts")}
              />
            </div>
          ),
          children: <TaskContacts {...props} create_module={"contacts"} />,
        },
      ];
    }
    if (alignValue === "companies") {
      return [
        {
          key: "3",
          label: (
            <div className="d-flex justify-content-between gap-2 align-items-center">
              <div className="d-flex gap-2 align-items-center">
                Companies
                <Badge
                  count={
                    getValue(props, `data.companies.length`, 0) < 9 &&
                    getValue(props, `data.companies.length`, 0) !== 0
                      ? `${getValue(props, `data.companies.length`, 0)}`
                      : getValue(props, `data.companies.length`, 0)
                  }
                  className="ms-1"
                ></Badge>
              </div>
              <PlusCircle
                size={18}
                onClick={() => handleOpenForm("companies")}
              />
            </div>
          ),
          children: <TaskCompanies {...props} create_module={"companies"} />,
        },
      ];
    }
    return [];
  };

  const items1: any = [
    { value: "details", label: "Details" },
    { value: "contacts", label: "Contacts", className: "gray-tab" },
    { value: "companies", label: "Companies", className: "gray-tab" },
  ];
  const [alignValue, setAlignValue] = useState<any>("details");

  return (
    <div className={`pendingticket-left_container ${props.className}`}>
      <Segmented
        value={alignValue}
        style={{ marginBottom: 8 }}
        onChange={(value) => {
          setAlignValue(value);
        }}
        options={items1}
      />
      <PendingLoanComponent {...props} alignValue={alignValue} />
      <Collapse items={getItems()} className="ticket-right-side-collapse" />
      <AddInfoPopup
        isOpen={isRollsPopupVisibile}
        setIsOpen={setRollsPopupVisisbility}
        title="Add Contacts"
        width={"590px"}
        form={props.form}
        setForm={props.setForm}
        formLoading={props.formLoading}
        //submit section
        handleSubmit={handleSubmit}
        formSubmitLoading={formSubmitLoading}
        simpleValidator={props.simpleValidator}
        handleAssociate={props.handleAssociate}
        handleDisAssociate={props.handleDisAssociate}
        //static List
        module={"contacts"}
        pipelineList={props.pipelineList}
        stageList={props.stageList}
        staticFieldRequest={props.staticFieldRequest}
        setStaticFieldRequest={props.setStaticFieldRequest}
        getSpecificPipelineInfo={props.getSpecificPipelineInfo}
        selectedList={
          getValue(props, `data.contacts.length`, 0) > 0
            ? getValue(props, `data.contacts`, []).map((item: object) =>
                getValue(item, `id`, "")
              )
            : []
        }
        disabled={hasErrors ? true : false}
        disableValidation
        permissions={getValue(props, `permissions`, [])}
      />
      <AddInfoPopup
        isOpen={isCompaniesRollsPopupVisibile}
        setIsOpen={setCompaniesRollsPopupVisisbility}
        title="Add Companies"
        width={"590px"}
        form={props.form}
        setForm={props.setForm}
        formLoading={props.formLoading}
        //submit section
        handleSubmit={handleComapniesSubmit}
        formSubmitLoading={companiesformSubmitLoading}
        simpleValidator={props.simpleValidator}
        handleAssociate={props.handleAssociate}
        handleDisAssociate={props.handleDisAssociate}
        //static List
        module={"companies"}
        pipelineList={props.pipelineList}
        stageList={props.stageList}
        staticFieldRequest={props.staticFieldRequest}
        setStaticFieldRequest={props.setStaticFieldRequest}
        getSpecificPipelineInfo={props.getSpecificPipelineInfo}
        selectedList={
          getValue(props, `data.companies.length`, 0) > 0
            ? getValue(props, `data.companies`, []).map((item: object) =>
                getValue(item, `id`, "")
              )
            : []
        }
        disabled={companiesHasErrors ? true : false}
        disableValidation
        permissions={getValue(props, `permissions`, [])}
      />
    </div>
  );
}

export default TicketsRightSection;
