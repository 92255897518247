import React, { useState } from "react";
import { Checkbox, Drawer } from "antd";
import SubmitButtonContainer from "@components/common/ButtonContainer/SubmitButtonContainer";
import { getValue } from "@utils/lodash";
import TicketMergeSearchToolTip from "./merge-search-dropdown";
import MergeStep1 from "./step-1";
import MergeStep2 from "./step-2";

const TicketMergeDrawer = (props: any) => {
  const {
    isOpen,
    toggle,
    permissions,
    note_in_primary,
    setNoteInPrimary,
    note_in_secondary,
    setNoteInSecondary,
    primary_id,
    setPrimaryId,
    handleClickOptionMerge,
    convert_recepients_to_cc,
    setConvert_recepients_to_cc,
  } = props;

  const [isMerge, setIsMerge] = useState(false);
  const [editPrimaryNote, setEditPrimaryNote] = useState(false);
  const [editSecondaryNote, setEditSecondaryNote] = useState(false);

  return (
    <>
      <Drawer
        title="Merge Tickets"
        placement={"right"}
        onClose={toggle}
        open={isOpen}
        className="chart-details-table-drawer"
        width="40%"
      >
        <div className="">
          <div className="w-90 mt-4 ms-4">
            <TicketMergeSearchToolTip
              lookup_api={"tickets"}
              label={"label"}
              selectedPrimaryTicket={getValue(
                note_in_primary,
                "ticketInfo.id",
                ""
              )}
              name="Search Tickets"
              selectedSecondaryTicket={getValue(
                note_in_secondary,
                "ticketInfo.id",
                ""
              )}
              primary_id={primary_id}
              setPrimaryId={setPrimaryId}
              onChange={handleClickOptionMerge}
            />
          </div>
          {getValue(note_in_primary, `ticketInfo.ticket_id`, "") && (
            <MergeStep1
              isMerge={isMerge}
              note={note_in_primary}
              primary_id={primary_id}
              setPrimaryId={setPrimaryId}
              editNote={editPrimaryNote}
              setEditNote={setEditPrimaryNote}
              setNoteInSecondary={setNoteInSecondary}
              setRequest={setNoteInPrimary}
              mainNote={note_in_secondary}
            />
          )}
          {getValue(note_in_secondary, `ticketInfo.ticket_id`, "") && (
            <MergeStep2
              isMerge={isMerge}
              note={note_in_secondary}
              primary_id={primary_id}
              setPrimaryId={setPrimaryId}
              editNote={editSecondaryNote}
              setEditNote={setEditSecondaryNote}
              setRequest={setNoteInSecondary}
              setNoteInPrimary={setNoteInPrimary}
              mainNote={note_in_primary}
            />
          )}

          <div className="d-flex align-items-center mt-4 px-3">

            <Checkbox
              checked={convert_recepients_to_cc}
              onChange={() =>
                setConvert_recepients_to_cc(!convert_recepients_to_cc)
              }
            />
            <p className="ms-3">Add secondary ticket recipients to CC</p>
          </div>
        </div>
        {isMerge ? (
          <SubmitButtonContainer
            handleBackButton={() => {
              setIsMerge(false);
            }}
            handleSubmit={() => {
              props.handleSubmit(getValue(props, `module`, ""));
            }}
            submitLoading={props.submitLoading}
            disabled={
              !getValue(note_in_primary, `ticketInfo.id`, "") ||
              !getValue(note_in_secondary, `ticketInfo.id`, "")
            }
            first_title={"Back"}
            second_title={"Merge"}
            hideNew
            permissions={permissions}
            classname="w-40"
          />
        ) : (
          <SubmitButtonContainer
            handleBackButton={() => {
              toggle();
              setEditPrimaryNote(false);
              setEditSecondaryNote(false);
              setIsMerge(false);
            }}
            handleSubmit={() => {
              setIsMerge(true);
            }}
            disabled={
              !getValue(note_in_primary, `ticketInfo.id`, "") ||
              !getValue(note_in_secondary, `ticketInfo.id`, "")
            }
            submitLoading={props.submitLoading}
            first_title={"Cancel"}
            second_title={"Continue"}
            hideNew
            permissions={permissions}
            classname="w-40"
          />
        )}
      </Drawer>
    </>
  );
};

export default TicketMergeDrawer;
