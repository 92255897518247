import { Mail, NotepadText, PlusCircle, Zap } from "lucide-react";
import { getValue, setValue } from "@utils/lodash";
import { Dropdown, MenuProps, Tag } from "antd";
import { DownOutlined } from "@ant-design/icons";
import DefaultActions from "./default/DefaultActions";
import NotesAction from "./notes/NotesAction";
import { useState } from "react";
import { getUsers } from "@services/users.service";
import { capitalizeFirstLetter } from "@common/text-helpers";
import { toast } from "sonner";

interface Update {
  action: string;
  fieldId: string;
  value: any[];
  isEmpty: boolean;
}
interface Notes {
  html: string;
  to: any[];
  is_private: boolean;
  attachments: any[];
  id?: string;
  action: string;
}
interface Emails {
  id: string;
  action: string;
  field_name: string;
  email_to?: string;
  email_body: string;
  email_subject: string;
  email_agent_id?: string;
  email_group_id?: string;
}

interface Request {
  actions: {
    notes?: Notes[];
    emails?: Emails[];
    updates?: Update[];
  };
}

type ValueTypes = {
  [key: number]: any;
};

interface OptionFieldsProps {
  request: Request;
  setRequest: (req: any) => void;
  updateFields: any[];
  validator: any;
  valueTypes: any[];
  setValueTypes: any;
}

const OptionFields = ({
  request,
  setRequest,
  updateFields,
  validator,
  valueTypes,
  setValueTypes,
}: OptionFieldsProps) => {
  const handleAddOption = (): void => {
    setRequest((prev: Request) => ({
      ...prev,
      actions: {
        ...prev.actions,
        updates: [
          ...(prev.actions.updates || []),
          { action: "add", fieldId: "", value: [], isEmpty: false },
        ],
      },
    }));
  };

  const handleAddMoreOptions = (type: "notes" | "email") => {
    setRequest((prev: Request) => ({
      ...prev,
      actions: {
        ...prev.actions,
        [type]:
          type === "notes"
            ? [
                ...(prev.actions.notes || []),
                {
                  html: '',
                  to: [],
                  is_private: true,
                  attachments: [],
                  action: "add",
                },
              ]
            : [
                ...(prev.actions.emails || []),
                {
                  action: "add",
                  field_name: "send_email_to_agent",
                  email_to: "assigned_group",
                  email_body: "",
                  email_subject: "",
                  email_agent_id: "",
                  email_group_id: "",
                },
              ],
      },
    }));
  };

  console.log(request,"-----request")

  const handleDeleteOption = (index: number, key: string): void => {
    setRequest((prev: Request) => ({
      ...prev,
      actions: {
        ...prev.actions,
        [key as keyof Request["actions"]]: (
          prev.actions[key as keyof Request["actions"]] || []
        ).filter((_: any, i: number) => i !== index),
      },
    }));

    setValueTypes((prev: ValueTypes) => {
      const newTypes: ValueTypes = {};
      Object.keys(prev).forEach((key) => {
        const idx = parseInt(key, 10);
        if (idx < index) {
          newTypes[idx] = prev[idx];
        } else if (idx > index) {
          newTypes[idx - 1] = prev[idx];
        }
      });
      return newTypes;
    });
  };

  const handleIfCondition = (index: number, selectedOption: any): void => {
    setRequest((prev: Request) => ({
      ...prev,
      actions: {
        ...prev.actions,
        updates: (prev.actions.updates || []).map((item, i) =>
          i === index ? { ...item, fieldId: selectedOption.value } : item
        ),
      },
    }));
    setValueTypes((prev: ValueTypes) => ({
      ...prev,
      [index]: selectedOption,
    }));
  };

  const findInputType = (id: string) => {
    let filteredConditions = updateFields.find(
      (item: object) => getValue(item, `fieldId`, "") === id
    );
    let index = updateFields.findIndex(
      (item: object) => getValue(item, "fieldId", "") === id
    );
    return { index: index, ...filteredConditions };
  };

  const removeConditionUpdate = (index: number, key: string) => {
    setValue(request, `actions.${key}[${index}].action`, "remove");
    setRequest({ ...request });
  };

  const filteredActions = () => {
    let list =
      getValue(request, `actions.updates.length`, 0) > 0
        ? getValue(request, `actions.updates`, []).filter(
            (item: object) => getValue(item, `action`, "") !== "remove"
          )
        : [];
    return list;
  };

  /* -------------------------------------------------------------------------- */
  /*                               Notes Section                                */
  /* -------------------------------------------------------------------------- */

  const filteredNotes = () => {
    let list =
      getValue(request, `actions.notes.length`, 0) > 0
        ? getValue(request, `actions.notes`, []).filter(
            (item: object) => getValue(item, `action`, "") !== "remove"
          )
        : [];
    return list;
  };

  /* -------------------------------------------------------------------------- */
  /*                            More Actions Section                            */
  /* -------------------------------------------------------------------------- */

  const items: MenuProps["items"] = [
    {
      key: "1",
      icon: <NotepadText size={18} />,
      label: "Add Note",
      // disabled: true,
      onClick: () => handleAddMoreOptions("notes"),
    },
    {
      key: "2",
      icon: <Mail size={18} />,
      label: "Add Mail",
      // disabled: true,
      onClick: () => handleAddMoreOptions("email"),
    },
  ];

  return (
    <>
      <div className="mt-5">
        <h6 className="header_text__18 mb-3 d-flex align-items-center gap-1">
          <Tag color="blue" bordered={false}>
            <Zap size={16} />
          </Tag>
          Actions
        </h6>
      </div>

      <div className="mt-1 sla-condition-card-wrapper">
        {filteredActions().map((item: Update, index: number) => (
          <DefaultActions
            index={index}
            item={item}
            request={request}
            setRequest={setRequest}
            updateFields={updateFields}
            valueTypes={valueTypes}
            handleIfCondition={handleIfCondition}
            validator={validator}
            findInputType={findInputType}
            removeConditionUpdate={removeConditionUpdate}
            handleDeleteOption={handleDeleteOption}
          />
        ))}
        {filteredNotes().map((item: Update, index: number) => (
          <NotesAction
            index={index}
            item={item}
            request={request}
            setRequest={setRequest}
            removeConditionUpdate={removeConditionUpdate}
            handleDeleteOption={handleDeleteOption}
          />
        ))}
        <div className="d-flex align-items-center px-4 py-3 justify-content-between">
          <div
            onClick={handleAddOption}
            className="header_blue_text__15 d-flex align-items-center gap-1 cursor-pointer "
          >
            <PlusCircle size={18} />
            Add New Action
          </div>
          <Dropdown menu={{ items }}>
            <div className="header_blue_text__15 d-flex align-items-center gap-1 cursor-pointer ">
              More Actions
              <DownOutlined />
            </div>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default OptionFields;
