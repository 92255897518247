import AddCircleSvgComponent from "@assets/svg/add-circle";
import { getValue, setValue } from "@utils/lodash";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import {
  handleSelectArrayChange,
  handleTextNestedArrayChange,
} from "@common/handleChange";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import SwapVerticalSvgComponent from "@assets/svg/swap";
import CriteriaFilterBuilder from "./criteria-builder";

function CriteriaFilters(props: any) {
  const { request, setRequest, workflowConditions, pipelineStageList } = props;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const addCondition = () => {
    if (getValue(request, `criteria_filters.length`, 0) < 10) {
      let obj = {
        action: "add",
        fieldId: "",
        operator: "",
        value: [{ key: "" }],
        pattern:
          getValue(request, `criteria_filters.length`, 0) === 0
            ? "WHEN"
            : "AND",
      };
      request.criteria_filters.push(obj);
      setRequest({ ...request });
    }
  };
  const removeCondition = (index: number) => {
    let array = getValue(request, `criteria_filters`, []);
    let filtered = array.filter((item: object, ind: number) => ind !== index);
    setRequest({
      ...request,
      criteria_filters: filtered,
    });
  };
  const removeConditionUpdate = (index: number) => {
    setValue(request, `criteria_filters[${index}].action`, "remove");
    setRequest({ ...request });
  };
  const getCriteria = () => {
    let condtions = getValue(request, `criteria_filters`, []).filter(
      (item: object) => getValue(item, `action`, "") !== "remove"
    );
    return generateExpressions(condtions);
  };
  const generateExpressions = (array: any) => {
    let arr = getFilteredArray(array).map((item: any, index: number) => ({
      ...item,
      index: index,
    }));
    const logicalOperators: any = {
      AND: " AND ",
      OR: " OR ",
    };
    const stack: any = [];
    let expression = "";
    for (const obj of arr) {
      const name = obj.pattern;
      if (logicalOperators[name]) {
        let obje = { operator: logicalOperators[name], index: obj.index };
        stack.unshift(obje);
      } else {
        if (stack.length > 0) {
          const { operator, index } = stack.pop();
          const prevExpression = expression;
          expression = `( ${prevExpression} ${operator}${index + 1} )`;
        } else {
          expression += `${obj.index + 1}`;
        }
      }
    }
    while (stack.length > 0) {
      const { operator, index } = stack.pop();
      const prevExpression = expression;
      expression = `( ${prevExpression} ${operator}${index + 1} )`;
    }

    if (getValue(request, `pattern`, "") !== expression) {
      setRequest({
        ...request,
        pattern: expression,
      });
    }
    return expression;
  };
  const getFilteredArray = (array: any) => {
    if (getValue(array, `length`, 0) > 0) {
      let selectedArray = array.filter(
        (item: object) => getValue(item, `action`, "") !== "REMOVE"
      );
      return selectedArray;
    } else {
      return [];
    }
  };
  const findConditions = (id: string) => {
    let filteredConditions = workflowConditions.find(
      (item: object) => getValue(item, `fieldId`, "") === id
    );

    return getValue(filteredConditions, `conditions`, []).map(
      (item: object) => ({
        label: getValue(item, `label`, ""),
        value: getValue(item, `operator`, ""),
        operator: getValue(item, `operator`, ""),
      })
    );
  };
  const findInputType = (id: string) => {
    let filteredConditions = workflowConditions.find(
      (item: object) => getValue(item, `fieldId`, "") === id
    );
    let index = workflowConditions.findIndex(
      (item: object) => getValue(item, "fieldId", "") === id
    );
    return { index: index, ...filteredConditions };
  };
  const getFilteredConditions = () => {
    let condtions = getValue(request, `criteria_filters`, []).filter(
      (item: object) => getValue(item, `action`, "") !== "remove"
    );
    return getValue(condtions, `length`, 0);
  };

  const filteredConditions = () => {
    let list =
      getValue(request, `criteria_filters.length`, 0) > 0
        ? getValue(request, `criteria_filters`, []).filter(
            (item: object) => getValue(item, `action`, "") !== "remove"
          )
        : [];
    return list;
  };
  return (
    <div className="">
      <div className="workflow_detail_container">
        <div className="">
          <h6 className="header_text__20 mb-3">Criteria Filter</h6>
          {/* <h6 className="header_text__18 mt-1 mb-2">
            Which{" "}
            {getValue(urlParams, `moduleName`, "")
              ? formatString(getValue(urlParams, `moduleName`, ""))
              : getValue(props, `info.module.label`, "")}{" "}
            would you like to apply the workflow to?
          </h6> */}
        </div>
      </div>
      <div>
        {filteredConditions().map((item: object, index: number) => {
          return (
            <div>
              {index !== 0 && (
                <div
                  className="mt-2 mb-2 d-flex align-items-center cursor-pointer"
                  onClick={() =>
                    setRequest((object: any) => {
                      return {
                        ...object,
                        ["criteria_filters"]: object["criteria_filters"].map(
                          (x: any, i: number) => {
                            if (i !== index) return x;
                            return {
                              ...x,
                              pattern:
                                getValue(item, `pattern`, "") === "AND"
                                  ? "OR"
                                  : "AND",
                            };
                          }
                        ),
                      };
                    })
                  }
                >
                  <h6 className="header_text__14">
                    {getValue(item, `pattern`, "")}
                  </h6>
                  <div className="ms-2">
                    <SwapVerticalSvgComponent />
                  </div>
                  {/* <SearchToolTip
                    data={index === 0 ? type1 : type}
                    label="label"
                    width={"200px"}
                    placeholder={"Select"}
                    value={getValue(item, `pattern`, "")}
                    selectKey={"value"}
                    onChange={(e: object) =>
                      handleSelectArrayChange(
                        index,
                        e,
                        "pattern",
                        "criteria_filters",
                        setRequest
                      )
                    }
                    disabled={index === 0 ? true : false}
                  /> */}
                </div>
              )}
              <div className="d-flex align-items-center condtion_container mb-2">
                <div className="criteria">
                  <div className="criteria-count">{index + 1}</div>
                </div>
                <div>
                  <div className="d-flex align-items-center">
                    <div className="ms-2">
                      <SearchToolTip
                        width={220}
                        label="label"
                        selectKey={"id"}
                        data={getValue(props, `workflowOptions`, [])}
                        value={getValue(item, `fieldId`, "")}
                        onChange={(e: any) => {
                          handleSelectArrayChange(
                            index,
                            e,
                            "fieldId",
                            "criteria_filters",
                            setRequest
                          );
                          handleSelectArrayChange(
                            index,
                            "",
                            "operator",
                            "criteria_filters",
                            setRequest
                          );
                          handleTextNestedArrayChange(
                            index,
                            "value",
                            0,
                            null,
                            "criteria_filters",
                            setRequest
                          );
                        }}
                      />
                    </div>
                    <div className="ms-2">
                      <SearchToolTip
                        width={220}
                        label="label"
                        selectKey={"value"}
                        value={getValue(item, `operator`, "")}
                        data={findConditions(getValue(item, `fieldId`, ""))}
                        onChange={(e: any) => {
                          handleSelectArrayChange(
                            index,
                            e,
                            "operator",
                            "criteria_filters",
                            setRequest
                          );
                        }}
                        disabled={!getValue(item, `fieldId`, "")}
                      />
                    </div>
                  </div>
                  {getValue(item, `fieldId`, "") && (
                    <div className="mt-2 ms-2">
                      <CriteriaFilterBuilder
                        item={
                          getValue(item, `fieldId`, "")
                            ? findInputType(getValue(item, `fieldId`, ""))
                            : findInputType("pipeline_stage_id")
                        }
                        criteriaInfo={item}
                        parentIndex={index}
                        request={request}
                        setRequest={setRequest}
                        value={getValue(item, `value`, [])}
                        pipelineStageList={pipelineStageList}
                      />
                    </div>
                  )}
                </div>
                <div className="cursor-pointer ms-4 d-flex align-items-center">
                  {(index !== 0 || getFilteredConditions() === 1) && (
                    <div
                      onClick={() =>
                        getValue(item, `id`, "")
                          ? removeConditionUpdate(index)
                          : removeCondition(index)
                      }
                      className="ms-2"
                    >
                      <RemoveCircleSvgComponent />
                    </div>
                  )}
                  {filteredConditions().length - 1 === index && (
                    <div onClick={addCondition} className="ms-2">
                      <AddCircleSvgComponent />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}

        {getValue(request, `criteria_filters.length`, 0) > 1 && (
          <p className="header_text__16 mb-3 mt-4">{getCriteria()}</p>
        )}
        {getValue(request, `criteria_filters.length`, 0) === 0 && (
          <div
            className="d-flex align-items-center mb-2 cursor-pointer"
            onClick={addCondition}
          >
            <AddCircleSvgComponent color={"#1a73e8"} />
            <h6 className="header_blue_text__18 ms-2">Add Condition</h6>
          </div>
        )}
      </div>
    </div>
  );
}

export default CriteriaFilters;
