import "./index.scss";
import ListLoader from "@components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import React, { useEffect, useState } from "react";
import AIAgentFullLayout from "../../Layout/full-layout";
import AgentSidebar from "./components/sidebar";
import SourceWebsite from "./components/website";
import RightSection from "./components/right-section";
import SourceText from "./components/text";
import { toast } from "sonner";
import {
  addChatbotDocumentLink,
  chatbotDocumentList,
  chatbotDocumentText,
  chatbotDocumentqna,
  trainChatbotDocument,
} from "@services/chatbot-document.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import SourceQA from "./components/qa";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
function AIAgentSources() {
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const [request, setRequest] = useState({
    url_source_ids: [],
    texts_source: {
      name: "",
      texts: "",
    },
    chatbot_id: "",
    qna: [
      {
        question: "",
        answer: "",
      },
    ],
  });

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    getChatbotLink();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const getChatbotLink = async () => {
    try {
      let payload = {
        chatbot_id: getValue(params, `chatbotId`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await chatbotDocumentList(queryRequest);
      let respText = await chatbotDocumentText(queryRequest);
      let respQna = await chatbotDocumentqna(queryRequest);
      if (resp) {
        setLinkUrls(getValue(resp, `data`, []));
        setRequest({
          ...request,
          texts_source: {
            texts: getValue(respText, `data.chatbot_document[${0}].texts`, {}),
            name: getValue(respText, `data.chatbot_document[${0}].texts`, {}),
          },
          qna: getValue(respQna, `data.chatbot_document`, []),
        });
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                Add Link section                            */
  /* -------------------------------------------------------------------------- */
  const [linkURLs, setLinkUrls] = useState<any>([]);

  const handleAddLink = async (url: string) => {
    try {
      let resp = await addChatbotDocumentLink({
        url: url,
        chatbot_id: getValue(params, `chatbotId`, ""),
      });
      if (resp) {
        toast.success("Deleted Successfully");
        getChatbotLink();
      }
    } catch (error) {}
  };

  const handleSaveURL = async () => {
    let links = linkURLs.filter((item: object) => !getValue(item, `id`, ""));
    links.map((item: object) => {
      return handleAddLink(getValue(item, `url`, ""));
    });
  };

  const findStaticUrl = () => {
    let links = linkURLs.filter((item: object) => !getValue(item, `id`, ""));
    if (getValue(links, `length`, 0) > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleTrainLink = async (url: string) => {
    try {
      let resp = await trainChatbotDocument(
        removeNullOrUndefinedProperties({
          url_source_ids: linkURLs.map((item: object) =>
            getValue(item, `id`, "")
          ),
          chatbot_id: getValue(params, `chatbotId`, ""),
          qna: getValue(request, `qna`, []),
          texts_source: getValue(request, `texts_source`, {}),
        })
      );
      if (resp) {
        toast.success("Trained Successfully");
        getChatbotLink();
      }
    } catch (error) {}
  };

  return (
    <AIAgentFullLayout>
      {isLoading ? (
        <ListLoader />
      ) : (
        <div className="sources-container">
          <div className="sources-layout">
            <AgentSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
            {activeTab === "1" && (
              <SourceText request={request} setRequest={setRequest} />
            )}
            {activeTab == "2" && (
              <SourceWebsite
                request={request}
                setRequest={setRequest}
                getChatbotLink={getChatbotLink}
                handleAddLink={handleAddLink}
                linkURLs={linkURLs}
                setLinkUrls={setLinkUrls}
              />
            )}

            {activeTab == "3" && (
              <SourceQA request={request} setRequest={setRequest} />
            )}
            <RightSection
              handleSaveURL={handleSaveURL}
              findStaticUrl={findStaticUrl}
              handleTrainLink={handleTrainLink}
            />
          </div>
        </div>
      )}
    </AIAgentFullLayout>
  );
}

export default AIAgentSources;
