import "../template.scss";
import { getValue } from "@utils/lodash";
import SubmitButtonContainer from "@components/common/ButtonContainer/SubmitButtonContainer";
import { Button, Drawer, Input, Modal } from "antd";
import { useState } from "react";
import { Search } from "lucide-react";
import MarkdownEditor from "@components/MarkdownEditor/Editor";

function TemplatePopup(props: any) {
  const {
    isOpen,
    handleModal,
    module,
    request,
    setRequest,
    editId,
    reset,
    permissions,
    ticketFields,
    ticketKeys,
    handleAddTextAtCursor,
    editorState,
    setEditorState,
    keyModal,
    handleKeyModal,
    handleChangeEditor,
  } = props;

  const [searchQuery, setSearchQuery] = useState("");

  const filteredKeys =
    ticketKeys.length > 0
      ? ticketKeys.filter((key: string) =>
          key.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : [];

  console.log("editor", getValue(request, "content", ""));
  return (
    <div>
      {/* <SlideFromRightPopup
        isPopupVisible={isOpen}
        addCustomClass={"rolls-popup"}
        toggle={() => {
          handleModal(false);
          reset();
        }}
        title="Create Template"
        moduleName={module}
        width={"60%"}
      > */}
      <Drawer
        title="Create Template"
        onClose={() => {
          handleModal(false);
          reset();
        }}
        open={isOpen}
        width="60%"
        className="chart-details-table-drawer"
      >
        {ticketFields && (
          <div className="d-flex justify-content-end mt-3 mx-3">
            <Button onClick={handleKeyModal}>Add Placeholders</Button>
          </div>
        )}

        <div className="m-2 mt-3">
          <div className={`d-flex align-items-center ${"template_container"}`}>
            <div className={`${"template_container_section__1"}`}>
              <p className="small_text__14 text-right">
                Template Name{" "}
                <span className="form-error small_text__14">*</span>
              </p>
            </div>
            <div className={`${"template_container_section__2"}`}>
              <input
                type="text"
                className={`${"template_input"} ms-2 w-90`}
                placeholder="Enter Template Name"
                name="name"
                value={getValue(request, `name`, "")}
                onChange={(e) =>
                  setRequest({
                    ...request,
                    name: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className={`d-flex align-items-center ${"template_container"}`}>
            <div className={`${"template_container_section__1"}`}>
              <p className="small_text__14 text-right">
                Subject <span className="form-error small_text__14">*</span>
              </p>
            </div>
            <div className={`${"template_container_section__2"}`}>
              <input
                type="text"
                className={`${"template_input"} ms-2 w-90`}
                placeholder="Enter Subject"
                name="subject"
                value={getValue(request, `subject`, "")}
                onChange={(e) =>
                  setRequest({
                    ...request,
                    subject: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="template_editor_container">
            {/* <DraftJSEditor
              editorState={editorState}
              handleChangeEditor={props.handleChangeEditor}
              name="content"
              placeholder="Enter Content here"
              editorStyle={{
                background: "white",
                paddingLeft: "30px",
                minHeight: "30vh",
              }}
            /> */}
            <MarkdownEditor
              value={getValue(request, "content", "")}
              handleChangeEditor={handleChangeEditor}
              hideEmoji
            />
          </div>
        </div>
        <SubmitButtonContainer
          handleBackButton={() => {
            props.handleModal();
            reset();
          }}
          handleSubmit={() => {
            props.handleSubmit(getValue(props, `module`, ""));
          }}
          submitLoading={props.submitLoading}
          first_title={"Cancel"}
          second_title={editId ? "Update" : "Create"}
          hideNew
          permissions={permissions}
          classname="w-60"
        />
      </Drawer>

      <Modal
        title="Ticket Placeholders"
        open={keyModal}
        onOk={handleKeyModal}
        onCancel={handleKeyModal}
        footer={null}
      >
        <div className="d-flex flex-wrap gap-2">
          <Input
            prefix={<Search size={16} />}
            className="my-3"
            placeholder="Search Placeholder"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            allowClear
          />
          {filteredKeys.length > 0 &&
            filteredKeys.map((item: any, index: number) => (
              <Button onClick={() => handleAddTextAtCursor(item)} key={index}>
                <span>{item}</span>
              </Button>
            ))}
        </div>
      </Modal>
    </div>
  );
}

export default TemplatePopup;
