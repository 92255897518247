import AccountCircleSvgComponent from "@assets/svg/account-circle";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  formatString,
  removeNullOrUndefinedProperties,
} from "@common/text-helpers";
import MultiLevelSearchToolTip from "@components/custom/Dropdown/MultiLevelSearchTooltip";
import { getAllChatbots, updateChatbot } from "@services/chatbot.service";
import { updateLiveChatConversation } from "@services/live-chat.service";
import { getOrganisationUsers } from "@services/organisations.service";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import { toast } from "sonner";

function ChatRightHeader(props: any) {
  const {
    handleToggleWidth,
    selectedContact,
    selectedConversationInfo,
  } = props;
  /* -------------------------------------------------------------------------- */
  /*                           Dropdown  Listing Section                        */
  /* -------------------------------------------------------------------------- */
  /* ---------------------------  OrgUsers section ---------------------------- */
  useEffect(() => {
    getActiveUsersData();
    getChatbotData();
  }, []);
  const [orgUsers, setOrgUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const getActiveUsersData = async () => {
    if (getValue(orgUsers, `length`, 0) === 0) {
      let payload = {
        page_size: 100,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      try {
        setLoading(true);
        let resp = await getOrganisationUsers(queryRequest);
        if (resp) {
          setOrgUsers(
            getValue(resp, `data.length`, 0) > 0 &&
              getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `id`, ""),
                name:
                  getValue(item, `first_name`, "") +
                  " " +
                  getValue(item, `last_name`, ""),
                label:
                  getValue(item, `first_name`, "") +
                  " " +
                  getValue(item, `last_name`, ""),
              }))
          );
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };
  /* ---------------------------  ChatbotList section ---------------------------- */

  const [chatbotList, setChatbotList] = useState([]);
  const [AiAgents, setAiAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getChatbotData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_size: 100,
        page_no: 1,
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllChatbots(queryRequest);
      if (resp) {
        const list1 =
          getValue(resp, `data.chatbots.length`, 0) > 0
            ? getValue(resp, `data.chatbots`, []).filter(
                (item: object) => getValue(item, `platform`, "") === "WHATSAPP"
              )
            : [];

        const list2 =
          getValue(resp, `data.chatbots.length`, 0) > 0
            ? getValue(resp, `data.chatbots`, []).filter(
                (item: object) => getValue(item, `type`, "") === "ai-chatbot"
              )
            : [];
        setAiAgents(
          list2.map((item: object) => ({
            ...item,
            label: getValue(item, `name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setChatbotList(
          list1.map((item: object) => ({
            ...item,
            label: getValue(item, `name`, ""),
            value: getValue(item, `id`, ""),
          }))
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                           Status Update Section                            */
  /* -------------------------------------------------------------------------- */

  const generateCommonFields = () => {
    const source = getValue(selectedConversationInfo, `source`, "");
    const commonFields: Record<string, any> = {
      contact_id: getValue(selectedConversationInfo, `contact_id`, ""),
      lead_id: getValue(selectedConversationInfo, `lead_id`, ""),
    };
    if (source === "webchat") {
      commonFields.web_name = getValue(
        selectedConversationInfo,
        `web_name`,
        ""
      );
      commonFields.web_phone = getValue(
        selectedConversationInfo,
        `web_phone`,
        ""
      );
      commonFields.web_email = getValue(
        selectedConversationInfo,
        `web_email`,
        ""
      );
      commonFields.web_is_guest = getValue(
        selectedConversationInfo,
        `web_is_guest`,
        false
      );
    }
    return commonFields;
  };
  const generatePayload = (value: string, id: string) => {
    const commonFields = generateCommonFields();
    switch (value) {
      case "team":
        return {
          ...commonFields,
          status: "un-assigned",
          chatbot_id: "",
          assigned_user_id: "",
          managed_by: "team",
        };
      case "bot":
        return {
          ...commonFields,
          status: "assigned-chatbot",
          chatbot_id:
            id || getValue(selectedConversationInfo, `chatbot_id`, ""),
          managed_by: "chatbot",
        };

      case "ai":
        return {
          ...commonFields,
          status: "assigned-chatbot",
          chatbot_id:
            id || getValue(selectedConversationInfo, `chatbot_id`, ""),
          managed_by: "ai-chatbot",
        };
      case "user":
        return {
          ...commonFields,
          status: "assigned",
          chatbot_id: "",
          assigned_user_id:
            id || getValue(selectedConversationInfo, `assigned_user_id`, ""),
          managed_by: "team",
        };
      case "resolved":
        return {
          ...commonFields,
          status: "resolved",
          chatbot_id: getValue(selectedConversationInfo, `chatbot_id`, ""),
          assigned_user_id: getValue(
            selectedConversationInfo,
            `assigned_user_id`,
            ""
          ),
          managed_by: getValue(selectedConversationInfo, `managed_by`, "")
            ? getValue(selectedConversationInfo, `managed_by`, "")
            : "team",
        };
      default:
        return {};
    }
  };
  const handleChangeMultiLevel = async (level: any, value: any, e: any) => {
    try {
      let resp = await updateLiveChatConversation(
        getValue(selectedConversationInfo, `id`, ""),
        removeNullOrUndefinedProperties(generatePayload(level, value))
      );
      if (resp) {
        toast.success("Assigned successfully");
        props.getData(false);
      }
    } catch (error) {}
  };
  const handleUpdateConvesation = async (status: string) => {
    try {
      let resp = await updateLiveChatConversation(
        getValue(selectedConversationInfo, `id`, ""),
        removeNullOrUndefinedProperties(generatePayload(status, ""))
      );
      if (resp) {
        toast.success(`${status} successfully`);
        props.handleClickFilter(status);
      }
    } catch (error) {}
  };

  const determineInitialSelectedId = (conversationInfo: any) => {
    const managedBy = getValue(conversationInfo, "managed_by", "");
    const orgUserId = getValue(conversationInfo, "org_user_id", "");
    const chatBotId = getValue(conversationInfo, "chatbot_id", "");
    // Priority order for selection
    switch (true) {
      case managedBy === "team" && !orgUserId:
        return "team";
      case managedBy === "ai-chatbot":
        return chatBotId;
      case !!orgUserId:
        return orgUserId;
      case !managedBy:
        return "team";
      default:
        return "";
    }
  };

  return (
    <div
      className="conversation-header  cursor-pointer"
      onClick={handleToggleWidth}
    >
      <div className="header-right-section">
        <button className="header-name">
          <AccountCircleSvgComponent color={"#dfe5e7"} size={40} />
          <div>
            {getValue(selectedContact, `contact.first_name`, "") ||
            getValue(selectedContact, `contact.last_name`, "") ||
            getValue(selectedConversationInfo, `web_name`, "") ? (
              <p
                className="small_text__14 color_black"
                style={{ textAlign: "left" }}
              >
                {formatString(
                  getValue(selectedContact, `contact.first_name`, "")
                )}{" "}
                {formatString(
                  getValue(selectedContact, `contact.last_name`, "")
                )}{" "}
                {formatString(
                  getValue(selectedConversationInfo, `web_name`, "")
                )}
              </p>
            ) : (
              <p
                className="small_text__14 color_black"
                style={{ textAlign: "left" }}
              >
                {formatString(
                  getValue(selectedConversationInfo, `wa_profile_name`, "")
                )}
              </p>
            )}

            <p className="small_text__12 mt-1 color_desc">
              Click here for more contact info
            </p>
          </div>
        </button>
      </div>
      <div className="d-flex align-items-center">
        {getValue(selectedConversationInfo, `status`, "") === "assigned" ? (
          <button
            className="inbox_button"
            onClick={(e: any) => {
              e.stopPropagation();
              handleUpdateConvesation("resolved");
            }}
          >
            Mark as Resolved
          </button>
        ) : getValue(selectedConversationInfo, `status`, "") === "resolved" ? (
          <button
            className="inbox_button"
            onClick={(e: any) => {
              e.stopPropagation();
              handleUpdateConvesation("un-assigned");
            }}
          >
            Reopen
          </button>
        ) : (
          <button
            className="inbox_button"
            onClick={(e: any) => {
              e.stopPropagation();
              handleUpdateConvesation("resolved");
            }}
          >
            Mark as Resolved
          </button>
        )}
        <div className="bg-white">
          <MultiLevelSearchToolTip
            label={"label"}
            width={"300px"}
            showAsButton
            dataSections={[
              {
                title: "Assign to Team",
                description:
                  "This will move the conversation to unassigned status",
                key: "team",
                data: [{ value: "team", label: "Team", id: "team" }],
              },
              {
                title: "Assign to User",
                key: "user",
                data: orgUsers,
                description: "Optional description",
              },
              {
                title: "Assign to Bot",
                key: "bot",
                data: chatbotList,
                description: "Optional description",
              },
              {
                title: "Assign to AI Agent",
                key: "ai",
                data: AiAgents,
                description: "Optional description",
              },
            ]}
            initialSelectedId={determineInitialSelectedId(
              selectedConversationInfo
            )}
            selectKey={"value"}
            onChange={(e: object, key: string) =>
              handleChangeMultiLevel(key, getValue(e, `id`, ""), e)
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ChatRightHeader;
