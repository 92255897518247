import { getValue } from "@utils/lodash";
import { Button } from "antd";
import {
  CircleCheckBig,
  Forward,
  Merge,
  NotepadText,
  Reply,
  Trash2,
} from "lucide-react";
import React from "react";

function TicketTopMenu(props: any) {
  const { toggleNote, handleMergeToggle, handleDeleteTicketView } = props;
  return (
    <div className="d-flex align-items-center gap-2 mb-3">
      <Button icon={<Reply size={16} />} disabled>
        Reply
      </Button>
      <Button icon={<NotepadText size={16} />} onClick={toggleNote}>
        Add Note
      </Button>
      <Button icon={<Forward size={16} />} disabled>
        Forward
      </Button>
      <Button
        icon={<CircleCheckBig size={16} />}
        onClick={props.handleCloseStatus}
      >
        Close
      </Button>
      <Button icon={<Merge size={16} />} onClick={handleMergeToggle}>
        Merge
      </Button>
      {getValue(props, `permissions`, []).includes("delete") && (
        <Button icon={<Trash2 size={16} />} onClick={handleDeleteTicketView}>
          Delete
        </Button>
      )}
    </div>
  );
}

export default TicketTopMenu;
