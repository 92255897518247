import React from "react";

function RightSection(props: any) {
  const { findStaticUrl } = props;
  return (
    <div className="stats-sidebar">
      <div className="stats-card">
        <h3>Sources</h3>
        <div className="stat-item">
          <div className="label">16 Links (71,630 detected chars)</div>
        </div>
        <div className="stat-item">
          <div className="label">Total detected characters</div>
          <div className="value">71,630</div>
          <div className="limit">11,000,000 limit</div>
        </div>
      </div>
      <button
        className="retrain-button"
        onClick={findStaticUrl() ? props.handleSaveURL : props.handleTrainLink}
      >
        {findStaticUrl() ? "Add URL Link" : "Retrain Chatbot"}
      </button>
    </div>
  );
}

export default RightSection;
