import { formHandleTextArrayChangeByName } from "@common/handleChange";
import { QueryRequestHelper } from "@common/query-request-helper";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { deletechatbotDocumentList } from "@services/chatbot-document.service";
import { chatbotDocumentList } from "@services/chatbot-document.service";
import { getValue } from "@utils/lodash";
import { Button, Input } from "antd";
import { Info, Plus, Save, Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

function SourceWebsite(props: any) {
  const { linkURLs, setLinkUrls, getChatbotLink } = props;
  const params = useParams();
  const navigate = useNavigate();

  const [src_url, setSrc_url] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const createLink = async () => {
    try {
      setSubmitLoading(true);
      let payload = {
        src_url: src_url,
        chatbot_id: getValue(params, `chatbotId`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await chatbotDocumentList(queryRequest);
      if (resp) {
        setSubmitLoading(false);
        toast.success("Submitted successfully");
        getChatbotLink();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                                Delete section                              */
  /* -------------------------------------------------------------------------- */
  const [isOpen, setIsOpen] = React.useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const [deleteId, setDeleteId] = useState("");
  const handleDelete = (id: string) => {
    setDeleteId(id);
    handleModal();
  };
  const handleDeleteFunction = async () => {
    try {
      let resp = await deletechatbotDocumentList(deleteId);
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal();
        getChatbotLink();
      }
    } catch (error) {}
  };

  const handleAddUrl = () => {
    linkURLs.unshift({ url: "", status: "untrained", type: "text" });
    setLinkUrls([...linkURLs]);
  };
  const handleRemoveUrl = (index: number) => {
    let filtered = linkURLs.filter((_: any, i: number) => i !== index);
    setLinkUrls(filtered);
  };

  return (
    <div className="main-content">
      <h2 className="header_text__18">Website</h2>

      <div className="crawl-section">
        <div className="w-100">
          <label className="form-label">Crawl</label>
          <div className="d-flex align-items-center gap-2 justify-content-between w-100">
            <Input
              placeholder="https://www.example.com"
              value={src_url}
              onChange={(e) => setSrc_url(e.target.value)}
              className="w-75 mb-0"
            />
            <Button
              type="primary"
              onClick={createLink}
              disabled={submitLoading}
              loading={submitLoading}
            >
              Fetch more links
            </Button>
          </div>
          <p className="small_text__13 pt-2">
            This will crawl all the links starting with the URL (not including
            files on the website).
          </p>
        </div>
      </div>

      <div className="links-section">
        <h3 className="header_text__16">
          Included Links
          <div className="actions">
            {/* <Button type="text">Delete all</Button> */}
            <Button
              type="text"
              icon={<Plus size={16} />}
              onClick={handleAddUrl}
            />
          </div>
        </h3>

        {getValue(linkURLs, `length`, 0) > 0 &&
          linkURLs.map((link: any, index: any) =>
            getValue(link, `type`, "") === "text" ? (
              <div className="d-flex align-items-center justify-content-between">
                <input
                  type="text"
                  value={getValue(link, `url`, "")}
                  name="url"
                  onChange={(e) =>
                    formHandleTextArrayChangeByName(index, e, setLinkUrls)
                  }
                  className="w-75 mb-2"
                />
                <div
                  onClick={() =>
                    !getValue(link, `id`, "")
                      ? handleRemoveUrl(index)
                      : handleDelete(getValue(link, `id`, ""))
                  }
                >
                  <Trash2 size={16} className="delete" />
                </div>
              </div>
            ) : (
              <div key={index} className="link-item">
                {/* {getValue(link, `status`, "") === "trained" && ( */}
                  <span className={"status"}>
                    <Info size={14} style={{ marginRight: 4 }} />
                    {getValue(link, `status`, "")}
                  </span>
                {/* )} */}
                <span className="link-url">{link.url}</span>
                <span className="chars">{link.chars}</span>
                <div
                  onClick={() =>
                    !getValue(link, `id`, "")
                      ? handleRemoveUrl(index)
                      : handleDelete(getValue(link, `id`, ""))
                  }
                >
                  <Trash2 size={16} className="delete" />
                </div>
              </div>
            )
          )}
      </div>
      <DeleteModal
        isOpen={isOpen}
        handleModal={handleModal}
        handleSubmit={handleDeleteFunction}
        deleteValue=""
      />
    </div>
  );
}

export default SourceWebsite;
