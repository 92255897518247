import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import { useNavigate, useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { Plus } from "lucide-react";
import SettingsPageDetails from "@components/Pages/PageDescription/SettingsPageDetails";
import { skillsDetails } from "@common/Data/page-details-data";
import ListingHoc from "../SLAPolicy/CreateSLAPolicy/common/ListingHoc";
import {
  deleteSkill,
  getAllSkills,
  updateSkillSeqNum,
  updateSkillStatus,
} from "@services/skills.service";
import { Alert } from "antd";

const SettingsSkills = ({ props }: any) => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        icon="skills"
        title="Assignment Rules"
        showSubmit
        submitIcon={<Plus size={18} />}
        submitText="New Rule"
        handleSubmit={() =>
          navigate(
            `/${getValue(
              params,
              "orgId",
              ""
            )}/settings/helpdesk/assignment-rules/create`
          )
        }
      />
      <div className="sla-policy-wrapper">
        <div className="w-70 p-4 sla-policy-card-container">
          <Alert
            type="info"
            showIcon
            message="
                The first matching SLA policy will be applied to tickets with
                matching conditions"
            className="w-fit-content mb-4"
          />
          <ListingHoc
            url="assignment-rules"
            apiData="skills"
            module="Skills"
            deleteApi={deleteSkill}
            listApi={getAllSkills}
            updatePositionApi={updateSkillSeqNum}
            updateSatusApi={updateSkillStatus}
            detailsData={skillsDetails}
            isSkills
            showAgents
          />
        </div>
        <div className="w-30 p-4 sla-policy-right-details">
          <SettingsPageDetails data={skillsDetails} />
        </div>
      </div>
    </CompanyProfileDetailsLayout>
  );
};

export default SettingsSkills;
