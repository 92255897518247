import { handleRadioChange } from "@common/handleChange";
import { getValue } from "@utils/lodash";
import { Radio, Tag } from "antd";
import { Clock, Flag } from "lucide-react";
import React from "react";

const EventFields = (props: any) => {
  const { request, setRequest } = props;
  return (
    <div>
      {" "}
      <div className="mt-4">
        <h6 className="header_text__18 d-flex align-items-center gap-2 mb-2">
          <Tag color="green" bordered={false}>
            <Flag size={16} />
          </Tag>
          Events
        </h6>
      </div>
      {getValue(request, "type", "") === "ticket_update_rules" ? (
        <div className="sla-condition-card-wrapper ">
          <div className="sla-condition-card p-4">
            <Radio.Group
              onChange={(e) =>
                handleRadioChange(e, "performer_type", request, setRequest)
              }
              value={getValue(request, "performer_type", "")}
              options={[
                { value: "agent", label: "Agent" },
                { value: "requester", label: "Requester" },
                { value: "agent_or_requester", label: "Agent or Requester" },
                { value: "system", label: "System" },
                { value: "collaborator", label: "Collaborator" },
              ]}
            />
          </div>
        </div>
      ) : (
        <div className="sla-condition-card-wrapper p-3 d-flex align-items-center gap-2 small_text__14">
          <Clock size={18} /> When a ticket is being created
        </div>
      )}
    </div>
  );
};

export default EventFields;
