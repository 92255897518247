import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getLiveChatStatusCount = (queryRequest: string) =>
  get(`${config.API_URL}/inbox/status/count?${queryRequest}`);

export const getLiveChatConversationList = (queryRequest: string) =>
  get(`${config.API_URL}/inbox/list?${queryRequest}`);

export const getSpecificLiveChatConversation = (queryRequest: string) =>
  get(`${config.API_URL}/inbox/list/conversation?${queryRequest}`);

export const updateLiveChatConversation = (id: string, payload: object) =>
  patch(`${config.API_URL}/inbox/${id}`, payload);

export const readLiveChatConversation = (id: string) =>
  get(`${config.API_URL}/inbox/read/status/${id}`);

export const liveChatSendMessage = (payload: object) =>
  post(`${config.API_URL}/inbox/send/message`, payload);
