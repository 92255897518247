import TicketDetailsPage from "@pages/Private/Tickets/Detail";
import Tickets from "@pages/Private/Tickets";
import TicketsKanban from "@pages/Private/Tickets/Kanban";

export const TicketRoutes = [
  {
    path: "/:orgId/tickets",
    name: "chatbot",
    component: Tickets,
  },
  {
    path: "/:orgId/tickets/kanban",
    name: "chatbot",
    component: TicketsKanban,
  },
  {
    path: "/:orgId/tickets/detail/:id",
    name: "chatbot",
    component: TicketDetailsPage,
  },
];
