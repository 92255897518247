import { Modal, Tooltip } from "antd";
import { Trash2 } from "lucide-react";
import { toast } from "sonner";

const DeleteModal = ({
  api,
  getData,
  name,
  module,
  size,
  disabled,
  disabledMessage,
  color,
  classname,
  showText,
}: any) => {
  const handleDeleteModal = () => {
    Modal.confirm({
      title: `Are you sure you want to delete ${module} - ${name}?`,
      content: "Once deleted, it cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          let resp = await api();
          if (resp) {
            toast.success(`${module} deleted successfully`);
            getData();
          }
        } catch (error) {
          toast.error(`Error deleting ${module}`);
        }
      },
    });
  };

  return (
    <>
      {showText ? (
        <div onClick={handleDeleteModal}>Delete</div>
      ) : (
        <Tooltip
          title={`${
            disabled && disabledMessage ? disabledMessage : `Delete ${module}`
          }`}
        >
          <button
            type="button"
            className={`delete-trash-icon ${classname ? classname : ""}`}
            onClick={handleDeleteModal}
            disabled={disabled}
          >
            <Trash2
              size={size ? size : "16"}
              style={{
                color: disabled ? "#d9dadb" : color || "#202124",
                transition: "color 0.3s ease",
              }}
              className="trash-icon"
            />
          </button>
        </Tooltip>
      )}
    </>
  );
};

export default DeleteModal;
