import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import React from "react";
import AIAgentTabHeaders from "./detail-header";

function AIAgentFullLayout({ children }: any) {
  return (
    <>
      <HomeHeader />
      <PipelineSubHeader
        subHeaderListTitle={`AI Chatbot`}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
      />
      <div className="ai-chatbot-container">
        <AIAgentTabHeaders />
        <div className={"contentWrapper"}>
          <div className={"contentFullContainer"}>{children}</div>
        </div>
      </div>
    </>
  );
}

export default AIAgentFullLayout;
