import Select from "react-select";
import "./form.scss";
import { getValue } from "@utils/lodash";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getDate } from "@common/date-helpers";

function InputRuleForm(props: any) {
  const {
    inputType,
    label,
    name,
    value,
    onChange,
    placeholder,
    validator,
    options,
    hidelabel,
    checked,
    disabled,
    mandatory,
    required,
    hasRequired,
    marginZero,
    hideFormLabel,
    isHeaderEditable,
    validLeft,
  } = props;
  const customStyles = {
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "#dadce0" : "#dadce0",
      height: "44px",
      boxShadow: "none",
    }),
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        color: "#dadce0",
      };
    },
  };

  switch (inputType) {
    case "TEXT":
      return (
        <div
        // className={`form-group ${
        //   validator && validator.current.message(label, value, "required")
        //     ? "form-wrapper__form-group--error"
        //     : ""
        // }`}
        >
          {!getValue(props, `hideLabel`, "") && (
            // <label
            //   className={`create-user-form-body__label mb-1`}
            // >
            //   {label}
            // </label>
            <div
              className={`d-flex  align-items-center ${
                hideFormLabel
                  ? "justify-content-end"
                  : "justify-content-between"
              }`}
            >
              {!hideFormLabel && (
                <label className={`form-label`}>
                  {label}{" "}
                  {(mandatory || required || hasRequired) && (
                    <span className=" small_text__16 text-danger">*</span>
                  )}
                </label>
              )}
              {getValue(props, `showCount`, false) && (
                <p className={`small_text__12 ${hideFormLabel && "mb-1"}`}>
                  {getValue(value, `length`, "0")} /{" "}
                  {getValue(props, `maxLength`, "0")}
                </p>
              )}
            </div>
          )}
          <input
            type={inputType}
            placeholder={placeholder}
            className={`custom-form-control form-control ${
              isHeaderEditable && "header-input-editable"
            }`}
            name={name}
            value={value}
            onChange={onChange}
            style={{
              ...props.style,
              borderLeft: getValue(props, `required`, false)
                ? "2px solid red"
                : "",
            }}
            disabled={disabled}
            id={props.id}
            maxLength={props.maxLength}
            // ref={props.ref}
          />
          {validator && validator.current.message(label, value, "required") && (
            <p
              className={`error-text ${
                getValue(props, `validLeft`, "") ? "" : "text-center"
              }`}
            >
              {validator.current.message(label, value, "required")}
            </p>
          )}
        </div>
      );

    case "NUMBER":
      return (
        <div
          className={`form-group ${
            validator && validator.current.message(label, value, "required")
              ? "form-wrapper__form-group--error"
              : ""
          } ${marginZero && "mb-0"}`}
        >
          {!getValue(props, `hideLabel`, "") && (
            // <label
            //   className={`create-user-form-body__label mb-1`}
            // >
            //   {label}
            // </label>
            <>
              {!hideFormLabel && (
                <label className={`form-label`}>
                  {label}{" "}
                  {(mandatory || required) && (
                    <span className=" small_text__16 text-danger">*</span>
                  )}
                </label>
              )}
            </>
          )}
          <input
            type="number"
            placeholder={placeholder}
            className={`custom-form-control form-control`}
            name={name}
            value={value}
            onChange={onChange}
            style={{
              ...props.style,
              borderLeft: getValue(props, `required`, false)
                ? "2px solid red"
                : "",
            }}
            disabled={disabled}
            id={props.id}
            onWheel={(e: any) => e.target.blur()}
            // ref={props.ref}
          />
          {validator && (
            <p
              className={`error-text ${
                getValue(props, `validLeft`, "") ? "" : "text-center"
              }`}
            >
              {validator.current.message(label, value, "required")}
            </p>
          )}
        </div>
      );

    case "EMAIL":
      return (
        <div
        // className={`form-group ${
        //   validator && validator.current.message(label, value, "required")
        //     ? "form-wrapper__form-group--error"
        //     : ""
        // }`}
        >
          {!getValue(props, `hideLabel`, "") && (
            // <label
            //   className={`create-user-form-body__label mb-1`}
            // >
            //   {label}
            // </label>
            <label className={`form-label`}>
              {label}{" "}
              {(mandatory || required || hasRequired) && (
                <span className="color_danger small_text__16">*</span>
              )}
            </label>
          )}
          <input
            type={inputType}
            placeholder={placeholder}
            className={`custom-form-control form-control`}
            name={name}
            value={value}
            onChange={onChange}
            style={{
              ...props.style,
              borderLeft: getValue(props, `required`, false)
                ? "2px solid red"
                : "",
            }}
            disabled={disabled}
            id={props.id}
            // ref={props.ref}
          />
          {validator && (
            <p
              className={`error-text ${
                getValue(props, `validLeft`, "") ? "" : "text-center"
              }`}
            >
              {validator.current.message(label, value, "required|email")}
            </p>
          )}
        </div>
      );

    case "TEXTAREA":
      return (
        <div
          className={`form-group ${
            validator && validator.current.message(label, value, "required")
              ? "form-wrapper__form-group--error"
              : ""
          }`}
        >
          <div className="w-100 d-flex justify-content-between align-items-center">
            {!getValue(props, `hideLabel`, "") && (
              // <label
              //   className={`create-user-form-body__label mb-1`}
              // >
              //   {label}
              // </label>
              <label className={`form-label`}>
                {label}
                {(mandatory || required) && "*"}
              </label>
            )}
            {getValue(props, `showCount`, false) && (
              <p className={`small_text__12 ${hideFormLabel && "mb-1"}`}>
                {getValue(value, `length`, "0")} /{" "}
                {getValue(props, `maxLength`, "0")}
              </p>
            )}
          </div>
          <textarea
            placeholder={placeholder}
            className={`custom-form-control-textarea  form-control-textarea`}
            name={name}
            value={value}
            onChange={onChange}
            autoFocus={props.autoFocus}
            maxLength={props.maxLength}
            style={{
              ...props.style,
              borderLeft:
                getValue(props, `required`, false) ||
                getValue(props, `mandatory`, false)
                  ? "2px solid red"
                  : "",
            }}
            rows={props.rows ? props.rows : 5}
            disabled={disabled}
          />
          {validator && (
            <p
              className={`error-text ${
                getValue(props, `validLeft`, "") ? "" : "text-center"
              }`}
            >
              {validator.current.message(label, value, "required")}
            </p>
          )}
        </div>
      );
    case "REACT_SELECT":
      return (
        <div
          className={`form-group ${
            validator && validator.current.message(label, value, "required")
              ? "form-wrapper__form-group--error"
              : ""
          }`}
        >
          {!hidelabel && (
            <label
              // className={`create-user-form-body__label mb-1`}
              className="form-label"
            >
              {label}
            </label>
          )}
          <Select
            styles={
              getValue(props, `style`, {})
                ? getValue(props, `style`, {})
                : customStyles
            }
            placeholder={placeholder}
            // className={`custom-form-control form-control`}
            options={options}
            name={name}
            value={value}
            onChange={onChange}
            isDisabled={disabled}
            isMulti={props.isMulti}
          />
          {validator && (
            <p className={`error-text text-center`}>
              {validator.current.message(label, value, "required")}
            </p>
          )}
        </div>
      );

    case "DATE":
      return (
        <div
          className={`form-group ${
            validator && validator.current.message(label, value, "required")
              ? "form-wrapper__form-group--error"
              : ""
          } ${marginZero && "mb-0"}`}
        >
          {!getValue(props, `hideLabel`, "") && (
            <label className={`form-label`}>
              {label} {mandatory && "*"}
            </label>
          )}
          <DatePicker
            className={`custom-form-control form-control`}
            selected={value ? new Date(value) : null}
            onChange={onChange}
            peekNextMonth
            // startDate={startDate}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={props.maxDate}
            minDate={props.minDate}
            placeholderText={placeholder}
            dateFormat="d MMM yyyy"
          />
          {validator && (
            <p
              className={`error-text ${
                getValue(props, `validLeft`, "") ? "" : "text-center"
              }`}
            >
              {validator.current.message(label, value, "required")}
            </p>
          )}
        </div>
      );

    case "DATETIME":
      return (
        <div
          className={`form-group ${
            validator && validator.current.message(label, value, "required")
              ? "form-wrapper__form-group--error"
              : ""
          }`}
        >
          {!getValue(props, `hideLabel`, "") && (
            <label className={`form-label`}>
              {label} {mandatory && "*"}
            </label>
          )}
          <div>
            <DatePicker
              className={`custom-form-control form-control-date`}
              selected={value ? getDate(value) : null}
              onChange={onChange}
              placeholderText={placeholder}
              showTimeSelect
              showMonthDropdown
              showYearDropdown
              //   showTimeSelectOnly
              timeIntervals={15}
              dateFormat="d MMM yyyy, p"
              timeCaption="Time"
              minDate={props.minDate}
              filterTime={props.filterTime}
            />
          </div>
          {validator && (
            <p
              className={`error-text ${
                getValue(props, `validLeft`, "") ? "" : "text-center"
              }`}
            >
              {validator.current.message(label, value, "required")}
            </p>
          )}
        </div>
      );

    case "TIME":
      return (
        <div
          className={`form-group ${
            validator && validator.current.message(label, value, "required")
              ? "form-wrapper__form-group--error"
              : ""
          }`}
        >
          {!getValue(props, `hideLabel`, "") && (
            <label className={`form-label`}>
              {label} {mandatory && "*"}
            </label>
          )}
          <DatePicker
            className={`custom-form-control form-control`}
            placeholderText={placeholder}
            selected={value ? getDate(value) : null}
            onChange={onChange}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15} // Set the time interval (in minutes)
            dateFormat="h:mm aa" // Display format for the time
          />
          {validator && (
            <p
              className={`error-text ${
                getValue(props, `validLeft`, "") ? "" : "text-center"
              }`}
            >
              {validator.current.message(label, value, "required")}
            </p>
          )}
        </div>
      );

    case "RADIO":
      return (
        <div
          className={`form-group cursor-pointer ${
            validator && validator.current.message(label, value, "required")
              ? "form-wrapper__form-group--error"
              : ""
          }`}
          onClick={onChange}
        >
          <div className="d-flex">
            <input
              type={"radio"}
              className={`form-radio-control cursor-pointer`}
              name={name}
              value={value}
              // onChange={onChange}
              checked={checked}
            />
            {!hidelabel && (
              <label
                className={`create-user-form-body__label mb-1 cursor-pointer`}
                // onClick={onChange}
              >
                {label}
              </label>
            )}
          </div>
          {validator && (
            <p className={`error-text text-center`}>
              {validator.current.message(label, value, "required")}
            </p>
          )}
        </div>
      );
    case "CHECKBOX":
      return (
        <div
          className={`form-group cursor-pointer ${
            validator && validator.current.message(label, value, "required")
              ? "form-wrapper__form-group--error"
              : ""
          }`}
          onClick={onChange}
        >
          <div className="d-flex">
            <input
              type={"checkbox"}
              className={`form-checkbox-control cursor-pointer`}
              name={name}
              value={value}
              // onChange={onChange}
              checked={checked}
            />
            {!hidelabel && (
              <label
                className={`create-user-form-body__label mb-1 cursor-pointer`}
                // onClick={onChange}
              >
                {label}
              </label>
            )}
          </div>
          {validator && (
            <p className={`error-text text-center`}>
              {validator.current.message(label, value, "required")}
            </p>
          )}
        </div>
      );

    default:
      return null;
  }
}

export default InputRuleForm;
