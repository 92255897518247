import * as React from "react";
import { Button, Modal } from "antd";
import { getValue } from "@utils/lodash";
import "./addpopup.scss";
interface IDeleteModalProps {
  isOpen: boolean;
  handleModal: () => void;
  handleSubmit: () => void;
  deleteValue: string;
  deleteMessage?: string;
}
const DeleteModal: React.FunctionComponent<IDeleteModalProps> = (props) => {
  return (
    <Modal
      open={getValue(props, `isOpen`, "")}
      // toggle={props.handleModal}
      onOk={props.handleSubmit}
      onCancel={props.handleModal}
      title="Confirm Delete"
      footer={[
        <Button
          key="cancel"
          onClick={props.handleModal}
          className="cancel__button"
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={props.handleSubmit}
          className="submit__button"
        >
          Delete
        </Button>,
      ]}
    >
      <p className="small_text__14">
        {props.deleteMessage ? (
          <>{props.deleteMessage}</>
        ) : (
          <span>
            {getValue(props, `deleteValue`, "") ? (
              <>
                Are you sure you want to delete{" "}
                <b className="mx-1">{getValue(props, `deleteValue`, "")}</b>?
              </>
            ) : (
              "Are you sure you want to delete?"
            )}
          </span>
        )}
      </p>
    </Modal>
  );
};

export default DeleteModal;
