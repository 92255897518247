import "./SubHeader.scss";
import { Link } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { Tooltip } from "react-tooltip";
import BackSvgComponent from "@assets/svg/back-link";
import { useState } from "react";
import SelectDropdown from "@components/custom/Dropdown/SelectDropdown";
import {
  generatePipelinePayload,
  sortPipelineFieldList,
} from "../helpers/create-pipeline-helper";
import {
  createPipeline,
  getSpecificPipeline,
} from "@services/pipeline.service";
import { removeDuplicatesById } from "@components/helpers/request-helper";
import { plural, singular } from "pluralize";
import { snakeCase } from "lodash";

export default function PipelineSubHeader({
  subHeaderListTitle,
  isRightArrowVisible,
  subMenuArray,
  children,
  pipeline,
  options,
  selectedPipeline,
  handleClickPipeline,
  showBack,
  handleNavigateBack,
  moduleInfo,
  tourRef2,
  addButton,
  component,
}: any) {
  const [pipelineLoading, setPipelineLoading] = useState(false);
  const handleAddPipeline = async (name: string) => {
    getPipeline(name);
  };

  const getPipeline = async (name: string) => {
    try {
      let resp = await getSpecificPipeline(
        getValue(moduleInfo, `id`, ""),
        getValue(options, `[${0}].id`, "")
      );
      if (resp) {
        let list = sortPipelineFieldList(
          getValue(resp, `data.form_fields`, [])
        );
        let formList =
          getValue(list, `length`, 0) > 0
            ? list.filter((item: object) =>
                getValue(item, `form_default`, false)
              )
            : [];

        const openStages = getValue(resp, `data.stages`, []).filter(
          (item: object) => getValue(item, `type`, "") === "OPEN"
        );
        const closedWonStages = getValue(resp, `data.stages`, []).filter(
          (item: object) => getValue(item, `type`, "") === "CLOSED_WON"
        );
        const closedLostStages = getValue(resp, `data.stages`, []).filter(
          (item: object) => getValue(item, `type`, "") === "CLOSED_LOST"
        );
        let obj = {
          name: name,
          singular_label: singular(snakeCase(name)),
          plural_label: plural(snakeCase(name)),
          openStages: openStages.map((item: object) => ({
            name: getValue(item, `api_name`, ""),
            label: getValue(item, `label`, ""),
            type: getValue(item, `type`, ""),
            color_code: getValue(item, `color_code`, ""),
          })),
          closedWonStages: closedWonStages.map((item: object) => ({
            name: getValue(item, `api_name`, ""),
            label: getValue(item, `label`, ""),
            type: getValue(item, `type`, ""),
            color_code: getValue(item, `color_code`, ""),
          })),
          closedLostStages: closedLostStages.map((item: object) => ({
            name: getValue(item, `api_name`, ""),
            label: getValue(item, `label`, ""),
            type: getValue(item, `type`, ""),
            color_code: getValue(item, `color_code`, ""),
          })),
          fields: removeDuplicatesById(formList),
        };
        handleCreatePipeline(obj);
      }
    } catch (error) {}
  };

  const handleCreatePipeline = async (obj: object) => {
    try {
      setPipelineLoading(false);
      const payload = generatePipelinePayload(obj, "");
      let resp = await createPipeline(getValue(moduleInfo, `id`, ""), payload);
      if (resp) {
        setPipelineLoading(false);
        handleClickPipeline(getValue(resp, `data`, {}));
      } else {
        setPipelineLoading(false);
      }
    } catch (error) {
      setPipelineLoading(false);
    }
  };

  return (
    <div className={`sub-header-wrapper`}>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          {showBack && (
            <ul onClick={handleNavigateBack}>
              <li className={`gap d-flex align-items-center cursor-pointer`}>
                <BackSvgComponent />
              </li>
            </ul>
          )}
          <ul ref={tourRef2}>
            <li className={`gap d-flex align-items-center cursor-pointer`}>
              {!showBack && (
                <img
                  src="/images/header/star.svg"
                  className="img-fluid"
                  alt="star"
                />
              )}
              <h6 className={`submenu-name ${showBack ? "ms-3" : ""}`}>
                {subHeaderListTitle}
              </h6>

              {isRightArrowVisible && (
                <img
                  src="/images/header/right-arrow.svg"
                  className="img-fluid"
                  alt="right-arrow"
                />
              )}
            </li>
          </ul>
          {subMenuArray?.length >= 0 && (
            <ul className={"header-wrapper__navigation-list"}>
              {subMenuArray.map((subMenuObj: any, index: any) => {
                return (
                  <li
                    className={"sub-header-wrapper__navigation-list-items"}
                    key={`subMenu-${index}`}
                  >
                    <Link
                      to={subMenuObj.linkUrl}
                      className={`sub-header-wrapper__sub-navigation-title ${
                        window.location.pathname === subMenuObj.linkUrl
                          ? `active`
                          : ""
                      }`}
                    >
                      {subMenuObj.linkText}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
          {pipeline && (
            <div>
              <SelectDropdown
                placeholder={`Enter ${
                  window.location.pathname.split("/").pop() === "leads"
                    ? "List Name"
                    : "Pipeline"
                }`}
                data={options}
                value={getValue(selectedPipeline, `id`, "")}
                selectKey={"id"}
                nameKey={`${
                  window.location.pathname.split("/").pop() === "leads"
                    ? "List"
                    : "Pipeline"
                }`}
                onAdd={handleAddPipeline}
                isLoading={pipelineLoading}
                onSelect={handleClickPipeline}
              />
              {/* <SearchToolTip
                label={"label"}
                value={getValue(selectedPipeline, `id`, "")}
                onChange={handleClickPipeline}
                width={"280px"}
                data={options}
                button_title={"Create Pipeline"}
                handleOpenPopup={handleOpenPopup}
                isLoading={isLoading}
                add
              /> */}
            </div>
          )}
          {component && (
            <div className="d-flex align-items-center gap-3">
              <img
                src="/images/header/right-arrow.svg"
                className="img-fluid"
                alt="right-arrow"
              />
              {component}
            </div>
          )}
          {/* <Select
            style={{ width: 300 }}
            placeholder="custom dropdown render"
            dropdownRender={(options) => (
              <>
                {options}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Button type="text" onClick={addItem} className="w-100">
                    Create pipeline
                  </Button>
                </Space>
              </>
            )}
            options={items.map((item) => ({ label: item, value: item }))}
          /> */}
        </div>
        {children}
      </div>
      {addButton && addButton}
      <Tooltip id={`pipeline`} className="tooltip" />
    </div>
  );
}
