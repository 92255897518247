// import CompanyProfileNavigation from "layout/CompanyProfileDetailsLayout/CompanyProfileNavigation/CompanyProfileNavigation";
import { getValue } from "@utils/lodash";
import "./CompanyProfileDetailsLayout.scss";
import CompanyProfileNavigation from "./CompanyProfileNavigation";
import { usePermissionContext } from "@context/permissionContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  AINavigationList,
  CRMNavigationList,
  ChatNavigationList,
  GeneralNavigationList,
  HelpdestNavigationList,
  filterNavigationListNew,
} from "@pages/Private/Settings/helpers/settings-permissions";
import NoAccessComponent from "@layouts/HomeLayout/noaccess";
import { Bot, Box, LifeBuoy, Reply, Shapes } from "lucide-react";
import BackSvgComponent from "@assets/svg/back-link";
import SettingsBackSvgComponent from "@assets/svg/settings-back-arrow";
import CategorySvgComponent from "@assets/svg/category";
import CategoryFilledSvgComponent from "@assets/svg/category-filled";
import CrmSvgComponent from "@assets/svg/crm";
import CrmFilledSvgComponent from "@assets/svg/crm-filled";
import SupportSvgComponent from "@assets/svg/support";
import SettingsChatSvgComponent from "@assets/svg/settings-chat";
import SettingsChatFilledSvgComponent from "@assets/svg/settings-chat-filled";

export default function CompanyProfileDetailsLayout({
  children,
  classname,
  ...restProps
}: any) {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { tabPermissionList, subTabPermissionList } = usePermissionContext();

  const determineDefaultPath = () => {
    if (location.pathname.includes("crm")) return "crm";
    if (location.pathname.includes("helpdesk")) return "helpdesk";
    if (location.pathname.includes("chat")) return "chat";
    return "general";
  };
  const [activePath, setActivePath] = useState<string>(determineDefaultPath());

  useEffect(() => {
    setActivePath(determineDefaultPath());
  }, [location.pathname]);

  const handleClick = (path: string) => {
    setActivePath(path);
    const routeList = getFun(path);
    navigate(`${getValue(routeList, `[${0}].linkArray[${0}].linkUrl`, "")}`);
  };

  const getFun = (route: string) => {
    switch (route) {
      case "general":
        return filterNavigationListNew(
          tabPermissionList,
          subTabPermissionList,
          GeneralNavigationList(params)
        );
      case "crm":
        return filterNavigationListNew(
          tabPermissionList,
          subTabPermissionList,
          CRMNavigationList(params)
        );
      case "helpdesk":
        return filterNavigationListNew(
          tabPermissionList,
          subTabPermissionList,
          HelpdestNavigationList(params)
        );
      case "chat":
        return filterNavigationListNew(
          tabPermissionList,
          subTabPermissionList,
          ChatNavigationList(params)
        );
      case "ai":
        return filterNavigationListNew(
          tabPermissionList,
          subTabPermissionList,
          AINavigationList(params)
        );
      default:
        return filterNavigationListNew(
          tabPermissionList,
          subTabPermissionList,
          GeneralNavigationList(params)
        );
    }
  };
  return (
    <div className={`d-flex w-100`}>
      <div className="settings-vertical-sidebar">
        <div
          onClick={() => navigate(`/${getValue(params, "orgId", "")}/home`)}
          className="settings-home-container"
        >
          <div className="back-to-app-button">
            <SettingsBackSvgComponent />
          </div>
        </div>
        {getValue(getFun("general"), `length`, 0) > 0 && (
          <div
            className={`verticla-tab-item ${
              activePath === "general" ? "vertical-active" : ""
            }`}
            onClick={() => handleClick("general")}
          >
            <div className="vertical-tab-item-icon">
              {activePath === "general" ? (
                <CategoryFilledSvgComponent />
              ) : (
                <CategorySvgComponent />
              )}
            </div>
            General
          </div>
        )}
        {getValue(getFun("crm"), `length`, 0) > 0 && (
          <div
            className={`verticla-tab-item ${
              activePath === "crm" ? "vertical-active" : ""
            }`}
            onClick={() => handleClick("crm")}
          >
            <div className="vertical-tab-item-icon">
              {activePath === "crm" ? (
                <CrmFilledSvgComponent />
              ) : (
                <CrmSvgComponent />
              )}
            </div>
            CRM
          </div>
        )}
        {getValue(getFun("helpdesk"), `length`, 0) > 0 && (
          <div
            className={`verticla-tab-item ${
              activePath === "helpdesk" ? "vertical-active" : ""
            }`}
            onClick={() => handleClick("helpdesk")}
          >
            <div className="vertical-tab-item-icon">
              <SupportSvgComponent />
            </div>
            Helpdesk
          </div>
        )}
        {getValue(getFun("chat"), `length`, 0) > 0 && (
          <div
            className={`verticla-tab-item ${
              activePath === "chat" ? "vertical-active" : ""
            }`}
            onClick={() => handleClick("chat")}
          >
            <div className="vertical-tab-item-icon">
              {activePath === "chat" ? (
                <SettingsChatFilledSvgComponent size={22} />
              ) : (
                <SettingsChatSvgComponent />
              )}
            </div>
            <div className="handwave-emoji">👋</div>
            Chat
          </div>
        )}
      </div>
      <CompanyProfileNavigation />
      <div className={`company-profile-wrapper__main-section-wrapper `}>
        {/* <div className={`${classname}`}> */}
        {restProps.isLoading || !restProps.permissionAPITriggered ? (
          // <ListLoader />
          children
        ) : restProps.permissions.includes("read") ? (
          children
        ) : (
          <NoAccessComponent />
        )}
        {/* {children} */}
        {/* </div> */}
      </div>
    </div>
  );
}
