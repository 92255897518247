import React, { useState, useEffect } from "react";
import DeleteModal from "@components/custom/Modal/DeleteModal";
import {
  deleteArticle,
  deleteCategory,
  deleteCategoryFolder,
} from "@services/solutions.service";
import { getValue } from "@utils/lodash";
import { Dropdown, Input, Modal } from "antd";
import {
  Check,
  ChevronRight,
  ChevronUp,
  EllipsisVertical,
  Pencil,
  PlusCircle,
  Trash2,
  X,
} from "lucide-react";
import { toast } from "sonner";
import RenderFolders from "./Folders/RenderFolders";

const CategoryDetailsLeftSide = (props: any) => {
  const {
    params,
    navigate,
    selectedFolder,
    setSelectedFolder,
    handleFolderEdit,
    selectedArticle,
    categoriesList,
    handleCreateFolderModal,
    showCategoryModal,
    handleDeleteCategory,
    getCategoriesList,
    setCategoryModalId,
    handleCreateArticle,
    categoriesRequest,
    setCategoriesRequest,
    isCategoryCreationOpen,
    setIsCategoryCreationOpen,
    handleCategoriesSubmit,
    articleRequestCopy,
    selectedCategory,
    setSelectedCategory,
    setPreviousArticle,
    setNextArticle,
  } = props;

  const handleDeleteModal = (
    module: string,
    name: string,
    api: any,
    id: string
  ) => {
    Modal.confirm({
      title: `Are you sure you want to delete ${module} - ${name}?`,
      content: "Once deleted, it cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          let resp = await api(id);
          if (resp) {
            toast.success(`${module} deleted successfully`);
            getCategoriesList(true);
          }
        } catch (error) {
          toast.error(`Error deleting ${module}`);
        }
      },
    });
  };

  const [expandedCategories, setExpandedCategories] = useState<
    Record<string, boolean>
  >({});
  const [expandedFolders, setExpandedFolders] = useState<
    Record<string, boolean>
  >({});

  useEffect(() => {
    if (selectedFolder) {
      setExpandedFolders((prev) => ({
        ...prev,
        [selectedFolder]: true,
      }));
    }
    if (selectedCategory) {
      setExpandedCategories((prev) => ({
        ...prev,
        [selectedCategory]: true,
      }));
    }
  }, [selectedFolder, selectedCategory]);

  const handleToggleCategory = (categoryId: string) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }));
    setSelectedCategory(categoryId);
  };
  const handleToggleFolder = (folderId: string) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId],
    }));
    setSelectedFolder(folderId);
  };

  const renderCategoryMenu = (category: any) => [
    {
      key: "new",
      icon: <PlusCircle size={14} />,
      label: "New Folder",
      // onClick: () => {
      //   showCategoryModal();
      //   setCategoryModalId("");
      // },
      onClick: () => handleCreateFolderModal(getValue(category, "id", "")),
    },
    {
      key: "edit",
      icon: <Pencil size={14} />,
      label: "Edit",
      onClick: () =>
        showCategoryModal(
          getValue(category, "id", ""),
          getValue(category, "name", "")
        ),
    },
    {
      key: "delete",
      icon: <Trash2 size={14} />,
      label: "Delete",
      onClick: () =>
        handleDeleteModal(
          "Category",
          getValue(category, "name", ""),
          deleteCategory,
          getValue(category, "id", "")
        ),
      disabled: getValue(category, "folders.length", 0) > 0,
    },
  ];
  return (
    <div className="category-details-leftside">
      <div className="py-4 px-3">
        {categoriesList.map((category: any, index: number) => {
          const categoryId = getValue(category, "id", "");
          const isSelected = selectedCategory === categoryId;
          const isExpanded = expandedCategories[categoryId];
          return (
            <div
              key={index}
              className={`mb-3 ${
                index === categoriesList.length - 1 ? "" : "border-bottom pb-3"
              }`}
            >
              <div className="d-flex align-items-center justify-content-between gap-3 category-header">
                <h6
                  className="header_text__14 cursor-pointer"
                  onClick={() =>
                    handleToggleCategory(getValue(category, "id", ""))
                  }
                >
                  {getValue(category, "name", "")}
                  {!isExpanded ? (
                    <ChevronRight size={16} />
                  ) : (
                    <ChevronUp size={16} />
                  )}
                </h6>

                <Dropdown
                  menu={{ items: renderCategoryMenu(category) }}
                  className="more-options"
                >
                  <EllipsisVertical size={14} className="cursor-pointer" />
                </Dropdown>
              </div>
              {isExpanded && (
                <RenderFolders
                  category={category}
                  selectedFolder={selectedFolder}
                  expandedFolders={expandedFolders}
                  handleToggleFolder={handleToggleFolder}
                  handleCreateArticle={handleCreateArticle}
                  handleFolderEdit={handleFolderEdit}
                  handleDeleteModal={handleDeleteModal}
                  articleRequestCopy={articleRequestCopy}
                  selectedArticle={selectedArticle}
                  setPreviousArticle={setPreviousArticle}
                  setNextArticle={setNextArticle}
                />
              )}
            </div>
          );
        })}
        <div>
          {isCategoryCreationOpen ? (
            <div className="category-creation-input">
              <Input
                className=""
                value={getValue(categoriesRequest, "name", "")}
                onChange={(e) =>
                  setCategoriesRequest((prev: any) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleCategoriesSubmit();
                  }
                }}
              />
              <div className="d-flex align-items-center gap-2">
                <X
                  size={16}
                  color="red"
                  className="cursor-pointer"
                  onClick={() => setIsCategoryCreationOpen(false)}
                />
                <Check
                  size={16}
                  className="cursor-pointer"
                  onClick={() => handleCategoriesSubmit()}
                  color="#08d200"
                />
              </div>
            </div>
          ) : (
            <div
              className="add-button text-muted justify-content-center"
              onClick={() => setIsCategoryCreationOpen(true)}
            >
              <PlusCircle size={16} /> Create Category
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryDetailsLeftSide;
