// import { getStartOfMonthDate } from "@common/date-helpers";
import { getValue } from "@utils/lodash";
import { Link, useParams } from "react-router-dom";

export default function Logo() {
  const params = useParams();
  // let date_range = getStartOfMonthDate();
  return (
    <Link
      to={
        getValue(params, `orgId`, "")
          ? `/${getValue(params, `orgId`, "")}/home`
          : "#"
      }
      // to={
      //   getValue(params, `orgId`, "")
      //     ? `/${getValue(params, `orgId`, "")}/dashboard?date_range=${date_range}`
      //     : "#"
      // }
      className="header-logo"
    >
      <img
        // src="/images/rapid-sales-logo.svg"
        src="/appzo-logo.svg"
        className=" cursor-pointer object-fit-contain py-1 "
        alt="appzo-logo"
        width={40}
        height={40}
      />
    </Link>
  );
}
