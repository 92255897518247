import React, { useRef, useState, useEffect, useCallback, memo } from "react";
import { Image, MessageSquareText, Smile } from "lucide-react";
import EmojiPicker from "./components/EmojiPicker";
import { formatMarkdownText } from "./helpers/textFormatter";
import FormatToolbar from "./components/FormatToolbar";
import "./styles/editor.scss";
import "./styles/floating-toolbar.scss";

function MarkdownEditor(props: any) {
  const [message, setMessage] = useState("");
  const [preview, setPreview] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selection, setSelection] = useState<{
    text: string;
    rect?: DOMRect | null;
  }>({
    text: "",
    rect: null,
  });

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const cursorPositionRef = useRef<{ start: number; end: number }>({
    start: 0,
    end: 0,
  });
  const isComposingRef = useRef(false);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.addEventListener("select", handleSelectionChange);
    textarea.addEventListener("click", handleSelectionChange);
    textarea.addEventListener("keyup", handleSelectionChange);

    return () => {
      textarea.removeEventListener("select", handleSelectionChange);
      textarea.removeEventListener("click", handleSelectionChange);
      textarea.removeEventListener("keyup", handleSelectionChange);
    };
  }, [message]);

  useEffect(() => {
    if ((props.value && !message) || props.parentChage) {
      setMessage(props.value);
      if (props.parentChage) {
        props.setParentChage(false);
      }
    }
  }, [props.value, props.parentChage]);

  const setCursorPosition = useCallback((start: number, end: number) => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(start, end);
    }
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = e.target.value;
      const newCursorStart = e.target.selectionStart;
      const newCursorEnd = e.target.selectionEnd;

      // Don't update if we're in IME composition
      if (isComposingRef.current) {
        return;
      }

      // Store cursor position before update
      cursorPositionRef.current = {
        start: newCursorStart,
        end: newCursorEnd,
      };

      const sanitizedValue = newValue.replace(/\uFFFD/g, "");
      setMessage(sanitizedValue);
      setPreview(formatMarkdownText(sanitizedValue));
      props.handleChangeEditor(sanitizedValue);

      // Restore cursor position in the next tick
      setTimeout(() => {
        setCursorPosition(newCursorStart, newCursorEnd);
      }, 0);
    },
    [props.handleChangeEditor, setCursorPosition]
  );

  const handleCompositionStart = () => {
    isComposingRef.current = true;
  };

  const handleCompositionEnd = () => {
    isComposingRef.current = false;
  };

  const handleFormat = (format: string) => {
    if (!textareaRef.current) return;

    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = message.substring(start, end);

    const beforeText = message.substring(0, start);
    const afterText = message.substring(end);

    const newText = `${beforeText}${format}${selectedText}${format}${afterText}`;
    setMessage(newText);
    setPreview(formatMarkdownText(newText));
    props.handleChangeEditor(newText);

    // Set new cursor position after formatting
    const newPosition = start + format.length;
    setTimeout(() => {
      setCursorPosition(newPosition, end + format.length);
    }, 0);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!props.removeEnterAction && e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (message.trim()) {
        props.handleChangeEditor(message);
        setMessage("");
        setPreview("");
      }
    }
  };

  const handleEmojiSelect = (emoji: any) => {
    if (!textareaRef.current) return;

    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    const beforeText = message.substring(0, start);
    const afterText = message.substring(end);
    const emojiToInsert = emoji.native || emoji.emoji || emoji.toString();

    const newText = `${beforeText}${emojiToInsert}${afterText}`;
    setMessage(newText);
    setPreview(formatMarkdownText(newText));
    props.handleChangeEditor(newText);
    const newPosition = start + emojiToInsert.length;
    setTimeout(() => {
      setCursorPosition(newPosition, newPosition);
    }, 0);
    setShowEmojiPicker(false);
  };

  const handleSelectionChange = () => {
    if (!textareaRef.current) return;

    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = message.substring(start, end);

    cursorPositionRef.current = { start, end };

    if (selectedText) {
      const selection = window.getSelection();
      const range = selection?.getRangeAt(0);
      const rect = range?.getBoundingClientRect();
      setSelection({ text: selectedText, rect });
    } else {
      setSelection({ text: "", rect: null });
    }

    if (props.handleCursorMovement) {
      props.handleCursorMovement(textarea);
    }
  };

  const fileInputRef = useRef<any>(null);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e: any) => {
    props.handleUploadDocument(e);
  };

  useEffect(() => {
    setMessage(props.value);
  }, [props.value]);

  return (
    <div className="editor">
      <div className="editor__toolbar">
        <FormatToolbar onFormat={handleFormat} />
        {!props.hideEmoji && (
          <div className="editor__emoji">
            <button
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className="emoji-button"
              title="Emoji"
            >
              <Smile color="#606060" />
            </button>
            {showEmojiPicker && (
              <div className="emoji-picker">
                <EmojiPicker
                  onEmojiSelect={handleEmojiSelect}
                  onClose={() => setShowEmojiPicker(false)}
                />
              </div>
            )}
          </div>
        )}
        {props.showCanned && (
          <div className="format-toolbar">
            <button onClick={() => props.handleCannedEditorButton()}>
              <MessageSquareText color="#606060" size={20} />
            </button>
          </div>
        )}
        {props.showUpload && (
          <div className="format-toolbar">
            <button onClick={handleUploadClick}>
              <Image color="#606060" size={20} />
              <div className="d-flex align-items-center">
                <input
                  type="file"
                  className="visually-hidden"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
              </div>
            </button>
          </div>
        )}
      </div>

      <div className="editor__content">
        <textarea
          ref={textareaRef}
          value={message}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
          placeholder="Type a message..."
          style={props.style}
        />
      </div>
    </div>
  );
}

export default memo(MarkdownEditor);
