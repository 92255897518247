import { convertCurrentDate } from "@common/date-helpers";
import { getValue } from "@utils/lodash";
import "./index.scss";
import TicketTopMenu from "./components/top-menu";
import TicketNoteCard from "./components/notes/ticket-note-card";
import { useEffect, useState } from "react";
import {
  addTicketNote,
  deleteTicket,
  deleteTicketNote,
  mergeTicket,
  ticketConversation,
  updateTicketNote,
} from "@services/tickets.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "@components/common/Loader/loading";
import AddNotePopup from "./components/notes/add-note-popup";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { toast } from "sonner";
import NoDocumentFound from "@components/common/NoData/NoDocument";
import { handleUpdateArray } from "@common/handleChange";
import TicketMergeDrawer from "./components/merge/merge-drawer";
import {
  primaryIntialState,
  secondaryIntialState,
} from "./components/request-helper";

const TicketsMiddleSection = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    // if (Object.keys(UrlParams).length > 0) {
    getData();
  }, [window.location.href, getValue(params, `id`, "")]);

  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [ticketConversations, setTicketConversations] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      const payload = {};
      const queryRequest = QueryRequestHelper(payload);
      const resp = await ticketConversation(
        getValue(params, `id`, ""),
        queryRequest
      );
      if (resp) {
        setTicketConversations(getValue(resp, `data.conversations`, []));
        setIsLoading(false);
      } else {
        setTicketConversations([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setTicketConversations([]);
    }
  };

  /* ------------------------ Delete Ticket Section -------------------------- */
  const [isDeleteTicketOpen, setIsTicketOpen] = useState(false);
  const handleTicketModal = () => {
    setIsTicketOpen(!isDeleteTicketOpen);
  };
  const handleDeleteTicketView = (id: string, value: string) => {
    handleTicketModal();
  };

  const handleTicketDelete = async () => {
    try {
      let resp = await deleteTicket(getValue(params, `id`, ""));
      if (resp) {
        toast.success("Deleted Successfully");
        //delete
        handleTicketModal();
        navigate(`/${getValue(params, `orgId`, "")}/tickets`);
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Note Section                               */
  /* -------------------------------------------------------------------------- */
  const noteState = {
    html: "",
    to: [],
    is_private: true,
    attachments: [],
  };
  const [noteRequest, setNoteRequest] = useState(noteState);
  const resetNotes = () => {
    setEditId("");
    setNoteRequest(noteState);
  };
  const handleChangeNote = (name: string, value: any) => {
    setNoteRequest((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const [noteLoading, setNoteLoading] = useState(false);
  const handleCreateNote = async () => {
    try {
      let payload = removeNullOrUndefinedProperties(noteRequest);
      setNoteLoading(true);
      let resp = editId
        ? await updateTicketNote(editId, payload)
        : await addTicketNote(getValue(params, `id`, ""), payload);
      if (resp) {
        toast.success(editId ? "Updated successfully" : "Created successfully");
        setNoteLoading(false);
        resetNotes();
        toggleNote();
        getData();
      } else {
        setNoteLoading(false);
      }
    } catch (error) {
      setNoteLoading(false);
    }
  };
  const handleFilterChange = (value: any, fieldName: string) => {
    setNoteRequest((prev: any) => ({
      ...prev,
      [fieldName]: handleUpdateArray(
        getValue(value, `id`, ""),
        getValue(noteRequest, `${fieldName}`, [])
      ),
    }));
  };

  /* ------------------------ Notes Toggle Section  -------------------------- */
  const [openNote, setOpenNote] = useState(false);
  const toggleNote = () => {
    setOpenNote(!openNote);
  };

  /* ------------------------ Edit Notes Section  -------------------------- */

  const [editId, setEditId] = useState("");
  const handleEditNote = (item: object) => {
    setEditId(getValue(item, `id`, ""));
    setNoteRequest({
      ...noteRequest,
      html: getValue(item, `html`, ""),
      to:
        typeof getValue(item, `to`, "") === "string"
          ? [getValue(item, `to`, [])]
          : getValue(item, `to`, []),
    });
    toggleNote();
  };
  /* ------------------------ Delete Notes Section -------------------------- */
  const [deleteId, setDeleteId] = useState("");
  const [deleteValue, setDeleteValue] = useState("");
  const [isDeleteOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isDeleteOpen);
  };
  const handleDeleteView = (id: string, value: string) => {
    setDeleteId(id);
    setDeleteValue(value);
    handleModal();
  };
  const handleDelete = async () => {
    try {
      let resp = await deleteTicketNote(deleteId);
      if (resp) {
        toast.success("Deleted Successfully");
        //delete
        handleModal();
        getData();
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                                 merge Section                              */
  /* -------------------------------------------------------------------------- */
  const [mergeOpen, setMergeOpen] = useState(false);
  const handleMergeToggle = () => {
    setMergeOpen(!mergeOpen);
  };
  const [primary_id, setPrimaryId] = useState("");
  const [convert_recepients_to_cc, setConvert_recepients_to_cc] = useState(
    false
  );
  const [note_in_primary, setNoteInPrimary] = useState(primaryIntialState);
  const [note_in_secondary, setNoteInSecondary] = useState(
    secondaryIntialState
  );
  const handleClickMerge = () => {
    handleChangePrimary(getValue(params, `id`, ""));
    handlePrimaryNote(getValue(params, "id", ""));
    handleMergeToggle();
  };
  const handleClickOptionMerge = (option: any) => {
    handleChangePrimary(getValue(params, `id`, ""));
    if (getValue(note_in_primary, `ticketInfo.id`, "")) {
      handleSecondaryNote(getValue(option, `id`, ""));
    } else {
      handlePrimaryNote(getValue(option, `id`, ""));
    }
  };
  const handlePrimaryNote = (id: string) => {
    const ticketList = getValue(props, "ticketList", []);
    const info = ticketList.find(
      (item: any) => getValue(item, "id", "") === id
    );
    setNoteInPrimary({
      ...note_in_primary,
      ticketInfo: info,
      html: `Tickets with IDs ${getValue(
        note_in_secondary,
        "ticketInfo.record_num",
        ""
      ).toString()} is merged into this ticket. `,
      is_private: true,
    });
  };
  const handleSecondaryNote = (id: string) => {
    const ticketList = getValue(props, "ticketList", []);
    const info = ticketList.find(
      (item: any) => getValue(item, "id", "") === id
    );
    setNoteInSecondary({
      ...note_in_secondary,
      ticketInfo: info,
      html: `This ticket is closed and merged into ticket ${getValue(
        note_in_primary,
        "ticketInfo.record_num",
        ""
      ).toString()} is merged into this ticket.`,
      is_private: true,
    });
  };
  const handleChangePrimary = (id: string) => {
    setPrimaryId(id);
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmitMerge = async () => {
    try {
      setSubmitLoading(true);
      const payload = {
        convert_recepients_to_cc: convert_recepients_to_cc,
        primary_id: primary_id,
        ticket_ids: [
          getValue(note_in_primary, `ticketInfo.id`, ""),
          getValue(note_in_secondary, `ticketInfo.id`, ""),
        ],
        note_in_primary: {
          html: getValue(note_in_primary, `html`, ""),
          is_private: getValue(note_in_primary, `is_private`, false),
        },
        note_in_secondary: {
          html: getValue(note_in_secondary, `html`, ""),
          is_private: getValue(note_in_secondary, `is_private`, false),
        },
      };
      const resp = await mergeTicket(payload);
      if (resp) {
        setSubmitLoading(false);
        getData();
        handleMergeToggle();
        props.getListData();
        reset();
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  const reset = () => {
    setConvert_recepients_to_cc(false);
    setNoteInPrimary(primaryIntialState);
    setNoteInSecondary(secondaryIntialState);
    setPrimaryId(getValue(params, `id`, ""));
  };

  return (
    <>
      <div className="ticket_middle_container">
        <TicketTopMenu
          toggleNote={toggleNote}
          handleCloseStatus={props.handleCloseStatus}
          handleMergeToggle={handleClickMerge}
          permissions={getValue(props, `permissions`, [])}
          handleDeleteTicketView={handleDeleteTicketView}
        />
        <div className="ticket-detail-time-wrapper">
          <p className="ticket-detail-time-container">
            {convertCurrentDate(getValue(props, "data.created_at", ""))}
          </p>
        </div>
        {isLoading ? (
          <Loader />
        ) : getValue(ticketConversations, `length`, 0) > 0 ? (
          <>
            {ticketConversations.map((item: object) => {
              return (
                <TicketNoteCard
                  data={item}
                  handleDeleteView={handleDeleteView}
                  handleEditNote={handleEditNote}
                />
              );
            })}
          </>
        ) : (
          <NoDocumentFound title={"No data found"} />
        )}
      </div>

      {/* -------------------- Add Note Section --------------- */}
      <AddNotePopup
        isOpen={openNote}
        handleModal={toggleNote}
        handleSubmit={handleCreateNote}
        isLoading={noteLoading}
        handleChangeNote={handleChangeNote}
        request={noteRequest}
        editId={editId}
        handleFilterChange={handleFilterChange}
        resetNotes={resetNotes}
      />
      {/* -------------------- Delete Note Section --------------- */}
      <DeleteModal
        isOpen={isDeleteOpen}
        handleModal={handleModal}
        handleSubmit={handleDelete}
        deleteValue={deleteValue}
      />

      {/* -------------------- Delete Ticket Section --------------- */}
      <DeleteModal
        isOpen={isDeleteTicketOpen}
        handleModal={handleTicketModal}
        handleSubmit={handleTicketDelete}
        deleteValue={""}
      />
      {/* -------------------- Merge Section --------------- */}

      <TicketMergeDrawer
        isOpen={mergeOpen}
        toggle={handleMergeToggle}
        handleClickMerge={handleClickMerge}
        permissions={getValue(props, `permissions`, [])}
        note_in_primary={note_in_primary}
        setNoteInPrimary={setNoteInPrimary}
        note_in_secondary={note_in_secondary}
        setNoteInSecondary={setNoteInSecondary}
        primary_id={primary_id}
        setPrimaryId={setPrimaryId}
        handleClickOptionMerge={handleClickOptionMerge}
        convert_recepients_to_cc={convert_recepients_to_cc}
        setConvert_recepients_to_cc={setConvert_recepients_to_cc}
        handleSubmit={handleSubmitMerge}
        submitLoading={submitLoading}
      />
    </>
  );
};

export default TicketsMiddleSection;
