import { getValue } from "@utils/lodash";
import React, { useState } from "react";
import { deleteModuleCommon } from "@services/common.service";
import { toast } from "sonner";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import "swiper/css";
import TicketSubHeader from "../../../../components/Pages/Tickets/SubHeader";
import TicketsLeftSction from "./left-section";
import Loader from "@components/common/Loader/loading";
import TicketsMiddleSection from "./middle-section";
import TicketsRightSection from "./right-section";

export default function CommonTicketDetailPage(props: any) {
  const navigate = useNavigate();
  const params = useParams();
  //----------------- Sidebar Section ----------------//

  const [showSidebar, setShowSidebar] = useState(true);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  /* ----------------------------- Delete View Section  ------------------------- */
  const [isOpen, setIsOpen] = React.useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
  };
  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteModuleCommon(
        getValue(params, `id`, ""),
        props.module
      );
      if (resp) {
        toast.success("Deleted Successfully");
        handleModal();
        navigate(
          `/${getValue(params, `orgId`, "")}/${getValue(props, `module`, "")}`
        );
      }
    } catch (error) {}
  };
  return (
    <div>
      <TicketSubHeader
        subHeaderListTitle={getValue(props, `moduleInfo.linkText`, "")}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        subMenuArray={props.subMenuArray}
        handleOpenPopup={toggleSidebar}
        moduleInfo={props.moduleInfo}
        toggleSidebar={toggleSidebar}
        hideView
        showBack
        handleNavigateBack={() =>
          navigate(`/${getValue(params, "orgId", "")}/tickets`)
        }
        params={props.params}
      />
      <div
        className={
          getValue(props, `isChanged`, false)
            ? "pending-wrapper__main-section_after_change"
            : "ticket-wrapper__main-section_detail"
        }
      >
        <div className={"ticket-wrapper__main-body row p-0 m-0"}>
          <div className="col-4 ticket-detail-ticket-list">
            <TicketsLeftSction
              ticketList={props.ticketList}
              selectedTicket={props.selectedTicket}
            />
          </div>
          <div className="col-8 p-3">
            {getValue(props, `isLoading`, false) ? (
              <Loader />
            ) : (
              <TicketsMiddleSection
                data={props.data}
                permissions={getValue(props, `permissions`, [])}
                handleCloseStatus={props.handleCloseStatus}
                ticketList={props.ticketList}
                getListData={props.getListData}
              />
            )}
          </div>
        </div>
        <TicketsRightSection
          data={getValue(props, `data`, {})}
          request={props.request}
          setRequest={props.setRequest}
          fields={props.fields}
          setFields={props.setFields}
          form={props.form}
          setForm={props.setForm}
          getForm={props.getForm}
          getData={props.getData}
          module={props.module}
          permissions={getValue(props, `permissions`, [])}
          formLoading={props.formLoading}
          handleDisAssociate={props.handleDisAssociate}
          pipelineList={props.pipelineList}
          staticFieldRequest={props.staticFieldRequest}
          setStaticFieldRequest={props.setStaticFieldRequest}
          getSpecificPipelineInfo={props.getSpecificPipelineInfo}
        />
        <DeleteModal
          isOpen={isOpen}
          handleModal={handleModal}
          handleSubmit={handleDeleteFunction}
          deleteValue=""
        />
      </div>
    </div>
  );
}
