import { formatString } from "@common/text-helpers";
import React from "react";

const TicketKanbanView = ({ list }: any) => {
  return (
    <div className="d-flex gap-3">
      {list.map((view: any, index: number) => (
        <div key={index}>
          {Object.keys(view).map((key) => (
            <div key={key}>
              <strong>{formatString(key)}</strong>
              <div>
                {view[key]?.tickets?.length > 0
                  ? view[key].tickets.map((ticket: any, idx: number) => (
                      <div key={idx}>Ticket ID: {ticket.id}</div>
                    ))
                  : "No tickets"}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default TicketKanbanView;
