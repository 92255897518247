import "./SubHeader.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BackSvgComponent from "@assets/svg/back-link";
import { ArrowDownWideNarrow } from "lucide-react";
import { Segmented, Tooltip } from "antd";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import { getValue } from "@utils/lodash";

export default function TicketSubHeader({
  subHeaderListTitle,
  isRightArrowVisible,
  subMenuArray,
  children,
  showBack,
  handleNavigateBack,
  addButton,
  toggleSidebar,
  hideView,
  params,
  hideSideBar,
}: any) {
  const navigate = useNavigate();
  const location = useLocation();

  const currentView = location.pathname.includes("kanban") ? "kanban" : "card";
  return (
    <div className={`sub-header-wrapper`}>
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          {showBack && (
            <ul onClick={handleNavigateBack}>
              <li className={`gap d-flex align-items-center cursor-pointer`}>
                <BackSvgComponent />
              </li>
            </ul>
          )}
          <ul>
            <li className={`gap d-flex align-items-center `}>
              {!hideSideBar ? (
                <Tooltip title="Ticket View" placement="bottom">
                  <ArrowDownWideNarrow
                    size={20}
                    color="#1a73e8"
                    className="cursor-pointer"
                    onClick={() => toggleSidebar()}
                  />
                </Tooltip>
              ) : (
                <img src="/images/header/star.svg" className="img-fluid" />
              )}
              <h6 className={`submenu-name ${showBack ? "ms-3" : ""}`}>
                {subHeaderListTitle}
              </h6>

              {isRightArrowVisible && (
                <img
                  src="/images/header/right-arrow.svg"
                  className="img-fluid"
                  alt="right-arrow"
                />
              )}
            </li>
          </ul>

          {subMenuArray?.length >= 0 && (
            <ul className={"header-wrapper__navigation-list"}>
              {subMenuArray.map((subMenuObj: any, index: any) => {
                return (
                  <li
                    className={"sub-header-wrapper__navigation-list-items"}
                    key={`subMenu-${index}`}
                  >
                    <Link
                      to={subMenuObj.linkUrl}
                      className={`sub-header-wrapper__sub-navigation-title ${
                        window.location.pathname === subMenuObj.linkUrl
                          ? `active`
                          : ""
                      }`}
                    >
                      {subMenuObj.linkText}
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}

          {/* <Select
            style={{ width: 300 }}
            placeholder="custom dropdown render"
            dropdownRender={(options) => (
              <>
                {options}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Button type="text" onClick={addItem} className="w-100">
                    Create pipeline
                  </Button>
                </Space>
              </>
            )}
            options={items.map((item) => ({ label: item, value: item }))}
          /> */}
        </div>
        {children}
        {!hideView && (
          <Segmented
            value={currentView}
            options={[
              {
                value: "card",
                icon: (
                  <Tooltip title="Card View" placement="bottom">
                    <BarsOutlined />
                  </Tooltip>
                ),
              },
              {
                value: "kanban",
                icon: (
                  <Tooltip title="Kanban View" placement="bottom">
                    <AppstoreOutlined />
                  </Tooltip>
                ),
              },
            ]}
            onChange={(value: any) => {
              if (value === "card") {
                navigate(`/${getValue(params, "orgId", "")}/tickets`);
              } else {
                navigate(`/${getValue(params, "orgId", "")}/tickets/kanban`);
              }
            }}
          />
        )}
      </div>
      {addButton && addButton}
    </div>
  );
}
