import { Button, Typography, message } from "antd";
import { CopyOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { CopyToClipboard } from "@common/text-helpers";
import CopySvgComponents from "@assets/svg/copy";
import { SquareArrowOutUpRight } from "lucide-react";

const { Title, Text } = Typography;

export default function AIShareURL() {
  const params = useParams();
  const chatbotUrl = `https://chatbot-iframe.onrender.com/?id=${getValue(
    params,
    `chatbotId`,
    ""
  )}`;

  return (
    <div className="share-container">
      <Text className="website-url">www.appzo.ai</Text>

      <p className="small_text__14 pb-2">
        To add the chatbot any where on your website, add this iframe to your
        html code
      </p>

      <div className="url-display">
        <div className="d-flex align-items-center gap-3">
          <pre className="text-center m-0">
            <code className="small_text__14 ">{chatbotUrl}</code>
          </pre>
        </div>
      </div>

      <div className="action-buttons">
        <Button
          icon={<SquareArrowOutUpRight size={16} />}
          onClick={() => window.open(chatbotUrl, "_blank")}
          className="action-button"
        >
          Visit
        </Button>
        <Button
          icon={<CopyOutlined />}
          onClick={() => CopyToClipboard(chatbotUrl)}
          className="action-button"
        >
          Copy
        </Button>
      </div>
    </div>
  );
}
