import { memo, useCallback } from "react";
import { deleteCategoryFolder } from "@services/solutions.service";
import { getValue } from "@utils/lodash";
import { Dropdown } from "antd";
import {
  ChevronRight,
  ChevronUp,
  EllipsisVertical,
  Pencil,
  PlusCircle,
  Trash2,
} from "lucide-react";
import RenderArticles from "../Articles/RenderArticles";

const RenderFolders = ({
  category,
  selectedFolder,
  expandedFolders,
  handleToggleFolder,
  handleCreateArticle,
  handleFolderEdit,
  handleDeleteModal,
  articleRequestCopy,
  selectedArticle,
  setPreviousArticle,
  setNextArticle,
}: any) => {
  const renderFolderMenu = useCallback(
    (categoryId: string, folderId: string, name: any) => [
      {
        key: "new",
        icon: <PlusCircle size={14} />,
        label: "New Article",
        onClick: () => handleCreateArticle(categoryId, folderId),
      },
      {
        key: "edit",
        icon: <Pencil size={14} />,
        label: "Edit",
        onClick: () => handleFolderEdit(folderId),
      },
      {
        key: "delete",
        icon: <Trash2 size={14} />,
        label: "Delete",
        onClick: () =>
          handleDeleteModal("Folder", name, deleteCategoryFolder, folderId),
      },
    ],
    [handleCreateArticle, handleFolderEdit]
  );
  const folders = getValue(category, "folders", []);
  return (
    <div className="folders-container">
      {folders.length === 0 ? (
        <p className="small_text__13 pt-2 text-center">No folders available</p>
      ) : (
        folders.map((folder: any) => {
          const folderId = getValue(folder, "id", "");
          const folderName = getValue(folder, "name", "");
          const isExpanded = expandedFolders[folderId];

          return (
            <div
              key={folderId}
              className={`folder ${selectedFolder ? "selected" : ""}`}
            >
              <div className="solution-folder-card">
                <h6
                  onClick={() => handleToggleFolder(folderId)}
                  className="cursor-pointer d-flex align-items-center gap-2 header_text__14"
                >
                  {folderName}
                  {!isExpanded ? (
                    <ChevronRight size={16} />
                  ) : (
                    <ChevronUp size={16} />
                  )}
                </h6>
                <Dropdown
                  menu={{
                    items: renderFolderMenu(
                      getValue(category, "id", ""),
                      folderId,
                      getValue(folder, "name", "")
                    ),
                  }}
                  className="more-options"
                >
                  <EllipsisVertical size={14} className="cursor-pointer" />
                </Dropdown>
              </div>
              {isExpanded && (
                <RenderArticles
                  folder={folder}
                  category={category}
                  selectedFolder={selectedFolder}
                  articleRequestCopy={articleRequestCopy}
                  handleCreateArticle={handleCreateArticle}
                  selectedArticle={selectedArticle}
                  setPreviousArticle={setPreviousArticle}
                  setNextArticle={setNextArticle}
                />
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

export default memo(RenderFolders);
