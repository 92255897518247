import "./template.scss";
import ModuleSettingNavViews from "../components/nav-views";
import { useCallback, useEffect, useState } from "react";
import {
  getAllTemplates,
  updateTemplates,
} from "@services/email-config.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import ConfigPopup from "./components/config-popup";
import { getValue } from "@utils/lodash";
import { defaultFields } from "@services/modules.service";
import TemplatePopup from "./components/template-popup";
import { addTemplates } from "@services/email-config.service";
import { toast } from "sonner";
import ListLoader from "@components/common/Loader";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import { useStateContext } from "@context/profileProvider";
import TemplatesNoDataPage from "@components/common/NoData/templates-nodata";
import { Badge, Button, Switch, Table } from "antd";
import { formatString } from "@common/text-helpers";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { getAutomationEmailKeys } from "@services/automations.service";
import { EditorState, Modifier } from "draft-js";

function SettingsEmailTemplates(props: any) {
  const { selectedModuleId } = useStateContext();
  const [ticketFields, setTicketFields] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [request, setRequest] = useState({
    name: "",
    subject: "",
    content: "",
    isCommon: true,
    moduleId: "",
    pipelineId: "",
  });
  const [isLoading1, setIsLoading1] = useState(false);
  const [ticketKeys, setTicketsKeys] = useState(false);

  const reset = () => {
    setRequest({
      ...request,
      name: "",
      subject: "",
      content: "",
      isCommon: true,
      moduleId: "",
      pipelineId: "",
    });
  };
  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
    handleGetTicketKeys();
  }, []);

  useEffect(() => {
    if (ticketFields) {
      handleGetTicketKeys();
    }
  }, [ticketFields]);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        module_name: "contacts",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllTemplates("");
      let response = await defaultFields(queryRequest);
      if (resp) {
        setList(getValue(resp, `data`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Template section                             */
  /* -------------------------------------------------------------------------- */
  const [isOpen, setIsOpen] = useState(false);
  const handleModal = () => {
    setIsOpen(!isOpen);
    setTicketFields(false);
  };

  const handleEmailCloseModal = () => {
    setIsOpen(!isOpen);
  };

  const [templateOpen, setTemplateOpen] = useState(false);
  const handleTemplateModal = () => {
    setTemplateOpen(!templateOpen);
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleAddTextAtCursor = (item: any) => {
    const textToInsert = `<%=${item}%>`;
    const updatedContent = `${request.content || ""}${textToInsert}`;

    setRequest((prevRequest) => ({
      ...prevRequest,
      content: updatedContent,
    }));
    setKeyModal(false);
  };

  const handleChangeEditor = useCallback(
    (value: string) => {
      setRequest((prevRequest) => ({
        ...prevRequest,
        content: value,
      }));
    },
    [] // Dependencies
  );

  useEffect(() => {
    setRequest((prevRequest) => {
      if (prevRequest.content !== request.content) {
        return {
          ...prevRequest,
          content: request.content,
        };
      }
      return prevRequest;
    });
  }, [request.content]);

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      let payload = {
        name: getValue(request, `name`, ""),
        content: getValue(request, `content`, ""),
        subject: getValue(request, `subject`, ""),
        isCommon: getValue(request, `isCommon`, false),
        moduleId: getValue(request, `moduleId`, ""),
        pipelineId: getValue(request, `pipelineId`, ""),
      };
      let resp;
      if (editId) {
        resp = await updateTemplates(editId, payload);
      } else {
        resp = await addTemplates(payload);
      }
      if (resp) {
        toast.success(`${editId ? "Updated" : "Created"} successfully`);
        setSubmitLoading(false);
        handleTemplateModal();
        getData();
        reset();
        setTicketFields(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const handleGetTicketKeys = async () => {
    try {
      setIsLoading1(true);
      const resp = await getAutomationEmailKeys();
      if (resp) {
        setTicketsKeys(getValue(resp, "data.keys", []));
        setIsLoading1(false);
      }
    } catch (error) {
      toast.error("Failed to load Keys.");
    } finally {
      setIsLoading1(false);
    }
  };

  const [editId, setEditId] = useState("");
  const handleUpdateTemplate = (obj: object) => {
    setEditId(getValue(obj, `id`, ""));
    setRequest({
      ...request,
      name: getValue(obj, `name`, ""),
      subject: getValue(obj, `subject`, ""),
      content: getValue(obj, `content`, ""),
      isCommon: true,
      moduleId: getValue(obj, `module_id`, ""),
      pipelineId: getValue(obj, `pipeline_id`, ""),
    });
    setTemplateOpen(true);
  };

  const [keyModal, setKeyModal] = useState(false);
  const handleKeyModal = () => {
    setKeyModal(!keyModal);
  };

  const handleChangeStatus = async (obj: object) => {
    try {
      let payload = {
        isActive: !getValue(obj, `is_active`, false),
      };
      let resp = await updateTemplates(getValue(obj, `id`, ""), payload);
      if (resp) {
        toast.success("Updated successfully");
        getData();
      }
    } catch (error) {}
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (name: any, record: any) => (
        <div
          className="cursor-pointer header_blue_text__14"
          onClick={() => handleUpdateTemplate(record)}
        >
          {formatString(name)}
        </div>
      ),
    },
    {
      title: "MODULE",
      dataIndex: "module",
      key: "module",
      render: (text: any) => text.label,
    },
    {
      title: "STATUS",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active: any) => (
        <div className="d-flex align-items-center gap-2">
          <Badge status={is_active === true ? "success" : "error"} />
          {is_active === true ? "Active" : "Inactive"}
        </div>
      ),
    },
    {
      title: "ACTION",
      dataIndex: "is_active",
      key: "is_active",
      render: (is_active: any, record: any) => (
        <div className="d-flex align-items-center gap-2">
          <Switch
            size="small"
            checked={is_active}
            onChange={() => handleChangeStatus(record)}
          />
        </div>
      ),
    },
  ];
  return (
    <CompanyProfileDetailsLayout {...props} isLoading={isLoading}>
      <CompanyProfileSubHeader
        settings
        title="Emails"
        icon="emails"
        addChildren={
          <Button size="large" type="primary" onClick={handleModal}>
            New Template
          </Button>
        }
      />
      <div className={`manageFieldRightSettings add-overflow-y p-4`}>
        <ModuleSettingNavViews handleModal={handleModal} />
        {isLoading ? (
          <ListLoader />
        ) : (
          <div className="m-2">
            {getValue(list, `length`, 0) > 0 ? (
              <Table
                size="middle"
                className="mt-3"
                bordered
                columns={columns}
                dataSource={list}
                rowKey="id"
                pagination={{
                  showTotal: (total, range) =>
                    `${total} ${total >= 2 ? "items" : "item"}`,
                }}
                scroll={{
                  y: `calc(100vh - 300px)`,
                }}
              />
            ) : (
              <TemplatesNoDataPage />
            )}
          </div>
        )}
      </div>
      <ConfigPopup
        isOpen={isOpen}
        handleModal={handleModal}
        request={request}
        setRequest={setRequest}
        handleTemplateModal={handleTemplateModal}
        setTicketFields={setTicketFields}
        handleEmailCloseModal={handleEmailCloseModal}
      />
      <TemplatePopup
        isOpen={templateOpen}
        handleModal={handleTemplateModal}
        module={getValue(request, `forModule`, "")}
        request={request}
        setRequest={setRequest}
        submitLoading={submitLoading}
        handleSubmit={handleSubmit}
        editId={editId}
        reset={reset}
        permissions={getValue(props, "permissions", [])}
        ticketFields={ticketFields}
        ticketKeys={ticketKeys}
        editorState={getValue(request, `content`, "")}
        setEditorState={setEditorState}
        handleChangeEditor={handleChangeEditor}
        handleAddTextAtCursor={handleAddTextAtCursor}
        keyModal={keyModal}
        handleKeyModal={handleKeyModal}
      />
    </CompanyProfileDetailsLayout>
  );
}

export default SettingsEmailTemplates;
