import { getValue } from "@utils/lodash";
import { Button, Tabs, TabsProps } from "antd";
import { Plus, Slack } from "lucide-react";
import AIAgentLayout from "../../Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllAgents } from "@services/agent-actions.service";
import { QueryRequestHelper } from "@common/query-request-helper";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import Nodata from "@components/common/NoData/NoData";
import { concatString, formatString } from "@common/text-helpers";
import ListLoader from "@components/common/Loader";
import ActionsSidebar from "./components/sidebar";
import AIAgentFullLayout from "../../Layout/full-layout";
import Loader from "@components/common/Loader/loading";

const AIAgentActions = (props: any) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const params = useParams();
  const navigate = useNavigate();

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getData();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getData = async () => {
    try {
      setIsLoading(true);
      let payload = {
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        search: getValue(UrlParams, `search`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllAgents(queryRequest);
      if (resp) {
        setIsLoading(false);
        setList(getValue(resp, `data.agentActions`, []));
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const findRoute = (name: string) => {
    switch (name) {
      case "form_action":
        return "form-action";
      case "web_search_action":
        return "web-search-action";
      case "custom_action":
        return "custom-action";
      default:
        return "custom-action";
    }
  };

  const [activeTab, setActiveTab] = useState(1);

  return (
    <AIAgentFullLayout>
      <div className="config-container">
        <ActionsSidebar activeTab={activeTab} setActiveTab={setActiveTab} />
        {activeTab === 1 && (
          <div className="">
            <div className="d-flex justify-content-end">
              <Button
                size="large"
                type="primary"
                onClick={() =>
                  navigate(
                    `/${getValue(params, "orgId", "")}/chatbot/${getValue(
                      params,
                      "chatbotId",
                      ""
                    )}/ai/detail/custom-action/create`
                  )
                }
              >
                Create Custom Action
              </Button>
            </div>

            {isLoading ? (
              <Loader />
            ) : getValue(list, `length`, 0) > 0 ? (
              <div className="actions-card-wrapper">
                {list.map((item: object) => {
                  return (
                    <div className=" rounded-3 p-4  actions-card">
                      {/* <h6 className="header_text__18">Form</h6> */}
                      <div className="mt-2 mb-4">
                        <h6 className="header_text__16">
                          {formatString(getValue(item, `name`, ""))}
                        </h6>
                        <p className="small_text__14 pt-2">
                          {concatString(getValue(item, `instruction`, ""), 50)}
                        </p>
                      </div>
                      <Button
                        size="large"
                        icon={<Plus size={18} />}
                        className="mx-auto h-fit-content"
                        onClick={() =>
                          navigate(
                            `/${getValue(
                              params,
                              `orgId`,
                              ""
                            )}/chatbot/${getValue(
                              params,
                              "chatbotId",
                              ""
                            )}/ai/detail/${findRoute(
                              getValue(item, `type`, "")
                            )}/create?action_id=${getValue(item, `id`, "")}`
                          )
                        }
                      >
                        Create Action
                      </Button>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Nodata />
            )}
          </div>
        )}
        {activeTab === 2 && (
          <div className="actions-card-wrapper pt-2">
            <div className=" rounded-3 p-4 h-fit-content rounded-3 p-4  actions-card">
              <Slack size={30} />
              <div className="mt-3 mb-4">
                <h6 className="header_text__16">Slack</h6>
                <p className="small_text__14">
                  Manage your Slack conversations.
                </p>
              </div>
              <div className="d-flex justify-content-end">
                <Button size="large" className="mx-auto">
                  Connect
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </AIAgentFullLayout>
  );
};

export default AIAgentActions;
