import { handleRadioChange } from "@common/handleChange";
import { getValue } from "@utils/lodash";
import { Radio, Switch } from "antd";

const GroupProperties = (props: any) => {
  const { request, setRequest } = props;

  // const handleAutoTicketAssign = (value: boolean) => {
  //   setRequest((prevRequest: any) => {
  //     if (value) {
  //       return {
  //         ...prevRequest,
  //         enable_automatic_ticket_assignment: value,
  //         assignment_type: "round_robin",
  //       };
  //     } else {
  //       const { assignment_type, ...rest } = prevRequest;
  //       return {
  //         ...rest,
  //         enable_automatic_ticket_assignment: value,
  //       };
  //     }
  //   });
  // };

  const assignementMode = [
    {
      value: "round_robin",
      heading: "Round robin ticket assignment",
      description: "Consider round robin with mapped agents to this group",
    },
    {
      value: "skill",
      heading: "Skill based ticket assignment",
      description: "Assign tickets to agents based on their skills. ",
    },
    {
      value: "disbale",
      heading: "Disabled",
      description: "Disable automatic assignemnt of agent upon ticket creation",
    },
    {
      value: "round_robin_with_agent_availability",
      heading: "Skill based ticket assignment",
      description:
        "Consider round robin + availability to assign to mapped agents",
    },
  ];

  return (
    <div className="w-50 d-grid gap-3">
      <div className="border rounded-3">
        <div className="d-flex gap-4 align-items-center p-4 justify-content-between">
          <div>
            <h6 className="header_text__16">Automatic ticket assignment</h6>
            <p className="small_text__14">
              Distribute and balance the number of tickets assigned to agents in
              this group
            </p>
          </div>
        </div>
        <div className="rounded-bottom-3" style={{ background: "#f5f7f9" }}>
          <div className="p-4">
            <p className="small_text__13">Choose an assignment mode</p>
            <Radio.Group
              className="mt-3"
              value={getValue(request, "assignment_type", "")}
              onChange={(e) =>
                handleRadioChange(e, "assignment_type", request, setRequest)
              }
            >
              <div className="d-grid gap-3">
                {assignementMode.map((radio, index) => (
                  <Radio key={index} value={getValue(radio, "value", "")}>
                    <h6 className="header_text__16">
                      {getValue(radio, "heading", "")}
                    </h6>
                    <p className="small_text__14">
                      {getValue(radio, "description", "")}
                    </p>
                  </Radio>
                ))}
              </div>
            </Radio.Group>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupProperties;
