import { concatString } from "@common/text-helpers";
import NotificationBar from "@components/Pages/Filter/NotificationBar";
import Filter from "@components/Pages/Filter/filter";
import { getValue } from "@utils/lodash";
import NavViews from "../NavViews";

function ModulePipelineSubheader(props: any) {
  const {
    selectedIds,
    openEditPopup,
    setSelectedIds,
    handleOpenFilter,
    handleDeleteTable,
    handleOpenCallRecordings,
    isStatusVisible,
    callsRecording,
    module,
    filterNavigationArray,
    options,
    setIsOpenSubPipeline,
    moduleInfo,
    stageList,
    searchInput,
    showSearchTextbox,
    toggleSearch,
    toggleSearchClose,
    handleChangeSearch,
    getSelectedPipeline,
    isFilterVisibile,
    getListData,
    getAllPipelines,
    hideCloseDate,
    showAdvancedFilter,
    importLogs,
    handleEditFieldsModal,
    handleOpenFilterView,
    handleNaviagteView,
    plural_label,
    fields,
    isSort,
    hideStage,
    tourRef3,
    tourRef4,
    hideNavView,
    showDownload,
    showDueDateFiter,
    hideEditColumns,
    isCreateVisible,
    hideCreateDate
  } = props;
  return (
    <div>
      {!hideNavView && (
        <NavViews
          // plural_label={concatString(
          //   getValue(moduleInfo, `label_singular`, ""),
          //   20
          // )}
          plural_label={plural_label}
          handleNaviagteView={handleNaviagteView}
          permissions={getValue(props, `permissions`, [])}
        />
      )}
      <div className="px-5" ref={tourRef3}>
        <div
          // content
          className={`subheader_filter_section ${
            getValue(selectedIds, `length`, 0) > 0 ? "open" : "closed"
          }`}
        >
          <NotificationBar
            openEditPopup={openEditPopup}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            handleOpenFilter={handleOpenFilter}
            handleDeleteTable={handleDeleteTable}
            module={module}
            callsRecording={callsRecording}
            handleOpenCallRecordings={handleOpenCallRecordings}
            hideStage={hideStage}
            permissions={getValue(props, `permissions`, [])}
          />
        </div>
        <div
          // other-content
          className={` subheader_filter_section ${
            getValue(selectedIds, `length`, 0) === 0 ? "open" : "closed"
          }`}
        >
          <Filter
            isStatusVisible={isStatusVisible}
            filterNavigationArray={filterNavigationArray}
            isFilterVisibile={isFilterVisibile}
            isCreateVisible={isCreateVisible}
            hideCreateDate={hideCreateDate}
            isCreateDealVisible={
              props.isCreateDealVisible
                ? props.isCreateDealVisible
                : getValue(options, `length`, 0) > 0
                ? true
                : false
            }
            setVisisbility={setIsOpenSubPipeline}
            SubPiplineTitle={concatString(
              getValue(moduleInfo, `label_singular`, ""),
              20
            )}
            stageList={stageList}
            //search section
            searchInput={searchInput}
            showSearchTextbox={showSearchTextbox}
            toggleSearch={toggleSearch}
            toggleSearchClose={toggleSearchClose}
            handleChangeSearch={handleChangeSearch}
            hideCloseDate={hideCloseDate}
            module={module}
            selectedPipeline={getSelectedPipeline}
            getListData={getListData}
            getAllPipelines={getAllPipelines}
            showAdvancedFilter={showAdvancedFilter}
            importLogs={importLogs}
            handleEditFieldsModal={handleEditFieldsModal}
            handleOpenFilterView={handleOpenFilterView}
            hideStage={hideStage}
            //sort section
            fields={fields}
            isSort={isSort}
            handleChangeFilter={props.handleChangeFilter}
            showtaskFilter={props.showtaskFilter}
            tourRef4={tourRef4}
            permissions={getValue(props, `permissions`, [])}
            //current view
            setCurrentView={props.setCurrentView}
            showDownload={showDownload}
            showDueDateFiter={showDueDateFiter}
            hideEditColumns={hideEditColumns}
          />
        </div>
      </div>
    </div>
  );
}

export default ModulePipelineSubheader;
