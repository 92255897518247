import qs from "query-string";

export const QueryRequestHelper = (props: any) => {
  let payload = { ...props };
  Object.keys(payload).forEach((key) => {
    if (
      (!payload[key] ||
        payload[key] === undefined ||
        (Array.isArray(payload[key]) && payload[key].length === 0)) &&
      typeof payload[key] !== "boolean"
    ) {
      delete payload[key];
    }
  });
  let queryRequest = qs.stringify(payload, {
    arrayFormat: "bracket",
    encode: false,
  });
  return queryRequest;
};



export const removeBracketsFromQuery = (query:any) => {
  // Split the query into key-value pairs
  const parts = query.split('&');
  
  // Map through the parts, removing '[]' from the keys
  const updatedParts = parts.map((part:any) => {
    const [key, value] = part.split('=');
    const updatedKey = key.replace(/\[\]/g, ''); // Remove the brackets
    return `${updatedKey}=${value}`;
  });

  // Join the updated parts back into a query string
  return updatedParts.join('&');
}