import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { updateSale } from "@services/deals.service";
import { toast } from "sonner";
import PendingLoanComponentContacts from "./contacts";
import { updateContact } from "@services/contacts.service";
import PendingLoanComponentCompanies from "./companies";
import { updateCompany } from "@services/companies.service";
import { FormRequestHelperWithNullData } from "@components/helpers/request-helper";
import TagPopup from "@components/Pages/Pipeline/Detail/components/Tag";
import {
  createTag,
  getAllTags,
  updateModuleTags,
} from "@services/tags.service";
import { useStateContext } from "@context/profileProvider";
import { Badge, Tag } from "antd";
import { updateLead } from "@services/leads.service";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import { formatString } from "@common/text-helpers";
import TaskPendingLayout from "@pages/Private/Tickets/Detail/components/TaskPendingLayout/TaskPendingLayout";

export default function PendingLoanComponent(props: any) {
  const { request, setRequest, alignValue } = props;
  const [clicked, setClicked] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    if (getValue(props, `duplicateAPINames.length`, 0) > 0) {
      toast.error(
        "Submission is not allowed due to the presence of duplicate values."
      );
    } else {
      try {
        let fieldReq = JSON.parse(JSON.stringify(props.popupFields));
        let payload = FormRequestHelperWithNullData(fieldReq);
        let requestPayload: any = { ...payload };
        // Object.keys(requestPayload).forEach((key) => {
        //   if (!requestPayload[key] || requestPayload[key] === undefined) {
        //     delete requestPayload[key];
        //   }
        // });
        setSubmitLoading(true);
        let resp;
        if (
          getValue(props, `module`, "") === "sales" ||
          getValue(props, `module`, "") === "deals"
        ) {
          resp = await updateSale(
            getValue(props, `params.id`, ""),
            requestPayload
          );
        }

        if (getValue(props, `module`, "") === "leads") {
          resp = await updateLead(
            getValue(props, `params.id`, ""),
            requestPayload
          );
        }

        if (getValue(props, `module`, "") === "contacts") {
          resp = await updateContact(
            getValue(props, `params.id`, ""),
            requestPayload
          );
        }
        if (getValue(props, `module`, "") === "companies") {
          resp = await updateCompany(
            getValue(props, `params.id`, ""),
            requestPayload
          );
        }
        if (resp) {
          toast.success("Updated successfully");
          props.getData(false);
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };
  const { modules } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                                 Tags Section                               */
  /* -------------------------------------------------------------------------- */
  const params = useParams();
  const [tags, setTags] = useState([]);
  const [obj, setObj] = useState({
    label: "",
    value: "",
    color_code: "#82E0AA",
  });
  useEffect(() => {
    getAllTagsList();
  }, [props.data]);

  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [allTags, setAllTags] = useState([]);
  const getAllTagsList = async () => {
    try {
      let moduleID =
        getValue(modules, `length`, 0) > 0
          ? modules.filter(
              (item: object) =>
                getValue(item, `name`, "") === getValue(props, `module`, "")
            )
          : "";
      let resp = await getAllTags(getValue(moduleID, `[${0}].id`, ""));
      if (resp) {
        setTags([]);
        let tags = getValue(resp, `data`, []);
        let selectedTags = getValue(props, `data.tags`, []);
        const newArray = tags.filter((item: any) =>
          selectedTags.includes(item.value)
        );
        setTags(
          newArray.map((item: object) => ({
            ...item,
            value: getValue(item, `value`, ""),
            label: getValue(item, `label`, ""),
          }))
        );

        setSelectedTags(selectedTags);
        setAllTags(
          tags.map((item: object) => ({
            ...item,
            value: getValue(item, `value`, ""),
            label: getValue(item, `label`, ""),
          }))
        );
      }
    } catch (error) {}
  };
  const [tagLoading, setTagLoading] = useState(false);
  const handleSubmitTags = async (obj: object) => {
    let moduleID =
      getValue(modules, `length`, 0) > 0
        ? modules.filter(
            (item: object) =>
              getValue(item, `value`, "") === getValue(props, `module`, "")
          )
        : "";
    try {
      setTagLoading(true);
      let resp = await createTag(getValue(moduleID, `[${0}].id`, ""), obj);
      if (resp) {
        let tagsList =
          getValue(tags, `length`, 0) > 0
            ? tags.map((item: object) => getValue(item, `value`, ""))
            : [];
        let payload: any = {
          tags: getValue(obj, `value`, "")
            ? tagsList.concat(getValue(obj, `value`, ""))
            : tagsList,
        };
        handleUpdateTags(payload);
        setTagLoading(false);
      } else {
        setTagLoading(false);
      }
    } catch (error) {
      setTagLoading(false);
    }
  };
  const handleUpdateTags = async (payload: any) => {
    try {
      setSubmitLoading(true);
      let resp = await updateModuleTags(
        getValue(props, `module`, ""),
        getValue(params, `id`, ""),
        {
          tags:
            getValue(payload, `tags.length`, 0) > 0
              ? getValue(payload, `tags`, []).filter(
                  (item: string) => item && item
                )
              : selectedTags,
        }
      );
      if (resp) {
        toast.success("Updated successfully");
        setObj({
          ...obj,
          label: "",
          value: "",
          color_code: "#D5F5E3",
        });
        props.getData(false);
        setSubmitLoading(false);
        setClicked(true);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };
  const handleChangeTags = (item: any) => {
    const itemName = getValue(item, "value", "");
    const isTagSelected = selectedTags.includes(itemName);
    if (isTagSelected) {
      const updatedTags = selectedTags.filter(
        (tag: string) => tag !== itemName
      );
      setSelectedTags([...updatedTags]);
    } else {
      setSelectedTags([...selectedTags, itemName]);
    }
  };
  const handleChangeSelect = (value: any) => {
    let selectedValue: any = getValue(value, `value`, "");
    if (selectedTags.includes(selectedValue)) {
      let filtered = selectedTags.filter((item: any) => item !== selectedValue);
      setSelectedTags(filtered);
    } else {
      selectedTags.push(selectedValue);
      setSelectedTags([...selectedTags]);
    }
  };
  const handleAddTags = async (obj: object) => {
    let moduleID =
      getValue(modules, `length`, 0) > 0
        ? modules.filter(
            (item: object) =>
              getValue(item, `name`, "") === getValue(props, `module`, "")
          )
        : "";
    try {
      let resp = await createTag(getValue(moduleID, `[${0}].id`, ""), obj);
      if (resp) {
        getTagsAfterAddedToList();
        handleChangeTags(obj);
        handleChangeSelect(obj);
        getSubmitTags(selectedTags);
      }
    } catch (error) {}
  };
  const getTagsAfterAddedToList = async () => {
    try {
      let moduleID =
        getValue(modules, `length`, 0) > 0
          ? modules.filter(
              (item: object) =>
                getValue(item, `name`, "") === getValue(props, `module`, "")
            )
          : "";
      let resp = await getAllTags(getValue(moduleID, `[${0}].id`, ""));
      if (resp) {
        let tags = getValue(resp, `data`, []);
        let selectedTags = getValue(props, `data.tags`, []);
        setSelectedTags(selectedTags);
        setAllTags(
          tags.map((item: object) => ({
            ...item,
            value: getValue(item, `value`, ""),
            label: getValue(item, `label`, ""),
          }))
        );
      }
    } catch (error) {}
  };

  const getSubmitTags = async (tags: any) => {
    try {
      let resp = await updateModuleTags(
        getValue(props, `module`, ""),
        getValue(params, `id`, ""),
        {
          tags: tags,
        }
      );
      if (resp) {
        props.getData(false);
        setIsActive(false);
        setSubmitLoading(false);
        setClicked(true);
        toast.success("Updated successfully");
      }
    } catch (error) {}
  };

  const [isActive, setIsActive] = useState(false);
  // handleConvertContact
  return (
    <>
      <TaskPendingLayout>
        <div className={"ticket-wrapper__main-left-section"}>
          <div className={"pending-wrapper__pending-task-details"}>
            <div className={`pending-wrapper__main-section-form`}>
              {alignValue === "details" && (
                <>
                  <div>
                    <div className="d-flex align-items-center justify-content-between gap-3 mb-2">
                      <h6 className="header_text__18 ">
                        {getValue(props, "data.resolution", "")
                          ? formatString(getValue(props, "data.resolution", ""))
                          : "Open"}
                      </h6>
                      <Tag color="blue">
                        {formatString(getValue(props, "data.status", ""))}
                      </Tag>
                    </div>
                    <div>
                      <div className="small_text__14 d-flex align-items-center gap-2">
                        <Badge status="success" />
                        First Response Due
                      </div>
                      <h6 className="header_text__14">
                        by{" "}
                        {convertCurrentDateWithTime(
                          getValue(props, "data.due_by", "")
                        )}
                      </h6>
                    </div>
                    <div className="mt-3">
                      <div className="small_text__14 d-flex align-items-center gap-2">
                        <Badge status="success" />
                        Resolution Due
                      </div>
                      <h6 className="header_text__14">
                        by{" "}
                        {convertCurrentDateWithTime(
                          getValue(props, "data.fr_due_by", "")
                        )}
                      </h6>
                    </div>
                  </div>
                  <div>
                    <TagPopup
                      obj={obj}
                      setObj={setObj}
                      tagLoading={tagLoading}
                      submitLoading={submitLoading}
                      clicked={clicked}
                      setClicked={setClicked}
                      selectedTags={selectedTags}
                      handleChangeTags={handleChangeTags}
                      handleSubmitTags={handleSubmitTags}
                      handleUpdateTags={handleUpdateTags}
                      handleAddTags={handleAddTags}
                      //tags
                      values={selectedTags}
                      handleSelectChange={handleChangeSelect}
                      tags={allTags}
                      setAllTags={setAllTags}
                      tagsList={tags}
                      permissions={getValue(props, `permissions`, [])}
                      setIsActive={setIsActive}
                      isActive={isActive}
                    />
                  </div>
                </>
              )}
              {(getValue(props, `module`, "") === "contacts" ||
                getValue(props, `module`, "") === "leads") && (
                <PendingLoanComponentContacts
                  request={request}
                  setRequest={setRequest}
                  submitLoading={submitLoading}
                  handleSubmit={handleSubmit}
                  popupFields={props.popupFields}
                  setPopupFields={props.setPopupFields}
                  data={props.data}
                  permissions={getValue(props, `permissions`, [])}
                  // module={props.module}
                  duplicateAPINames={props.duplicateAPINames}
                  setDuplicateAPINames={props.setDuplicateAPINames}
                />
              )}
              {getValue(props, `module`, "") === "companies" && (
                <PendingLoanComponentCompanies
                  request={request}
                  setRequest={setRequest}
                  submitLoading={submitLoading}
                  handleSubmit={handleSubmit}
                  popupFields={props.popupFields}
                  setPopupFields={props.setPopupFields}
                  data={props.data}
                  permissions={getValue(props, `permissions`, [])}
                  // module={props.module}
                  duplicateAPINames={props.duplicateAPINames}
                  setDuplicateAPINames={props.setDuplicateAPINames}
                />
              )}
            </div>
          </div>
        </div>
      </TaskPendingLayout>
    </>
  );
}
