import React from "react";
import { getValue } from "@utils/lodash";
import SlideFromRightPopup from "@components/Dialogs/SlideFromRightPopup/SlideFromRightPopup";
import DescriptionInfo from "../../common/DescInfo";

function TaskDeatilContactList(props: any) {
  const { isRollsListPopupVisibile, setRollsListPopupVisisbility } = props;
  return (
    <SlideFromRightPopup
      isPopupVisible={isRollsListPopupVisibile}
      addCustomClass={"rolls-popup"}
      toggle={() => {
        setRollsListPopupVisisbility(false);
      }}
      title="Contacts"
      width={"570px"}
    >
      <div className={"task_more-list"}>
        {getValue(props, `contacts`, []).map((item: object, index: number) => {
          return (
            <div key={index}>
              <DescriptionInfo
                item={item}
                index={index}
                handleDeleteView={props.handleDeleteView}
                module={"contacts"}
              />
            </div>
          );
        })}
      </div>
    </SlideFromRightPopup>
  );
}

export default TaskDeatilContactList;
