"use client";

import { useState } from "react";
import { RefreshCcw, Send, MessageSquarePlus } from "lucide-react";
import "./chat-view.scss";
import { getValue } from "@utils/lodash";
import Logo from "@components/common/Logo/Logo";

interface Message {
  id: string;
  content: string;
  sender: "bot" | "user";
}

export default function ChatView(props: any) {
  const { request, setRequest } = props;
  const [messages, setMessages] = useState<Message[]>([
    {
      id: "1",
      content: "Hi",
      sender: "user",
    },
  ]);
  const [inputMessage, setInputMessage] = useState("");

  const handleSendMessage = (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    setMessages([
      ...messages,
      {
        id: Date.now().toString(),
        content: inputMessage,
        sender: "user",
      },
    ]);
    setInputMessage("");
  };
  console.log(
    typeof getValue(request, `initial_messages`, ""),
    getValue(request, `initial_messages`, ""),
    getValue(request, `initial_messages`, "").split("\n")
  );
  return (
    <div className="chat-wrapper">
      <div className="chat-container">
        <header className="chat-header">
          <h1>{getValue(request, `name`, "")}</h1>
          <button className="refresh-button">
            <RefreshCcw size={20} />
          </button>
        </header>

        <div className="messages-container">
          <>
            {
              // typeof getValue(request, `initial_messages`, "") === "string"?
              getValue(request, `initial_messages`, "")
                .split("\n")
                // : getValue(request, `initial_messages`, [])
                .map((message: any, index: number) => (
                  <div key={index} className={`message bot`}>
                    {message}
                  </div>
                ))
            }
            {messages.map((message) => (
              <div key={message.id} className={`message user`}>
                {message.content}
              </div>
            ))}
          </>
        </div>
        <div className="d-flex flex-wrap gap-2 mb-2">
          {
            // typeof getValue(request, `suggested_messages`, "") === "string"?
            getValue(request, `suggested_messages`, "")
              .split("\n")
              // : getValue(request, `suggested_messages`, [])
              .map((message: any, index: number) => (
                <div
                  key={index}
                  className={`message border border-radius__5 p-2`}
                >
                  {message}
                </div>
              ))
          }
        </div>
        <form onSubmit={handleSendMessage} className="input-container">
          <input
            type="text"
            placeholder={
              getValue(request, `message_placeholder`, "")
                ? getValue(request, `message_placeholder`, "")
                : "Message..."
            }
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
          />
          <button type="submit">
            <Send size={20} />
          </button>
        </form>

        <footer className="chat-footer">
          <img
            // src="/images/rapid-sales-logo.svg"
            src="/appzo-logo.svg"
            // className=" cursor-pointer object-fit-contain py-1 "
            alt="appzo-logo"
            width={40}
            height={40}
          />
          Powered By Appzo{" "}
          {getValue(request, `footer`, "")
            ? `| ${getValue(request, `footer`, "")}`
            : ""}
        </footer>
      </div>

      <button className="widget-button">
        <MessageSquarePlus size={24} />
      </button>
    </div>
  );
}
