import { handleTextChange } from "@common/handleChange";
import HomeHeader from "@components/common/Header/HomeHeader/Header";
import InputRuleForm from "@components/InputRuleForm/form";
import PipelineSubHeader from "@components/Pages/Pipeline/SubHeader";
import { getValue } from "@utils/lodash";
import { Button, Modal, Radio, Select, Space } from "antd";
import { Plus } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./ai-chatbot.scss";

const AiPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [request, setRequest] = useState({
    name: "",
    type: "agents",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    navigate(
      `/${getValue(params, "orgId", "")}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/playground`
    );
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleRadioChange = (e: any) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      type: e.target.value,
    }));
  };

  return (
    <div>
      <HomeHeader />
      <PipelineSubHeader
        subHeaderListTitle={`AI Chatbot`}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        // subMenuArray={subMenuArray}
        addButton={
          <Button
            icon={<Plus />}
            onClick={showModal}
            size="large"
            type="primary"
            className="justify-content-end"
          >
            New Chatbot
          </Button>
        }
      />
      <Modal
        title="Create AI Agent or Chatbot"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="d-flex flex-column gap-3">
          <InputRuleForm
            inputType="TEXT"
            name="name"
            value={getValue(request, "name", "")}
            placeholder="Enter a Name"
            required
            label="Name"
            onChange={(e: any) => handleTextChange(e, request, setRequest)}
            validLeft
          />
          <div>
            <label className="form-label">What do you want to Create?</label>
            <Radio.Group onChange={handleRadioChange} value={request.type}>
              <Space direction="vertical">
                <Radio value="agents">
                  AI Agents
                  <p className="small_text__14">
                    Create a custom AI agent tailored and trained with your
                    unique content using RAG Solution
                  </p>
                </Radio>
                <Radio value="chatbot">
                  Chatbots
                  <p className="small_text__14">
                    Manually setup flows to guide your bot's actions and
                    responses to customer queries
                  </p>
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          {request.type === "chatbot" && (
            <div className="d-grid mb-4">
              <label className="form-label">Channel</label>
              <Select
                size="large"
                defaultValue="jack"
                options={[
                  { value: "jack", label: "Web" },
                  { value: "lucy", label: "Whatsapp" },
                ]}
              />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AiPage;
