import { useEffect, useMemo, useRef, useState } from "react";
import "./index.scss";
import { getValue } from "@utils/lodash";
import CloseMainSvgComponent from "@assets/svg/close-main";
import { QueryRequestHelper } from "@common/query-request-helper";
import { getLookupAPIs } from "@components/helpers/lookup-apis";

const MultiSearchLookupTooltip = (props) => {
  const { label, id, onChange, value, name } = props;

  const inputRef = useRef(null);
  const ref = useRef();

  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [inputChange, setInputChange] = useState(false);
  const colorCodes = [
    "#82E0AA",
    "#fea7a7",
    "#fdd399",
    "#fff1a3",
    "#a8e8ad",
    "#b1dbfd",
    "#d4c9fd",
    "#beeae8",
    "#e9bfed",
    "#bbd2ef",
    "#d0d9e2",
  ];
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    setQuery("");
  }, [props.resetInput]);

  useEffect(() => {
    if (query) {
      setInputChange(true);
    }
  }, [query]);

  useEffect(() => {
    if (getValue(props, `lookup_api`, "")) {
      fetchDefaultData();
    }
  }, [getValue(props, `lookup_api`, "")]);
  /* -------------------------------------------------------------------------- */
  /*                                    API Section                             */
  /* -------------------------------------------------------------------------- */

  const [dataInfo, setData] = useState([]);
  const [isLoadingInfo, setIsLoading] = useState(true);
  const fetchDefaultData = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 10,
      };
      setIsLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getLookupAPIs(
        getValue(props, `lookup_api`, ""),
        queryRequest
      );
      if (resp) {
        console.log(resp)
        setData(resp);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleFetchData = async (search_text) => {
    if (search_text)
      try {
        let payload = {
          search: search_text,
          page_no: 1,
          page_size: 10,
        };
        setIsLoading(true);
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getLookupAPIs(
          getValue(props, `lookup_api`, ""),
          queryRequest
        );
        if (resp) {
          setData(resp);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    else onChange(null);
  };
  /* -------------------------------------------------------------------------- */
  /*                      Hadleclick Outside Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const toggleBox = (e) => {
    setIsOpen(!isOpen);
  };
  const handleClickOption = (option) => {
    onChange && onChange(option);
    setInputChange(false);
    setQuery("");
  };
  const handleRemoveOption = (option) => {
    onChange(option);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    onChange(null);
  };
  const filter = (options) => {
    if (!Array.isArray(options) || options.length === 0) {
      return [];
    }

    const sanitizedValue = Array.isArray(value)
      ? value.filter((v) => v !== null && v !== undefined)
      : [];

    const filtered = options.filter(
      (item) => !sanitizedValue.includes(getValue(item, `name`, ""))
    );

    if (filtered.length === 0) {
      return [];
    }

    return filtered.filter((option) => {
      const optionLabel = getValue(option, `[${label}]`, "");
      return (
        optionLabel &&
        typeof optionLabel === "string" &&
        optionLabel.toLowerCase().includes(query.toLowerCase())
      );
    });
  };

  const findSelectedPipeline = (id) => {
    let list =
      getValue(dataInfo, `length`, 0) > 0
        ? dataInfo.filter((item) => getValue(item, `id`, "") === id)
        : [];
    return getValue(list, `length`, 0) > 0
      ? getValue(list, `[${0}].label`, "")
      : "";
  };
  const valueLabel = useMemo(() => findSelectedPipeline(value), [
    value,
    getValue(dataInfo, `length`, 0) > 0,
  ]);

  const valuesSet = new Set(value);
  const selectedData = useMemo(() => {
    return dataInfo.filter((item) => valuesSet.has(getValue(item, "id", "")));
  }, [dataInfo, valuesSet, value, query]);
  return (
    <>
      {name && (
        <label className="form-label">
          {name}{" "}
          {/* {(getValue(props, `required`, false) ||
            getValue(props, `hasRequired`, false)) && (
            <span className="color_danger small_text__16">*</span>
          )} */}
        </label>
      )}

      <div className={"tag-dropdownselect-form"} ref={ref}>
        <div
          className={`tag-multidropdown-box_main position-relative ${
            isOpen ? "active" : ""
          }`}
          onClick={toggleBox}
          style={{
            width: props.width,
            borderLeft:
              getValue(props, `required`, false) ||
              getValue(props, `hasRequired`, false)
                ? "2px solid red"
                : "",
          }} /* add custom width here */
        >
          <div className="tag-search_container">
            {selectedData.map((item, index) => {
              if (item)
                return (
                  <div
                    className="d-flex align-items-center tag_container px-3 border bg-white"
                    key={index}
                    style={{ background: getValue(item, `color_code`, "") }}
                  >
                    <p className="tag-text">{getValue(item, `label`, "")}</p>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveOption(item);
                      }}
                      className="tag-item-close"
                    >
                      <CloseMainSvgComponent size={17} color={"red"} />
                    </div>
                  </div>
                );
            })}
            <input
              className="tag-search_input"
              ref={inputRef}
              type="text"
              value={
                inputChange
                  ? query
                    ? query
                    : valueLabel
                  : valueLabel
                  ? valueLabel
                  : query
              }
              name="searchTerm"
              autoComplete="off"
              placeholder={getValue(
                props,
                `placeholder`,
                "Please Add/Search here..."
              )}
              onChange={(e) => {
                !e.target.value && onChange(null);
                setQuery(e.target.value);
                handleFetchData(e.target.value);
                e.target.value.length === 1 && setIsOpen(true);
              }}
            />
          </div>
          <div className="right_container">
            {valueLabel && (
              <div className="close_image_container">
                <img
                  src={"/dropdown/close.svg"}
                  className="close_image"
                  onClick={(e) => handleClose(e)}
                />
              </div>
            )}
          </div>
        </div>
        <div
          className={`tag-options ${isOpen ? "open" : ""}`}
          style={{ width: props.width }} /* add custom width here */
        >
          <div>
            <div className="tag-option_container pb-3">
              {filter(dataInfo).length > 0 &&
              getValue(value, `length`, 0) !== filter(dataInfo).length ? (
                filter(dataInfo).map((option, index) => {
                  if (
                    getValue(value, `length`, 0) > 0 &&
                    !value.includes(getValue(option, `id`, ""))
                  ) {
                    return (
                      <div
                        onClick={() => {
                          handleClickOption(option);
                          // setIsOpen(false);
                        }}
                        className={`tag_option ${
                          option.id === value ? "selected" : ""
                        }`}
                        key={`${id}-${index}`}
                      >
                        <div
                          className="selected_tag_text px-3 p-1"
                          style={{
                            background: getValue(option, `color_code`, ""),
                          }}
                        >
                          {option[label]}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        onClick={() => {
                          handleClickOption(option);
                          // setIsOpen(false);
                        }}
                        className={`tag_option ${
                          option.id === value ? "selected" : ""
                        }`}
                        key={`${id}-${index}`}
                      >
                        <div
                          className="selected_tag_text px-3 p-1"
                          style={{
                            background: getValue(option, `color_code`, ""),
                          }}
                        >
                          {option[label]}
                        </div>
                      </div>
                    );
                  }
                })
              ) : (
                <p className="small_text__14 text-center">No Data Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {props.validator && (
        <p className={"error-text"}>
          {props.validator.current.message(
            getValue(props, `name`, ""),
            props.value,
            "required"
          )}
        </p>
      )}
    </>
  );
};

export default MultiSearchLookupTooltip;
