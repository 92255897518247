import "./Tickets.scss";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "common/query-request-helper";
import _ from "lodash";
import { toast } from "sonner";
import SimpleReactValidator from "simple-react-validator";
import {
  createTicket,
  getSpecificTicket,
  updateTicket,
} from "@services/tickets.service";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import { useStateContext } from "context/profileProvider";
import {
  FormRequestHelper,
  FormRequestHelperWithNullFields,
  appendObjectValuesToArray,
} from "@components/helpers/request-helper";
import ButtonComponent from "@components/Form/Button/Button";
import { motion } from "framer-motion";
import { getAllModuleFields } from "@services/module-fields.service";
import ListLoader from "@components/common/Loader";
import CommonTicketDetailPage from "./detailPage";
import { commonCustomFilter } from "@services/smart-filter.service";
import { removeNullOrUndefinedProperties } from "@common/text-helpers";
import { getModuleFieldsById, listAllModules } from "@services/modules.service";
import { sortTaskFieldList } from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import { allPipelines, getSpecificPipeline } from "@services/pipeline.service";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPipelineDetailsProps {
  pipelineId: string;
}

const TicketDetailsPage: React.FC<IPipelineDetailsProps> = (props) => {
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const { selectedModuleId, allModules } = useStateContext();

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  React.useEffect(() => {
    getData(true);
    getListData(true);
  }, []);

  React.useEffect(() => {
    getData(true);
  }, [params]);

  const urlParamsFun = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());
    let request = QueryRequestHelper({ ...urlParams });
    return request;
  };

  const mainQueryRequest = React.useMemo(() => urlParamsFun(), [
    window.location.href,
  ]);

  /* -------------------------------------------------------------------------- */
  /*                                  Left Section                              */
  /* -------------------------------------------------------------------------- */

  /* -------------------------------- Get Ticket List ------------------------- */

  const [listLoading, setListLoading] = React.useState(true);
  const [ticketList, setList] = useState([]);
  const getListData = async (status: boolean) => {
    try {
      let payload = {
        page_size: 100,
        page_no: 1,
        module_id: await selectedModuleId("tickets"),
        filter_owner_id: getValue(UrlParams, `filter_owner_id`, ""),
        filter_pipeline_stage_id: getValue(
          UrlParams,
          `filter_pipeline_stage_id`,
          ""
        ),

        filter_closing_date: getValue(UrlParams, `filter_closing_date`, ""),
        filter_created_at: getValue(UrlParams, `filter_created_at`, ""),
        filter_is_completed: getValue(UrlParams, `filter_is_completed`, "")
          ? getValue(UrlParams, `filter_is_completed`, "") === "active"
            ? "true"
            : "false"
          : "false",
      };
      let queryRequest = QueryRequestHelper(payload);

      let obj = {
        has_advance_filter: getValue(localStorage, `sf:tickets`, "")
          ? true
          : false,
        has_group_by: false,
        filter_groups: getValue(localStorage, `sf:tickets`, "")
          ? JSON.parse(getValue(localStorage, `sf:tickets`, ""))
          : null,
        sort_by: getValue(localStorage, `s:tickets`, "")
          ? {
              ...JSON.parse(getValue(localStorage, `s:tickets`, "")),
              custom: false,
            }
          : {
              custom: false,
              field: "created_at",
              value: "desc",
            },
      };
      setListLoading(status);
      // let resp = await getAllTasks(queryRequest);
      const resp = await commonCustomFilter(
        "tickets",
        queryRequest,
        removeNullOrUndefinedProperties(obj)
      );
      if (resp) {
        console.log(getValue(resp, `data.data.tickets`, []));
        setList(getValue(resp, `data.data.tickets`, []));
        //   setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setListLoading(false);
      } else {
        setList([]);
        setListLoading(false);
      }
    } catch (error) {
      setList([]);
      setListLoading(false);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                                  Middle Section                            */
  /* -------------------------------------------------------------------------- */
  const [options, setOptions] = React.useState([]);
  const [data, setData] = React.useState<any>({});
  const [isTaskLoading, setIsTaskLoading] = React.useState(true);
  const [selectedTicket, setSelectedTicket] = React.useState("");
  const getData = async (status: boolean) => {
    try {
      setIsTaskLoading(status);
      setSelectedTicket(getValue(params, `id`, ""));
      let resp = await getSpecificTicket(getValue(params, `id`, ""));
      if (resp) {
        setData(getValue(resp, `data`, {}));
        getForm(getValue(resp, `data`, {}));
        setIsTaskLoading(false);
      } else {
        setIsTaskLoading(false);
      }
    } catch (error) {
      setIsTaskLoading(false);
    }
  };

  const [dirtyForm, setDirtyForm] = useState([]);
  const [form, setForm] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getForm = async (obj: object) => {
    try {
      let resp = await listAllModules("");
      if (resp) {
        let task_modules = getValue(resp, `data`, []).find(
          (item: object) => getValue(item, `api_name`, "") === "tickets"
        );
        let fields = await getModuleFieldsById(
          getValue(task_modules, `id`, "")
        );
        if (fields) {
          let list = sortTaskFieldList(getValue(fields, `data`, []));
          // for (const item of list) {
          //   if (item.api_name === "owner_id") {
          //     item.value = userId;
          //   }
          // }
          const aDict = list.reduce((dict: any, item: any) => {
            dict[item.name] = item;
            return dict;
          }, {});
          const ReorderedList: any = [];
          // Add elements from b in the order they appear in a
          for (const name of getValue(fields, `data`, [])) {
            if (aDict[name]) {
              ReorderedList.push(aDict[name]);
            }
          }
          // Add elements from a that are not in b to the end
          for (const item of list) {
            if (!getValue(fields, `data`, []).includes(item.name)) {
              ReorderedList.push(item);
            }
          }
          let array: any = appendObjectValuesToArray(ReorderedList, obj);
          setForm(array);
          setDirtyForm(array);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Left Section                                 */
  /* -------------------------------------------------------------------------- */

  const [popupFields, setPopupFields] = React.useState([]);
  const [dirtyPopupFields, setDirtyPopupFields] = React.useState([]);

  let checkfieldRequestChanges = _.isEqual(form, dirtyForm);
  const { userId } = useStateContext();
  /* -------------------------------------------------------------------------- */
  /*                               Middle Section                               */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [, forceUpdate] = React.useState(0);
  const [stage, setStage] = React.useState<any>([]);
  const [formLoading, setFormLoading] = React.useState(false);
  const [formSubmitLoading, setFormSubmitLoading] = React.useState(false);
  const [associationForm, setAssociationForm] = React.useState([]);

  const getAssociationForm = async (module: string) => {
    setAssociationForm([]);
    if (module !== "tickets" && module !== "meetings" && module !== "calls") {
      try {
        let payload = {
          module_name: module,
        };
        let queryRequest = QueryRequestHelper(payload);
        setFormLoading(true);
        let findId =
          getValue(allModules, `length`, 0) > 0
            ? allModules.find(
                (item: object) =>
                  getValue(item, `api_name`, "") ===
                  (module === "sales" ? "deals" : module)
              )
            : [];
        let resp = await allPipelines(getValue(findId, `id`, ""), queryRequest);
        if (resp) {
          getAssociationPipelineInfo(
            getValue(resp, `data[${0}].id`, ""),
            module
          );
          setFormStageList(
            getValue(resp, `data[${0}].stages`, []).map((item: object) => ({
              ...item,
              label: getValue(item, `label`, ""),
              value: getValue(item, `api_name`, ""),
            }))
          );
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {
        setFormLoading(false);
      }
    } else {
      try {
        let code =
          getValue(allModules, `length`, 0) > 0
            ? allModules.find(
                (item: object) => getValue(item, `api_name`, "") == module
              )
            : [];
        let resp = await getAllModuleFields(getValue(code, `id`, ""));
        if (resp) {
          let fields =
            getValue(resp, `data.length`, 0) > 0
              ? getValue(resp, `data`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `module_field.api_name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setAssociationForm(fields);
        }
      } catch (error) {}
    }
  };
  /* ---------------------------  Pipeline By ID  -------------------------------- */
  const [staticFieldRequest, setStaticFieldRequest] = React.useState({
    pipeline_id: "",
    stage: "",
  });
  const [formStageList, setFormStageList] = React.useState<any>([]);
  const getAssociationPipelineInfo = async (id: string, module: string) => {
    if (id) {
      try {
        let resp = await getSpecificPipeline(await selectedModuleId(), id);
        if (resp) {
          let fields =
            getValue(resp, `data.form_fields.length`, 0) > 0
              ? getValue(resp, `data.form_fields`, []).map((item: object) => ({
                  ...getValue(item, `module_field`, {}),
                  name: getValue(item, `name`, ""),
                  quick_add: getValue(item, `quick_add`, ""),
                  required: getValue(item, `required`, ""),
                  seq_num: getValue(item, `seq_num`, ""),
                }))
              : [];
          for (const item of fields) {
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          setStaticFieldRequest((prevStaticFieldRequest) => ({
            ...prevStaticFieldRequest,
            stage: getValue(resp, `data.stages[${0}].id`, ""),
            pipeline_id: id,
          }));
          setAssociationForm(fields);
          setFormLoading(false);
        } else {
          setFormLoading(false);
        }
      } catch (error) {}
    } else {
      setForm([]);
    }
  };

  const handleCreateForm = async (module: string) => {
    if (module) {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
      } else {
        let fields = JSON.parse(JSON.stringify(form));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        requestPayload["stage"] = getValue(stage, `[${0}].value`, "");
        requestPayload["sale_id"] = getValue(params, `id`, "");
        try {
          setFormSubmitLoading(true);
          let resp;
          if (module === "tickets") {
            resp = await createTicket(requestPayload);
          }
          if (resp) {
            toast.success("Created & Associated Successfully");
            setFormSubmitLoading(false);
            getData(false);
            simpleValidator.current.hideMessages();
            forceUpdate(0);
          } else {
            setFormSubmitLoading(false);
          }
        } catch (error) {
          setFormSubmitLoading(false);
        }
      }
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Footer Section                               */
  /* -------------------------------------------------------------------------- */
  const handleReset = async () => {
    setForm(dirtyForm);
  };
  const handleCancel = () => {
    handleReset();
  };
  const [submitLoading, setSubmitLoading] = React.useState(false);
  const handleSubmit = async () => {
    try {
      let fieldReq = JSON.parse(JSON.stringify(form));
      let payloadNew = FormRequestHelperWithNullFields(fieldReq);
      let requestPayload: any = { ...payloadNew };
      setSubmitLoading(true);
      let resp = await updateTicket(getValue(params, `id`, ""), requestPayload);
      if (resp) {
        toast.success("Updated successfully");
        getData(false);
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const handleCloseStatus = async () => {
    try {
      let fieldReq = JSON.parse(JSON.stringify(form));
      let payloadNew = FormRequestHelperWithNullFields(fieldReq);
      let requestPayload: any = { ...payloadNew, status: "closed" };
      setSubmitLoading(true);
      let resp = await updateTicket(getValue(params, `id`, ""), requestPayload);
      if (resp) {
        toast.success("Ticket closed successfully");
        getData(false);
        setSubmitLoading(false);
      } else {
        setSubmitLoading(false);
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const [duplicateAPINames, setDuplicateAPINames] = React.useState<any>([]);

  /* -------------------------------------------------------------------------- */
  /*                                 Module Info                                */
  /* -------------------------------------------------------------------------- */
  const findModuleType = (module: string) => {
    let info =
      getValue(allModules, `length`, 0) > 0
        ? allModules.find(
            (item: object) => getValue(item, `api_name`, "") === module
          )
        : [];
    return info;
  };

  const moduleInfo = React.useMemo(() => findModuleType("tickets"), [
    getValue(allModules, `length`, 0) > 0,
  ]);


  

  const subMenuArray: any = [];
  return (
    <MainLayout>
      {isLoading ? (
        <ListLoader />
      ) : (
        <>
          <CommonTicketDetailPage
            data={data}
            params={params}
            backUrl={`/${getValue(
              params,
              `orgId`,
              ""
            )}/tickets?${mainQueryRequest}`}
            isChanged={!checkfieldRequestChanges}
            fields={form}
            setFields={setForm}
            form={associationForm}
            setForm={setAssociationForm}
            popupFields={popupFields}
            setPopupFields={setPopupFields}
            getData={getData}
            ticketList={ticketList}
            selectedTicket={selectedTicket}
            handleCloseStatus={handleCloseStatus}
            // ------------ Right Section Props ----------- //
            formLoading={formLoading}
            formSubmitLoading={formSubmitLoading}
            handleCreateForm={handleCreateForm}
            simpleValidator={simpleValidator}
            getForm={getAssociationForm}
            module={"tickets"}
            //static dropddowns
            duplicateAPINames={duplicateAPINames}
            setDuplicateAPINames={setDuplicateAPINames}
            permissions={getValue(props, `permissions`, [])}
            isLoading={isLoading}
            stageList={formStageList}
            //Module info
            moduleInfo={moduleInfo}
            UrlParams={UrlParams}
            subMenuArray={subMenuArray}
            options={options}
            staticFieldRequest={staticFieldRequest}
            setStaticFieldRequest={setStaticFieldRequest}
            getListData={getListData}
          />
        </>
      )}
      {!checkfieldRequestChanges && (
        <motion.div
          initial={{ y: 0, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
          exit={{
            y: 50,
            opacity: 0,
            transition: { duration: 0.5, ease: "easeInOut" },
          }}
        >
          <div className={"footer_container"}>
            <div className={"button_container"}>
              <div className="d-flex align-items-center">
                <ButtonComponent
                  buttonType={"scecondary"}
                  buttonText={"Cancel"}
                  onClickHandler={() => handleCancel()}
                  // squre
                />
                {getValue(props, `permissions`, []).includes("update") && (
                  <ButtonComponent
                    buttonType={"primary"}
                    buttonText={submitLoading ? "Please wait..." : "Update"}
                    onClickHandler={() => handleSubmit()}
                    disabled={submitLoading || duplicateAPINames.length > 0}
                    // squre
                  />
                )}
              </div>
            </div>
            <p className={"text"}>You have unsaved changes</p>
          </div>
        </motion.div>
      )}
    </MainLayout>
  );
};
export default TicketDetailsPage;
