import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import { useEffect, useRef, useState } from "react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { Alert, Button, Modal, Select, Tabs, TabsProps } from "antd";
import { toast } from "sonner";
import {
  createSkill,
  deleteSkillAgents,
  getSkillAgents,
  getSkillsConditionFields,
  getSpecificSkill,
  getSpecificSkillAgents,
  updateSkill,
  updateSkillAgents,
} from "@services/skills.service";
import SkillsGeneralSettings from "./components/SkillsGeneralSettings";
import { capitalizeFirstLetter } from "@common/text-helpers";
import GroupsAgents from "../../Groups/EditGroupSettings/components/common/GroupsAgents";
import { DeleteOutlined } from "@ant-design/icons";

const SettingSkillDetails = ({ props }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [editId, seteditId] = useState("");
  const [currentTab, setCurrentTab] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [isLoading4, setIsLoading4] = useState(false);

  const [request, setRequest] = useState({
    name: "",
    description: "",
    match_type: "any",
    skill_conditions: [
      {
        field_name: "group_id",
        operator: "in",
        value: [],
      },
    ],
  });
  const [conditionList, setConditionList] = useState([]);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  //Agents
  const [skillDetails, setSkillDetails] = useState([]);
  const [agents, setAgents] = useState([]);
  const [agentsRequest, setAgentsRequest] = useState({
    agents: [],
  });
  const [selectedAgentIds, setSelectedAgentIds] = useState([]);

  //Modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (getValue(params, "id", "")) {
      seteditId(getValue(params, "id", ""));
    }
  }, [params]);

  useEffect(() => {
    if (editId) {
      handleGetSkillDetails(true);
      getAllAgents(editId);
    }
  }, [editId]);

  useEffect(() => {
    handleGetConditionList(true);
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsLoading(true);
      try {
        let payload = JSON.parse(JSON.stringify(request));
        const resp = editId
          ? await updateSkill(editId, payload)
          : await createSkill(payload);

        if (resp) {
          toast.success(`${editId ? "Updated" : "Created"} Successfully`);

          setRequest({
            name: "",
            description: "",
            match_type: "any",
            skill_conditions: [
              {
                field_name: "group_id",
                operator: "in",
                value: [],
              },
            ],
          });
          if (editId) {
            navigate(
              `/${getValue(
                params,
                "orgId",
                ""
              )}/settings/helpdesk/assignment-rules`
            );
          } else {
            navigate(
              `/${getValue(
                params,
                "orgId",
                ""
              )}/settings/helpdesk/assignment-rules/edit/${getValue(
                resp,
                "data.id",
                ""
              )}`
            );
          }
          setIsLoading(false);
        } else {
          toast.error(
            `Failed to ${editId ? "editing" : "creating"} Assignment rule`
          );
        }
      } catch (error) {
        toast.error(`Error ${editId ? "editing" : "creating"} Assignment rule`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleGetConditionList = async (status: boolean) => {
    try {
      setIsLoading1(status);
      const resp = await getSkillsConditionFields();
      if (resp) {
        const conditions = getValue(resp, "data", []).map((item: any) => ({
          ...item,
          value: getValue(item, "fieldName", ""),
          label: getValue(item, "fieldLabel", ""),
        }));
        setConditionList(conditions);
      }
    } catch (error) {
      toast.error("Failed to load conditions.");
    } finally {
      setIsLoading1(false);
    }
  };

  const handleGetSkillDetails = async (status: boolean) => {
    try {
      setIsLoading4(status);
      const resp = await getSpecificSkill(editId);
      if (resp) {
        setRequest({
          ...request,
          name: getValue(resp, "data.name", ""),
          description: getValue(resp, "data.description", ""),
          match_type: getValue(resp, "data.match_type", ""),
          skill_conditions: getValue(resp, "data.skill_conditions", []),
        });
        setSkillDetails(getValue(resp, "data", []));
        setIsLoading4(false);
      }
    } catch (error) {
      toast.error("Failed to load Assignment rule.");
    } finally {
      setIsLoading4(false);
    }
  };

  const getAllAgents = async (id: string) => {
    try {
      setIsLoading2(true);
      const resp = await getSkillAgents(id);
      if (resp) {
        const modifiedUsers = getValue(resp, "data.agents", "").map(
          (user: any) => ({
            value: getValue(user, "id", ""),
            label:
              capitalizeFirstLetter(getValue(user, "first_name", "")) +
              " " +
              capitalizeFirstLetter(getValue(user, "last_name", "")),
          })
        );
        setAgents(modifiedUsers);
        setIsLoading2(false);
      }
    } catch (error) {
      toast.error("Failed to load Agents.");
      setIsLoading2(false);
    } finally {
      setIsLoading2(false);
    }
  };

  const handleAddAgent = async () => {
    setIsLoading3(true);
    try {
      let payload = JSON.parse(JSON.stringify(agentsRequest));
      const resp = await updateSkillAgents(editId, payload);
      if (resp) {
        toast.success("Updated Agents Successfully");
        setAgentsRequest({
          agents: [],
        });
        setIsModalOpen(false);
        getAllAgents(editId);
        setIsLoading3(false);
        handleGetSkillDetails(true);
        setSelectedAgentIds([]);
        // navigate(
        //   `/${getValue(
        //     params,
        //     "orgId",
        //     ""
        //   )}/settings/helpdesk/groups/edit/${editId}`
        // );
      } else {
        toast.error(`Failed editing agents`);
      }
    } catch (error) {
      toast.error(`Error editing agents`);
    } finally {
      setIsLoading3(false);
    }
  };

  const handleDeleteAgent = () => {
    Modal.confirm({
      title: `Are you sure you want to delete this agent from the group?`,
      okText: "Delete",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          const payload = {
            agents: [...selectedAgentIds],
          };
          let queryRequest = JSON.parse(JSON.stringify(payload));
          await deleteSkillAgents(editId, queryRequest);
          toast.success(
            `Agent${
              selectedAgentIds.length > 1 ? "s" : ""
            } deleted successfully`
          );
          setSelectedAgentIds([]);
          handleGetSkillDetails(true);
          getAllAgents(editId);
        } catch (error) {
          toast.error("Error deleting agent");
        }
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                               Other Section                                  */
  /* -------------------------------------------------------------------------- */
  //Modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "General Settings",
      children: (
        <SkillsGeneralSettings
          request={request}
          setRequest={setRequest}
          validator={simpleValidator}
          editId={editId}
          conditionList={conditionList}
          isLoading1={isLoading1}
        />
      ),
    },
    ...(editId
      ? [
          {
            key: "2",
            label: "Agents",
            children: (
              <GroupsAgents
                agents={agents}
                isLoading2={isLoading2}
                groupDetails={skillDetails}
                setSelectedAgentIds={setSelectedAgentIds}
                showModal={showModal}
                selectedAgentIds={selectedAgentIds}
                handleDeleteAgent={handleDeleteAgent}
              />
            ),
          },
        ]
      : []),
  ];

  const onChange = (key: string) => {
    setCurrentTab(key);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    if (UrlParams.addAgent) {
      navigate(
        `/${getValue(
          params,
          "orgId",
          ""
        )}/settings/helpdesk/assignment-rules/edit/${editId}`
      );
    }
  };

  const handleUsers = (selectedAgents: string[]) => {
    setAgentsRequest((prevRequest: any) => ({
      ...prevRequest,
      agents: selectedAgents.map((id) => ({ id })),
    }));
  };

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        backButton
        route={`/${getValue(
          params,
          "orgId",
          ""
        )}/settings/helpdesk/assignment-rules`}
        titleLoading={isLoading4}
        title={`${editId ? "Edit" : "New"} Assignment Rule`}
        addChildren={
          <div className="d-flex align-items-center gap-2">
            <Button
              // type=""
              size="large"
              onClick={() =>
                navigate(
                  `/${getValue(params, "orgId", "")}/settings/helpdesk/groups`
                )
              }
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={() => handleSubmit()}
              loading={isLoading}
              disabled={!getValue(request, "name", "")}
            >
              {editId ? "Update" : "Create"} Rule
            </Button>
          </div>
        }
      />
      <div className="manageFieldRightSettings overflow-y-auto p-4">
        {!editId && (
          <Alert
            message="Add Agents after creating the Rule "
            type="info"
            showIcon
            className="w-fit-content"
          />
        )}
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
      <Modal
        title="Add Agents"
        okText="Add to Assignment Rules"
        open={isModalOpen}
        onOk={handleAddAgent}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: getValue(agentsRequest, "agents.length", 0) === 0,
        }}
      >
        <Select
          size="large"
          mode="multiple"
          placeholder="Select one or more agents"
          options={agents}
          className="w-100 mb-4"
          value={agentsRequest.agents.map((agent: { id: string }) => agent.id)}
          onChange={(selectedValues: string[]) => {
            handleUsers(selectedValues);
          }}
          maxTagCount="responsive"
        />
      </Modal>
    </CompanyProfileDetailsLayout>
  );
};

export default SettingSkillDetails;
