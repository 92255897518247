import React, { useState } from "react";
import { Input } from "antd";
import { getValue } from "@utils/lodash";

const { TextArea } = Input;

function SourceText(props: any) {
  return (
    <div>
      <div className="text-container">
        <div className="text-input-wrapper">
          <TextArea
            placeholder="Enter text ..."
            value={getValue(props, `request.texts_source.texts`, "")}
            onChange={(e) =>
              props.setRequest({
                ...props.request,
                texts_source: {
                  ...props.request.texts_source,
                  texts: e.target.value,
                  name: e.target.value,
                },
              })
            }
            autoSize={{ minRows: 16 }}
            bordered={false}
          />
        </div>
      </div>
      <p className="text-center mt-3 small_text__14">
        {getValue(props, `request.texts_source.text.length`, 0)} characters
      </p>
    </div>
  );
}

export default SourceText;
