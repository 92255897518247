import { getValue } from "@utils/lodash";
import ChatReceiverSection from "./chat-receiver";
import ChatSenderSection from "./chat-sender";
import { groupByDate } from "@common/date-helpers";
import { memo } from "react";

function ConversationMessages(props: any) {
  const { conversation, setSelectedReplyMessage } = props;
  const groupedData = groupByDate(conversation);
  return (
    <div>
      {Object.keys(groupedData).map((group: any, index: number) => {
        return (
          <div key={index}>
            <div className="chat-date-container">
              <p className="chat-date-header">{group}</p>
            </div>
            {getValue(groupedData, `[${group}].length`, 0) > 0
              ? getValue(groupedData, `[${group}]`, []).map(
                  (item: object, index: number) => {
                    if (getValue(item, `type`, "") !== "request_welcome")
                      return (
                        <>
                          {!getValue(item, `business_initiated`, false) ? (
                            getValue(item,`text`,'') &&
                            <ChatReceiverSection
                              item={item}
                              setSelectedReplyMessage={setSelectedReplyMessage}
                            />
                          ) : (
                            getValue(item,`text`,'') &&
                            <ChatSenderSection
                              item={item}
                              setSelectedReplyMessage={setSelectedReplyMessage}
                            />
                          )}
                        </>
                      );
                  }
                )
              : ""}
          </div>
        );
      })}
    </div>
  );
}

export default memo(ConversationMessages);
