import { DeleteOutlined } from "@ant-design/icons";
import { getColorCode, getTextColor } from "@common/colors";
import { convertCurrentDateWithTime } from "@common/date-helpers";
import { concatString, getOnlyFirstLetterOfWord } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Edit } from "lucide-react";

function TicketNoteCard(props: any) {
  return (
    <div className="ticket-detail-message-wrapper my-3">
      <div
        className="image-container"
        style={{
          background: getColorCode(
            getOnlyFirstLetterOfWord(
              getValue(props, `data.msg_user.first_name`, "")
            )
          ),
          color: getTextColor(
            getColorCode(
              getOnlyFirstLetterOfWord(
                getValue(props, `data.msg_user.first_name`, "")
              )
            )
          ),
        }}
      >
        <p className="header-text">
          {getOnlyFirstLetterOfWord(
            getValue(props, `data.msg_user.first_name`, "")
          )}
        </p>
      </div>
      <div className="w-100">
        <div className="d-flex align-items-center gap-3 justify-content-between">
          <div className="d-flex align-items-center gap-3 ">
            <h6 className="header_text__16">
              {getValue(props, `data.msg_user.first_name`, "")}{" "}
              {getValue(props, `data.msg_user.last_name`, "")}
            </h6>
          </div>

          <div className="d-flex align-items-center gap-3">
            <p className="small_text__13">
              {convertCurrentDateWithTime(
                getValue(props, `data.created_at`, "")
              )}{" "}
            </p>
            {/* <Forward size={16} className="cursor-pointer" /> */}
          </div>
        </div>

        <p
          className="settings-details-desc pb-2 mt-3"
          dangerouslySetInnerHTML={{
            __html: concatString(getValue(props, "data.html", ""), 100),
          }}
        />
        <div className="d-flex align-items-center justify-content-between">
          <div></div>
          <div className="d-flex align-items-center gap-2">
            <div
              className="cursor-pointer"
              onClick={() => props.handleEditNote(getValue(props, `data`, {}))}
            >
              <Edit size={16} />
            </div>
            <div
              className="cursor-pointer"
              onClick={() =>
                props.handleDeleteView(getValue(props, "data.id", ""), "")
              }
            >
              <DeleteOutlined size={16} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketNoteCard;
