import { EditOutlined } from "@ant-design/icons";
import ImportCheckbox from "@components/BulkImport/components/import-checkbox";
import InputRuleForm from "@components/InputRuleForm/form";
import { getValue } from "@utils/lodash";
import { Button } from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

const BotBuilderStepOne = (props: any) => {
  const { request, setRequest } = props;
  const params = useParams();
  const navigate = useNavigate();

  const platformList = [
    {
      label: "Web",
      value: "WEB",
    },
    {
      label: "Whatsapp",
      value: "WHATSAPP",
    },
  ];

  const typeEnum = [
    { value: "chatbot", label: "Chatbot" },
    { value: "webchat", label: "Webchat" },
    { value: "ai-chatbot", label: "AI Chatbot" },
  ];

  const handleCheckboxChange = (value: string) => {
    if (!getValue(props, "edit", false)) {
      setRequest({
        ...request,
        platform: value,
      });

      navigate(
        `/${getValue(
          params,
          "orgId",
          ""
        )}/chatbot/builder/${value.toLowerCase()}`
      );
    } else if (getValue(request, "platform", "") !== value) {
      toast.error("Platform update not allowed");
    }
  };

  const [showDescription, setShowDescription] = useState(true);
  const handlePositionCheckboxChange = (value: string) => {
    setRequest({ ...request, type: value });
  };
  return (
    <div>
      <div className="">
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value={getValue(request, `name`, "")}
          placeholder="Enter a name (Ex: Landing page bot )"
          required
          label="Name Your Bot (For Own Reference)"
          className="mb-2"
          onChange={(e: any) =>
            setRequest({
              ...request,
              name: e.target.value,
            })
          }
        />
      </div>
      <div className="mt-3">
        {showDescription ? (
          <div className="d-flex gap-3 mt-3">
            {getValue(request, `description`, "") ? (
              getValue(request, `description`, "")
            ) : (
              <span
                className="header_blue_text__14 cursor-pointer"
                onClick={() => setShowDescription(false)}
              >
                Add description
              </span>
            )}
            <EditOutlined onClick={() => setShowDescription(false)} />
          </div>
        ) : (
          <>
            <InputRuleForm
              inputType="TEXTAREA"
              name="description"
              value={getValue(request, `description`, "")}
              placeholder="Enter a description (Ex: Landing page bot to capture leads from google ads)"
              label="Describe Your Bot"
              // required
              onChange={(e: any) =>
                setRequest({
                  ...request,
                  description: e.target.value,
                })
              }
            />
            <Button onClick={() => setShowDescription(true)}>Cancel</Button>
          </>
        )}
      </div>

      <div className="d-flex gap-3 mt-2">
        {typeEnum.map((item: any, index: any) => {
          const value = getValue(item, "value", "");
          const isSelected = getValue(request, "position", "") === value;
          // const markerComponent =
          //   markerComponents[value] || markerComponents.DEFAULT;

          return (
            <ImportCheckbox
              key={index}
              value={value}
              label={getValue(item, "label", "")}
              // svgComponent={
              //   <div
              //     className={`bot-position-container ${
              //       isSelected ? "add-position-border" : ""
              //     }`}
              //   >
              //     {markerComponent}
              //   </div>
              // }
              onChange={handlePositionCheckboxChange}
              selectedOption={getValue(request, "type", "")}
              checked={isSelected}
              groupName="type"
            />
          );
        })}
      </div>
    </div>
  );
};

export default BotBuilderStepOne;
