import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { QueryRequestHelper, removeBracketsFromQuery } from "common/query-request-helper";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "sonner";
import { handleNavigateURL } from "common/url-navigate";
import DeleteModal from "@components/Dialogs/Modals/deleteModal";
import { removeDuplicates } from "common/text-helpers";
import { deleteTicket, getAllTickets } from "@services/tickets.service";
import { useStateContext } from "context/profileProvider";
import { sortTaskFieldList } from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import CommonAddSubPipeline from "@components/Pages/Pipeline/AddSubPipeline";
import CommonAddFilterView from "@components/Pages/Pipeline/AddFilterViews";
import Pagination from "@components/Pages/Pipeline/common/pagination";
import { listAllModules } from "@services/modules.service";
import useDynamicTitle from "@context/useDynamicTitle";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import TicketSubHeader from "@components/Pages/Tickets/SubHeader";
import TicketsLeftSideBar from "@pages/Private/Tickets/components/LeftSection";
import "./tickets.scss";
import { getAllModuleFields } from "@services/module-fields.service";
import LoaderMain from "@components/common/Loader/loading1";
import ModulePipelineSubheader from "@components/Pages/Pipeline/common/subheader";
import CustomCardView from "./components/MiddleSection";
import TicketsRightSection from "./components/RightSection";
import {
  filterInitialState,
  filterModeState,
  getUrlParams,
  getUrlParamsObj,
} from "./components/helper";

interface ITicketsProps {}

const Tickets: React.FunctionComponent<ITicketsProps> = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const { userId, selectedModuleId, allModules } = useStateContext();

  /* -------------------------------------------------------------------------- */
  /*                               Validation Section                           */
  /* -------------------------------------------------------------------------- */
  const simpleValidator1 = useRef(new SimpleReactValidator());
  const [, forceUpdate1] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getAllModules();
      setSelectedStaus("all_tickets");
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      if (UrlParams.filter_status) {
        setSelectedStaus(UrlParams.filter_status);
      }
      setRequest(getUrlParams());
      getAllModules();
    }
  }, [window.location.href]);
  /* -------------------------------------------------------------------------- */
  /*                                  API Section                               */
  /* -------------------------------------------------------------------------- */
  const [selectedIds, setSelectedIds] = useState([]);
  const [moduleId, setModuleId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownFilterOptions, setDropdownFilterOptions] = useState([]);
  const getAllModules = async () => {
    try {
      let resp = await listAllModules("");
      if (resp) {
        let task_modules = getValue(resp, `data`, []).find(
          (item: object) => getValue(item, `api_name`, "") === "tickets"
        );
        setModuleId(getValue(task_modules, `id`, ""));
        let fields = await getAllModuleFields(getValue(task_modules, `id`, ""));
        if (fields) {
          let list = sortTaskFieldList(getValue(fields, `data`, []));
          for (const item of list) {
            if (getValue(item, `input_type`, "") !== "select_multiple") {
              if (
                typeof getValue(item, `default_value`, null) === "string" ||
                typeof getValue(item, `default_value`, null) === "boolean"
              ) {
                item.value = getValue(item, `default_value`, null);
              }
            } else {
              item.value = [getValue(item, `default_value`, null)];
            }
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          const aDict = list.reduce((dict: any, item: any) => {
            dict[item.name] = item;
            return dict;
          }, {});
          const ReorderedList: any = [];
          // Add elements from b in the order they appear in a
          for (const name of getValue(fields, `data`, [])) {
            if (aDict[name]) {
              ReorderedList.push(aDict[name]);
            }
          }
          // Add elements from a that are not in b to the end
          for (const item of list) {
            if (!getValue(fields, `data`, []).includes(item.name)) {
              ReorderedList.push(item);
            }
          }
          setSelectedFields(removeDuplicates(ReorderedList));
          setDropdownFilterOptions(
            getDropdownFields(removeDuplicates(ReorderedList))
          );
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
        getTickets();
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getDropdownFields = (fields: any) => {
    const result: any = {};
    const dropdownFields = [
      "category",
      "source",
      "resolution",
      "internal_type",
      "segment",
      "status",
    ];

    fields.forEach((field: any) => {
      // Check if this field has dropdown options and is in our target list
      if (
        field.module_field?.dropdown_options?.length > 0 &&
        dropdownFields.includes(field.module_field.api_name)
      ) {
        // Map the options to a simpler format
        const options = field.module_field.dropdown_options.map(
          (option: any) => ({
            label: option.label,
            value: option.value,
            seq_num: option.seq_num,
          })
        );

        // Sort by sequence number
        options.sort((a: any, b: any) => a.seq_num - b.seq_num);

        // Add to result object using api_name as key
        result[field.module_field.api_name] = options;
      }
    });
    return result;
  };
  const [selectedStatus, setSelectedStaus] = useState("");
  const [listLoading, setListLoading] = useState(true);
  const [list, setList] = useState([]);
  const getTickets = async () => {
    try {
      let payload = {
        filter: getValue(UrlParams, `filter_status`, ""),
        module_id: await selectedModuleId(),
      };
      let queryRequest = QueryRequestHelper({
        ...payload,
        ...getUrlParamsObj(),
      });
      setListLoading(true);
      let resp = await getAllTickets(removeBracketsFromQuery(queryRequest));
      if (resp) {
        setList(getValue(resp, `data.tickets`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setListLoading(false);
      } else {
        setList([]);
        setListLoading(false);
      }
    } catch (error) {
      setList([]);
      setListLoading(false);
    }
  };

  function formatDynamicGroupIds(url: any, keyBase: any) {
    const urlObj = new URL(url);
    const arrayKey = `${keyBase}[]`; // Check for the array version
    const values = urlObj.searchParams.getAll(arrayKey);

    if (values.length === 1) {
      // Replace the array key with a single key if there's only one value
      urlObj.searchParams.delete(arrayKey);
      urlObj.searchParams.set(keyBase, values[0]);
    } else if (values.length > 1) {
      // If multiple values exist, ensure the array key is used
      urlObj.searchParams.delete(keyBase);
      values.forEach((value) => {
        urlObj.searchParams.append(arrayKey, value);
      });
    }

    return urlObj.toString();
  }

  /* ----------------------------- Delete View Section  ------------------------- */
  const [deleteId1, setDeleteId1] = useState("");
  const [deleteTableValue, setDeleteTableValue] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);
  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };
  const handleDeleteTableRow = async () => {
    try {
      let resp = await deleteTicket(deleteId1);
      if (resp) {
        toast.success("Deleted Successfully");
        //delete
        setSelectedIds([]);
        handleModal1();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                       Create SubPipline Section                            */
  /* -------------------------------------------------------------------------- */

  const [isOpenSubPipeline, setIsOpenSubPipeline] = useState(false);
  const toggleOpenSubpipeline = () => {
    setIsOpenSubPipeline(!isOpenSubPipeline);
  };
  const [selectedFields, setSelectedFields] = useState([]);

  /* -------------------------------------------------------------------------- */
  /*                  Hide Body Scroll When Modal Open                          */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (isOpenSubPipeline) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpenSubPipeline]);

  const handleNaviagteView = (name: string, value: string) => {
    navigate(handleNavigateURL(name, value));
  };
  /* -------------------------------------------------------------------------- */
  /*                       Advanced Filterview Sectio                           */
  /* -------------------------------------------------------------------------- */
  const [isOpenFilterView, setIsOpenFilterView] = useState(false);
  const handleOpenFilterView = () => {
    setIsOpenFilterView(!isOpenFilterView);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Module Info                                */
  /* -------------------------------------------------------------------------- */
  const findModuleType = (id: string) => {
    let info =
      getValue(allModules, `length`, 0) > 0
        ? allModules.find((item: object) => getValue(item, `id`, "") == id)
        : [];
    return info;
  };
  const moduleInfo = useMemo(() => findModuleType(moduleId), [
    allModules,
    moduleId,
  ]);
  useDynamicTitle(
    getValue(moduleInfo, `linkText`, "")
      ? getValue(moduleInfo, `linkText`, "")
      : "tickets"
  );

  /* -------------------------------------------------------------------------- */
  /*                                Sheet view                                  */
  /* -------------------------------------------------------------------------- */

  const [showSidebar, setShowSidebar] = useState(true);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    if (UrlParams.search) {
      delete UrlParams.search;
      let payload: any = { ...UrlParams };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      // getData(getValue(staticFieldRequest, `pipeline_id`, ""));
    }
  };
  const handleChangeSearch = async (e: any) => {
    const regex = /^[a-zA-Z0-9@.]*$/;
    if (regex.test(e.target.value)) {
      setSearchInput(e.target.value);
      let payload: any = {
        ...UrlParams,
        search: e.target.value,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    }
  };
  /* -------------------------------------------------------------------------- */
  /*                               Filter section                               */
  /* -------------------------------------------------------------------------- */

  const [request, setRequest] = useState(filterInitialState);
  const [filterMode, setFilterMode] = useState<any>(filterModeState);

  const resetRequest = () => {
    setRequest(filterInitialState);
    const queryRequest = QueryRequestHelper(filterInitialState);
    navigate(`${window.location.pathname}?${queryRequest}`);
    getAllModules();
  };

  const handleApplyFilter = () => {
    const queryRequest = QueryRequestHelper(request);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  return (
    <MainLayout {...props} isLoading={listLoading}>
      <TicketSubHeader
        subHeaderListTitle={getValue(moduleInfo, "linkText", "")}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        hideClose={!getValue(UrlParams, `pipeline`, "") ? true : false}
        moduleInfo={moduleInfo}
        toggleSidebar={toggleSidebar}
        params={params}
        hideView
      />
      <div className={`d-flex`}>
        <div className="ticketsWrapper w-100">
          <div className="d-flex">
            {showSidebar && (
              <TicketsLeftSideBar
                userId={userId}
                permissions={getValue(props, `permissions`, [])}
                handleNaviagteView={handleNaviagteView}
                totalCount={totalCount}
                selectedStatus={selectedStatus}
              />
            )}
            <div className={"tickets-card-middle-wrapper"}>
              <ModulePipelineSubheader
                hideStage
                hideEditColumns
                // showAdvancedFilter
                hideNavView
                hideCloseDate={true}
                module={"tickets"}
                plural_label={"tickets"}
                permissions={getValue(props, `permissions`, [])}
                moduleInfo={moduleInfo}
                setIsOpenSubPipeline={setIsOpenSubPipeline}
                //search section
                toggleSearch={toggleSearch}
                toggleSearchClose={toggleSearchClose}
                handleChangeSearch={handleChangeSearch}
                searchInput={search}
                showSearchTextbox={showSearchTextbox}
                hideCreateDate={true}
                isCreateDealVisible
              />
              {listLoading ? (
                <LoaderMain />
              ) : (
                <CustomCardView
                  params={params}
                  data={list}
                  module="tickets"
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                />
              )}
              {totalCount > 10 && (
                <Pagination
                  totalCount={totalCount}
                  limit={limit}
                  page_no={page_no}
                  handleChangePagination={handleChangePagination}
                  handleChangeLimit={handleChangeLimit}
                  list={list}
                />
              )}
            </div>
            <TicketsRightSection
              dropdownFilterOptions={dropdownFilterOptions}
              request={request}
              setRequest={setRequest}
              handleApplyFilter={handleApplyFilter}
              resetRequest={resetRequest}
              filterMode={filterMode}
              setFilterMode={setFilterMode}
            />
          </div>
        </div>
      </div>
      {/* ------------------------- Delete Table Section ------------------------------- */}
      <DeleteModal
        isOpen={isOpen1}
        handleModal={handleModal1}
        handleSubmit={handleDeleteTableRow}
        deleteValue={deleteTableValue}
      />
      {/* ------------------------- Creating Sub Pipelines ------------------------------ */}
      <CommonAddSubPipeline
        isOpen={isOpenSubPipeline}
        toggle={toggleOpenSubpipeline}
        // getAllPipelines={getAllPipelines}
        fields={selectedFields}
        setFields={setSelectedFields}
        simpleValidator={simpleValidator1}
        forceUpdate={forceUpdate1}
        module={"tickets"}
        permissions={getValue(props, `permissions`, [])}
      />
      {/* ------------------------- Advanced Filter View ------------------------- */}
      {isOpenFilterView && (
        <CommonAddFilterView
          isOpenFilterView={isOpenFilterView}
          module={"tickets"}
          handleOpenFilterView={handleOpenFilterView}
          // getData={getAllPipelines}
          permissions={["create"]}
        />
      )}
    </MainLayout>
  );
};

export default Tickets;
