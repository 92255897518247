import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import React, { useEffect, useRef, useState } from "react";
import CompanyProfileSubHeader from "../../CompanyProfile/components/CompanyProfileSubHeader";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import { formatString, sortJSONObjectArray } from "@common/text-helpers";
import InputRuleForm from "@components/InputRuleForm/form";
import { handleTextChange } from "@common/handleChange";
import { listAllModules } from "@services/modules.service";
import {
  createAutomation,
  getAllAutomationsUpdateFields,
  getAutomationsConditionFields,
  getSpecificAutomations,
} from "@services/tickets-automation.service";
import { AutomationLookupEnums } from "./helpers/enum";
import ConditionFields from "./components/conditions/ConditionFields";
import OptionFields from "./components/actions/OptionFields";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "sonner";
import EventFields from "./components/events/EventFields";
import { Badge, Tag, Timeline } from "antd";

type RequestState = {
  id?: string;
  name: string;
  type: string;
  module_id: string;
  pattern: string;
  conditions: {
    action: string;
    fieldId: string;
    operator: string;
    value: never[];
    index: number;
  }[];
  actions: {
    updates: {
      action: string;
      fieldId: string;
      value: never[];
      isEmpty: boolean;
    }[];
    notes?: {
      html: string;
      to: any[];
      is_private: boolean;
      attachments: any[];
      id?: string;
      action: string;
    }[];
  };
};

const TicketsAutomationDetails = ({ props }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());

  const [editId, setEditId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);

  const initialRequest = {
    name: "",
    type: "ticket_creation_rules",
    module_id: "",
    pattern: "",
    conditions: [
      {
        action: "add",
        fieldId: "",
        operator: "{IS}",
        value: [],
        index: 1,
      },
    ],
    actions: {
      updates: [
        {
          action: "add",
          fieldId: "",
          value: [],
          isEmpty: false,
        },
      ],
      // notes: [
      //   {
      //     to: [],
      //     html: "",
      //     attachments: [],
      //     is_private: true,
      //     action: "add",
      //   },
      // ],
    },
  };
  const [allConditions, setAllConditions] = useState(false);
  const [specificData, setSpecificData] = useState([]);

  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  const [request, setRequest] = useState<RequestState>(initialRequest);
  const [valueTypes, setValueTypes] = useState<any>([]);

  useEffect(() => {
    if (UrlParams.type) {
      setRequest((prevRequest) => ({
        ...prevRequest,
        type: UrlParams.type,
      }));
    }
    getAllModules();
    getConditions();
    getUpdateFields();
  }, []);

  useEffect(() => {
    if (UrlParams.type === "ticket_update_rules") {
      setRequest((prev) => ({
        ...prev,
        events: [
          {
            id: "",
            action: "add",
            fieldId: "",
            index: 1,
            value: "",
            from: "",
            to: "",
          },
        ],
        performer_type: "agent",
      }));
    }
  }, [UrlParams.type]);

  useEffect(() => {
    if (getValue(params, "id", "")) {
      setEditId(getValue(params, "id", ""));
      handleGetSpecificAutomation(getValue(params, "id", ""));
    }
  }, [params]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */

  const [currentModule, setCurrentModule] = useState(null);
  const getAllModules = async () => {
    try {
      const resp = await listAllModules("");
      if (resp) {
        const ticketsModule = getValue(resp, `data`, []).find(
          (module: any) => module.api_name === "tickets"
        );

        if (ticketsModule) {
          setCurrentModule(ticketsModule);
          setRequest((prevRequest) => ({
            ...prevRequest,
            type: UrlParams.type,
            module_id: ticketsModule.id,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching modules:", error);
    }
  };

  // const [conditionFields, setConditionFields] = useState([]);
  // const handleGetConditionFields = async () => {
  //   try {
  //     const resp = await getAutomationsConditionFields();
  //     if (resp) {
  //       setConditionFields(getValue(resp, "data", []));
  //     }
  //   } catch (error) {
  //     console.error("Error fetching modules:", error);
  //   }
  // };

  const [automationConditions, setAutomationCondtions] = React.useState<any>(
    []
  );
  const [automationOptions, setAutomationOptions] = React.useState<any>([]);
  const getConditions = async () => {
    setAutomationOptions([]);
    try {
      let resp = await getAutomationsConditionFields();
      if (resp) {
        // Additional static fields to be added
        const staticFields = [
          {
            id: "created_at",
            value: "created_at",
            label: "Created At",
            name: "created_at",
            inputType: "datetime_dashboard",
            fieldType: "datetime_dashboard",
            fieldId: "created_at",
            conditions: AutomationLookupEnums,
          },
          {
            id: "updated_at",
            value: "updated_at",
            label: "Updated At",
            name: "updated_at",
            inputType: "datetime_dashboard",
            fieldType: "datetime_dashboard",
            fieldId: "updated_at",
            conditions: AutomationLookupEnums,
          },
          {
            id: "created_by",
            value: "created_by",
            label: "Created By",
            name: "created_by",
            inputType: "lookup",
            fieldId: "created_by",
            fieldType: "lookup",
            lookupModule: { api_name: "org_user" },
            conditions: AutomationLookupEnums,
          },
          {
            id: "updated_by",
            value: "updated_by",
            label: "Updated By",
            name: "updated_by",
            inputType: "lookup",
            fieldType: "lookup",
            fieldId: "updated_by",
            lookupModule: { api_name: "org_user" },
            conditions: AutomationLookupEnums,
          },
        ];
        // Function to remove duplicates
        const removeDuplicates = (
          existingArray: any,
          newArray: any,
          uniqueKey = "id"
        ) => {
          const existingIds = new Set(
            existingArray.map((item: any) => item[uniqueKey])
          );
          const filteredNewArray = newArray.filter(
            (newItem: any) => !existingIds.has(newItem[uniqueKey])
          );
          return [...existingArray, ...filteredNewArray];
        };

        // Process workflow options with deduplication
        const processedWorkflowOptions = getValue(resp, `data`, []).map(
          (item: object) => ({
            id: getValue(item, `fieldId`, ""),
            label: getValue(item, `fieldLabel`, ""),
            inputType: getValue(item, `fieldType`, ""),
            value: getValue(item, `fieldId`, ""),
          })
        );

        const WorkflowOptions = getValue(resp, `data`, []).map(
          (item: object) => ({
            ...item,
            inputType:
              getValue(item, `fieldId`, "") === "pipeline_stage_id"
                ? "select"
                : getValue(item, `fieldType`, ""),
            fieldType:
              getValue(item, `fieldId`, "") === "pipeline_stage_id"
                ? "select"
                : getValue(item, `fieldType`, ""),
          })
        );

        // Update workflow options with deduplication
        setAutomationOptions((prevOptions: any) =>
          removeDuplicates(
            prevOptions,
            [...processedWorkflowOptions, ...staticFields],
            "id"
          )
        );

        // Update workflow conditions with deduplication
        setAutomationCondtions((prevConditions: any) =>
          removeDuplicates(
            prevConditions,
            [...WorkflowOptions, ...staticFields],
            "id"
          )
        );
      }
    } catch (error) {
      console.error("Error fetching conditions:", error);
    }
  };

  const [updateFields, setUpdateFields] = React.useState<any>([]);
  const getUpdateFields = async () => {
    try {
      let resp = await getAllAutomationsUpdateFields();
      if (resp) {
        const updatedArray = getValue(resp, "data", []).map((item: any) => {
          const { ...rest } = item;
          return {
            label: item.fieldLabel,
            value: item.fieldId,
            ...rest,
          };
        });
        setUpdateFields(updatedArray);
      }
    } catch (error) {
      console.error("Error fetching options", error);
    }
  };

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsLoading(true);
      try {
        let payload = JSON.parse(JSON.stringify(request));
        const resp = await createAutomation(payload);

        if (resp) {
          toast.success(`${editId ? "Updated" : "Created"} Successfully`);

          setRequest(initialRequest);
          navigate(
            `/${getValue(params, "orgId", "")}/settings/helpdesk/tickets`
          );
          setIsLoading(false);
        } else {
          toast.error(
            `Failed to ${editId ? "editing" : "creating"} Automation`
          );
        }
      } catch (error) {
        toast.error(`Error ${editId ? "editing" : "creating"} Automation`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  function extractOperators(input: string) {
    const operators = [];
    operators.push("WHEN"); // Add WHEN as the first element
    const keywords = input.match(/[A-Z]+/g); // Extract uppercase words
    if (keywords) {
      keywords.forEach((word) => {
        operators.push(word);
      });
    }
    return operators;
  }

  function convertExpression(input: string) {
    return input
      .replace(/\[/g, "")
      .replace(/\]/g, "")
      .replace(/\sAND\s/g, " ")
      .replace(/\(/g, "( ")
      .replace(/\)/g, " )");
  }

  const handleGetSpecificAutomation = async (id: string) => {
    try {
      setIsLoading1(true);
      const resp = await getSpecificAutomations(id);
      if (resp) {
        setSpecificData(getValue(resp, "data", ""));
        let conditionsTypes = extractOperators(
          getValue(resp, `data.pattern`, "")
        );
        let conditions =
          getValue(resp, `data.conditions.length`, 0) > 0
            ? sortJSONObjectArray(
                getValue(resp, `data.conditions`, []),
                "index"
              ).map((item: object, index: number) => ({
                pattern:
                  getValue(conditionsTypes, `length`, 0) > 0
                    ? conditionsTypes[index]
                    : "",
                value: [getValue(item, `value`, "")],
                fieldId: getValue(item, `module_field.id`, ""),
                operator: getValue(item, `operator`, ""),
                id: getValue(item, `id`, ""),
                action: "update",
                index: getValue(item, `index`, null),
              }))
            : [];
        // let emailActions =
        //   getValue(resp, `data.email_actions.length`, 0) > 0
        //     ? getValue(resp, `data.email_actions`, []).map((item: object) => ({
        //         action: "update",
        //         id: getValue(item, `id`, ""),
        //         from: getValue(item, `from`, ""),
        //         moduleId: getValue(item, `module_id`, ""),
        //         name: getValue(item, `name`, ""),
        //         replyToMail: getValue(item, `reply_to_email`, ""),
        //         templateId: getValue(item, `template_id`, ""),
        //         recipients: getValue(item, `recipient_emails`, ""),
        //         displayName: getValue(item, `display_name`, ""),
        //         emailUsers:
        //           getValue(item, `email_action_users.length`, 0) > 0
        //             ? getValue(item, `email_action_users`, []).map(
        //                 (item: object) =>
        //                   getValue(item, `org_user_id`, "")
        //                     ? getValue(item, `org_user_id`, "")
        //                     : getValue(item, `user_type`, "")
        //               )
        //             : [],
        //       }))
        //     : [];
        // let taskActions =
        //   getValue(resp, `data.task_actions.length`, 0) > 0
        //     ? getValue(resp, `data.task_actions`, []).map((item: object) => ({
        //         action: "update",
        //         id: getValue(item, `id`, ""),
        //         dueDate: getValue(item, `due_date`, ""),
        //         dueDateDays: getValue(item, `due_date_days`, ""),
        //         name: getValue(item, `name`, ""),
        //         owner: getValue(item, `owner_id`, ""),
        //         reminderDate: getValue(item, `reminder_date`, ""),
        //         moduleId: getValue(item, `module_id`, ""),
        //         priority: getValue(item, `priority`, ""),
        //       }))
        //     : [];

        // let whatsappActions =
        //   getValue(resp, `data.whatsapp_actions.length`, 0) > 0
        //     ? getValue(resp, `data.whatsapp_actions`, []).map(
        //         (item: object) => ({
        //           action: "update",
        //           id: getValue(item, `id`, ""),
        //           name: getValue(item, `name`, ""),
        //           templateId: getValue(item, `template_id`, ""),
        //           userPreference: getValue(item, `user_preference`, ""),
        //           waAccountId: getValue(item, `wa_account_id`, ""),
        //           alternateNumbers:
        //             getValue(item, `alternate_numbers.length`, 0) > 0
        //               ? getValue(item, `alternate_numbers`, []).map(
        //                   (item: object) => ({ value: item })
        //                 )
        //               : [],
        //         })
        //       )
        //     : [];
        let updateActions =
          getValue(resp, `data.update_actions.length`, 0) > 0
            ? getValue(resp, `data.update_actions`, []).map((item: object) => ({
                action: "update",
                id: getValue(item, `id`, ""),
                // name: getValue(item, `name`, ""),
                // dueDateDays: getValue(item, `due_date_days`, ""),
                fieldId: getValue(item, `module_field.id`, ""),
                value: [getValue(item, `value`, "")],
                // moduleId: getValue(item, `module_field.id`, ""),
                isEmpty: getValue(item, `is_empty`, false),
              }))
            : [];
        let updateValuesTypes =
          getValue(resp, `data.update_actions.length`, 0) > 0
            ? getValue(resp, `data.update_actions`, []).map((item: object) => ({
                fieldType: getValue(item, `module_field.input_type`, ""),
                value: getValue(item, `value`, ""),
                lookupModule: getValue(item, `module_field.lookup`, null),
                dropDownOpts: [],
              }))
            : [];
        setValueTypes(updateValuesTypes);
        setAllConditions(
          getValue(resp, `data.conditions.length`, 0) > 0 ? true : false
        );

        setRequest({
          id: getValue(resp, "data.id", ""),
          name: getValue(resp, "data.name", ""),
          type: getValue(resp, "data.type", ""),
          module_id: getValue(resp, "data.module_id", ""),
          pattern: convertExpression(getValue(resp, `data.pattern`, "")),
          conditions: conditions,
          actions: {
            // emails: emailActions,
            notes: getValue(resp, "data.note_actions", []),
            updates: updateActions,
            // tasks: taskActions,
            // whatsapp: whatsappActions,
          },
        });
      }
    } catch (error) {
      toast.error("Failed to load response.");
    } finally {
      setIsLoading1(false);
    }
  };

  console.log(" request", request);
  // console.log(" specificData", specificData);
  // console.log(" valueTypes", valueTypes);

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        backButton
        title={`${editId ? "Edit" : "New"} ${formatString(
          getValue(request, "type", "")
        )}`}
        showSubmit
        submitText={`${editId ? "Update" : "Create"} Automation`}
        isLoading={isLoading}
        handleSubmit={() => handleSubmit()}
        showCancel
        handleCancel={() =>
          navigate(
            `/${getValue(params, "orgId", "")}/settings/helpdesk/tickets`
          )
        }
        handleBack={() =>
          navigate(
            `/${getValue(params, "orgId", "")}/settings/helpdesk/tickets`
          )
        }
        disabled={getValue(request, "conditions.length", 0) < 2}
      />
      <div className="manageFieldRightSettings overflow-y-auto p-4">
        {editId && (
          <div className="border rounded-3 px-4 pt-4 mb-3">
            <h6 className="header_text__16">Summary</h6>
            <p
              dangerouslySetInnerHTML={{
                __html: getValue(specificData, "summary_name", ""),
              }}
              className="small_text__14 summary-conditions pt-2"
              style={{ marginRight: "4px" }}
            />

            <div>
              <Tag color="default" className="mb-2 mt-3">
                Condition
              </Tag>
              <Timeline
                className="ps-3"
                items={getValue(specificData, "summary_conditions", []).map(
                  (item: any) => ({
                    children: (
                      <p
                        dangerouslySetInnerHTML={{ __html: formatString(item) }}
                        className="small_text__14 summary-conditions"
                        style={{ marginRight: "4px" }}
                      />
                    ),
                  })
                )}
              />
            </div>
            <div>
              <Tag color="default" className="mb-2">
                Action
              </Tag>
              <Timeline
                className="ps-3"
                items={getValue(specificData, "summary_actions", []).map(
                  (item: any) => ({
                    children: (
                      <p
                        dangerouslySetInnerHTML={{ __html: formatString(item) }}
                        className="small_text__14 summary-conditions"
                        style={{ marginRight: "4px" }}
                      />
                    ),
                  })
                )}
              />
            </div>
          </div>
        )}
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value={getValue(request, "name", "")}
          placeholder="Enter Rule Name"
          required
          label="Rule Name (For Own Reference)"
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
          validLeft
          mandatory
          validator={simpleValidator}
          style={{
            maxWidth: "440px",
          }}
        />
        <div>
          <Timeline
            className="mt-5"
            items={[
              {
                color: "green",
                dot: <Badge count={1} style={{ backgroundColor: "#52c41a" }} />,
                children: (
                  <EventFields request={request} setRequest={setRequest} />
                ),
              },
              {
                color: "green",
                dot: <Badge count={2} style={{ backgroundColor: "#ff942f" }} />,
                children: (
                  <ConditionFields
                    request={request}
                    setRequest={setRequest}
                    workflowConditions={automationConditions}
                    workflowOptions={automationOptions}
                    validator={simpleValidator}
                  />
                ),
              },
              {
                color: "red",
                dot: <Badge count={3} style={{ backgroundColor: "#1677ff" }} />,
                children: (
                  <OptionFields
                    request={request}
                    setRequest={setRequest}
                    updateFields={updateFields}
                    validator={simpleValidator}
                    valueTypes={valueTypes}
                    setValueTypes={setValueTypes}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </CompanyProfileDetailsLayout>
  );
};

export default TicketsAutomationDetails;
