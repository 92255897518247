import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, InputRef, Modal, Select, Space } from "antd";
import { useRef, useState } from "react";

const ArticleOptionsModal = ({
  request,
  setRequest,
  openArtcileOptions,
  isLoading,
  toggleArticleOptions,
  handleArticleOptionsUpdate,
}: any) => {
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);

  const tags = request.tags || [];

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    if (name && !tags.includes(name)) {
      setRequest((prev: any) => ({
        ...prev,
        tags: [...tags, name],
      }));
      setName("");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleTagsChange = (selectedTags: string[]) => {
    setRequest((prev: any) => ({
      ...prev,
      tags: selectedTags,
    }));
  };

  return (
    <Modal
      title="Tags"
      open={openArtcileOptions}
      onOk={handleArticleOptionsUpdate}
      okText="Update"
      confirmLoading={isLoading}
      onCancel={toggleArticleOptions}
    >
      <div className="d-flex flex-column gap-3">
        <div className="">
          <label className="form-label">Tags</label>
          <Select
            size="large"
            mode="multiple"
            className="w-100"
            placeholder="Select or add tags"
            value={tags}
            onChange={handleTagsChange}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    placeholder="Add a new tag"
                    ref={inputRef}
                    value={name}
                    onChange={onNameChange}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                  <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                    Add Item
                  </Button>
                </Space>
              </>
            )}
            options={tags.map((tag: any) => ({
              label: tag,
              value: tag,
            }))}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ArticleOptionsModal;
