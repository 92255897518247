import { useEffect, useRef, useState } from "react";
import CompanyProfileSubHeader from "../CompanyProfile/components/CompanyProfileSubHeader";
import { Button, Dropdown, MenuProps, Modal, Pagination, Tooltip } from "antd";
import { LockKeyhole, Mail, MoreVertical, Plus } from "lucide-react";
import CompanyProfileDetailsLayout from "@layouts/SettingsLayout/CompanyProfile";
import ListLoader from "@components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import SettingsPageDetails from "@components/Pages/PageDescription/SettingsPageDetails";
import { productsDetails } from "@common/Data/page-details-data";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "@common/query-request-helper";
import {
  createProduct,
  deleteProduct,
  getAllProducts,
  updateProduct,
} from "@services/products.service";
import { toast } from "sonner";
import { capitalizeFirstLetter } from "@common/text-helpers";
import InputRuleForm from "@components/InputRuleForm/form";
import { handleTextChange } from "@common/handleChange";
import FormDescription from "@components/custom/FormDescrption/FormDescription";
import SimpleReactValidator from "simple-react-validator";

const SettingsProducts = ({ props }: any) => {
  const navigate = useNavigate();
  const params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [list, setList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [request, setRequest] = useState<any>({
    name: "",
    description: "",
  });
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  const [editId, seteditId] = useState<any>("");

  /* -------------------------------------------------------------------------- */
  /*                              UseEffect Section                             */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getProducts(true);
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getProducts(true);
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getProducts = async (status: boolean) => {
    try {
      setIsLoading(status);
      const payload = {
        page_size: getValue(UrlParams, `page_size`, "")
          ? getValue(UrlParams, `page_size`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
      };
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getAllProducts(queryRequest);
      if (resp) {
        setList(getValue(resp, "data.products", []));
        // setLimit(getValue(resp, "data.pagination.page_size", 0));
        setPage_no(getValue(resp, "data.pagination.page", 0));
        setTotalCount(getValue(resp, "data.pagination.total", 0));
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("Failed to load products");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteModal = (id: string, name: string) => {
    Modal.confirm({
      title: `Are you sure you want to delete product - ${name}?`,
      content: "Once deleted, this product cannot be recovered.",
      okText: "Delete",
      cancelText: "Cancel",
      okButtonProps: {
        disabled: isLoading1,
      },
      onOk: async () => {
        setIsLoading1(true);
        try {
          await deleteProduct(id);
          toast.success("Product deleted successfully");
          getProducts(true);
          setIsLoading1(false);
        } catch (error) {
          toast.error("Error deleting product");
          setIsLoading1(false);
        }
      },
    });
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: any, limit: any) => {
    setPage_no(page_no);
    setLimit(limit);

    let payload = {
      ...UrlParams,
      page_no: page_no,
      page_size: limit,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
    setPage_no(page_no);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Other section                           */
  /* -------------------------------------------------------------------------- */

  const showModal = (id?: string, name?: string, description?: string) => {
    setIsModalOpen(true);
    seteditId(id);
    setRequest({
      name: name,
      description: description,
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    seteditId("");
    setRequest({
      name: "",
      description: "",
    });
  };

  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      toast.error(`Please fill all the mandatory fields`);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      setIsLoading(true);
      try {
        let payload = JSON.parse(JSON.stringify(request));
        const resp = editId
          ? await updateProduct(editId, payload)
          : await createProduct(payload);

        if (resp) {
          toast.success(`${editId ? "Updated" : "Created"} Successfully`);
          setRequest({
            name: "",
            description: "",
          });
          getProducts(true);
          setIsModalOpen(false);
        } else {
          toast.error(`Failed to ${editId ? "editing" : "creating"} products`);
        }
      } catch (error) {
        toast.error(`Error ${editId ? "editing" : "creating"} products`);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <CompanyProfileDetailsLayout {...props}>
      <CompanyProfileSubHeader
        settings
        icon="products"
        title="Products"
        showSubmit
        submitIcon={<Plus size={18} />}
        submitText="New Product"
        handleSubmit={() => showModal()}
      />
      <div className="sla-policy-wrapper">
        <div className="w-70 p-4 sla-policy-card-container">
          {isLoading ? (
            <ListLoader />
          ) : (
            <div className="d-grid gap-2">
              {list.map((listItem, index) => {
                const items: MenuProps["items"] = [
                  {
                    key: "1",
                    label: "Delete",
                    onClick: () =>
                      handleDeleteModal(
                        getValue(listItem, "id", ""),
                        getValue(listItem, "name", "")
                      ),
                  },
                ];

                return (
                  <div
                    key={index}
                    className="border p-4 rounded-3 products-list-card"
                  >
                    <div
                      className="cursor-pointer w-100"
                      onClick={() =>
                        showModal(
                          getValue(listItem, "id", ""),
                          getValue(listItem, "name", ""),
                          getValue(listItem, "description", "")
                        )
                      }
                    >
                      <h6 className="header_text__16 d-flex align-items-center gap-2 mb-2 ">
                        {capitalizeFirstLetter(getValue(listItem, "name", ""))}
                        {getValue(listItem, "is_default", "") && (
                          <Tooltip title="Default Product">
                            <LockKeyhole size={14} />
                          </Tooltip>
                        )}
                      </h6>
                      {getValue(listItem, "description", "") && (
                        <p className="small_text__14 pb-1">
                          {getValue(listItem, "description", "")}
                        </p>
                      )}
                      {getValue(listItem, "product_supports", "").map(
                        (product: any, i: number) => (
                          <div className="d-flex align-items-center gap-2 small_text__14">
                            <Mail size={14} />
                            {getValue(product, "is_primary", false) &&
                              getValue(product, "email", "")}
                          </div>
                        )
                      )}
                    </div>
                    {!getValue(listItem, "is_default", "") && (
                      <Dropdown menu={{ items }}>
                        <MoreVertical size={18} />
                      </Dropdown>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {totalCount > 10 && (
            <Pagination
              align="center"
              className="mt-4"
              total={totalCount}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              defaultPageSize={limit}
              defaultCurrent={page_no}
              onChange={handleChangePagination}
            />
          )}
        </div>
        <div className="w-30 p-4 sla-policy-right-details">
          <SettingsPageDetails data={productsDetails} />
        </div>
      </div>
      <Modal
        title={`${editId ? "Edit" : "New"} Product`}
        okText={`${editId ? "Update" : "Create"} Product`}
        open={isModalOpen}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: !getValue(request, "name", ""),
        }}
      >
        <InputRuleForm
          inputType="TEXT"
          name="name"
          value={getValue(request, "name", "")}
          placeholder="Enter Product Name"
          required
          label="Name (For Own Reference)"
          onChange={(e: any) => handleTextChange(e, request, setRequest)}
        />

        <FormDescription
          request={request}
          textClassname="mb-2 mt-3 w-50"
          inputClassname="mt-2 mb-2"
          input={
            <InputRuleForm
              inputType="TEXTAREA"
              name="description"
              value={getValue(request, `description`, "")}
              placeholder="Enter Description"
              label="Description"
              onChange={(e: any) => handleTextChange(e, request, setRequest)}
            />
          }
        />
      </Modal>
    </CompanyProfileDetailsLayout>
  );
};

export default SettingsProducts;
