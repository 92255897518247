import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getValue } from "@utils/lodash";
import { concatString } from "common/text-helpers";
import Popup from "reactjs-popup";
import { QueryRequestHelper } from "common/query-request-helper";
import { motion } from "framer-motion";
import DropdownCloseSvgComponent from "@assets/svg/dropdown-close";
import { getLookupAPIs } from "../../helpers/lookup-apis";
import DropdownOptions from "./components/dropdown-options";

const MultiLevelSearchToolTip = (props) => {
  const {
    label,
    id,
    value,
    onChange,
    width,
    selectKey,
    name,
    isLoading,
    disabled,
    mandatory,
    required,
    errorLabel,
    concatLength = 40,
    hideLabel,
    module_id,
    showAsButton,
    placeholder = "search here...",
    validator,
    lookup_api,
    forModule,
    arrow,
    editInline,
    closeInline,
    title,
    add,
    button_title,
    hideClose,
    form,
    name_key,
    dataSections,
    initialSelectedId,
  } = props;
  const ref = useRef();
  const inputRef = useRef(null);
  const popupRef = useRef(null);
  const selectedOptionRef = useRef(null);

  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [inputChange, setInputChange] = useState(false);
  const [dataInfo, setData] = useState([]);
  const [isLoadingInfo, setIsLoading] = useState(true);
  const [click, setClick] = useState(false);
  const [parentWidth, setParentWidth] = useState(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      popupRef.current?.close();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  useEffect(() => {
    if (lookup_api) {
      fetchDefaultData();
    }
  }, [lookup_api]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    setQuery("");
  }, [props?.resetInput]);

  useEffect(() => {
    if (query) {
      setInputChange(true);
    }
  }, [query]);

  useEffect(() => {
    if (selectedOptionRef.current) {
      selectedOptionRef.current.scrollIntoView({ behavior: "instant" });
      setClick(false);
    }
  }, [click]);

  const handleFetchData = async (search_text) => {
    if (!search_text) {
      onChange(null);
      return;
    }

    try {
      const payload = {
        search: search_text,
        page_no: 1,
        page_size: 10,
        forModule,
        module_id,
      };
      setIsLoading(true);
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getLookupAPIs(lookup_api, queryRequest);
      if (resp) {
        setData(resp);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDefaultData = async () => {
    try {
      const payload = {
        page_no: 1,
        page_size: 10,
        forModule,
        module_id,
      };
      setIsLoading(true);
      const queryRequest = QueryRequestHelper(payload);
      const resp = await getLookupAPIs(lookup_api, queryRequest);
      if (resp) {
        setData(resp);
      }
    } catch (error) {
      console.error("Error fetching default data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const filter = (options) => {
    if (!options?.length) return [];

    const queryLower = query?.toLowerCase();
    return options.filter((option) => {
      const labelValue = getValue(option, `[${label}]`, "")?.toLowerCase();
      const valueValue = getValue(option, "value", "")
        ?.toString()
        .toLowerCase();
      return (
        labelValue?.includes(queryLower) || valueValue?.includes(queryLower)
      );
    });
  };

  const findSelectedValue = (id) => {
    const allData = dataSections.flatMap((section) =>
      getValue(section, "data", [])
    );
    const selectedItem = allData.find(
      (item) => getValue(item, `[${selectKey || "id"}]`, "") == id
    );
    return getValue(selectedItem, "label", "");
  };

  const valueLabel = useMemo(() => findSelectedValue(value), [
    value,
    dataSections,
    dataInfo,
  ]);

  const handleClose = (e) => {
    e.stopPropagation();
    onChange(null);
    popupRef.current?.close();
    document.body.style.overflow = "auto";
  };

  const handleOpen = () => {
    popupRef.current?.open();
    setClick(true);
    document.body.style.overflow = "hidden";
  };

  const handleClickOption = (option, key) => {
    if (!disabled) {
      onChange(option, key);
    }
    setInputChange(false);
    setQuery("");
    popupRef.current?.close();
  };

  const renderDataSection = (section, index) => {
    const filteredData = filter(getValue(section, "data", []));
    return (
      <div key={index}>
        <h6 className="header_text__16 color_primary ms-3 mt-2">
          {getValue(section, "title", "")}
        </h6>
        {showAsButton && index === 0 ? (
          <button
            className="mx-3 mt-1 p-2 team-selection-button"
            onClick={() =>
              handleClickOption(
                getValue(section, "data[0]", {}),
                getValue(section, "key", "")
              )
            }
          >
            <div className="text-start">
              <h6 className="header_text__16 color_primary">
                {getValue(section, "title", "")}
              </h6>
              {getValue(section, "description", "")}
            </div>
            {initialSelectedId === findInitialSelectedItem(initialSelectedId)[selectKey] && (
              <img
                src="/dropdown/done.svg"
                className="done_image"
                width={16}
                height={16}
              />
            )}
          </button>
        ) : filteredData.length > 0 ? (
          filteredData.map((option, optionIndex) => (
            <DropdownOptions
              key={optionIndex}
              handleClickOption={(e) =>
                handleClickOption(e, getValue(section, "key", ""))
              }
              option={option}
              selectKey={selectKey}
              value={initialSelectedId}
              label={label}
              selectedOptionRef={selectedOptionRef}
              id={id}
              concatLength={concatLength}
              index={optionIndex}
            />
          ))
        ) : (
          <div className="nodata">No Data Found</div>
        )}
      </div>
    );
  };

  const measureRef = useCallback((node) => {
    if (node !== null) {
      setParentWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const findInitialSelectedItem = (id) => {
    const allData = dataSections.flatMap((section) =>
      getValue(section, "data", [])
    );
    let info = allData.find(
      (item) => getValue(item, `[${selectKey || "id"}]`, "") == id
    );
    if (info) {
      return info;
    } else {
      return {};
    }
  };

  // On component mount, try to find and set the initial selected item
  // useEffect(() => {
  //   if (initialSelectedId) {
  //     const initialItem = findInitialSelectedItem(initialSelectedId);
  //     if (initialItem) {
  //       onChange(initialItem, initialItem.key);
  //     }
  //   }
  // }, [initialSelectedId, dataSections]);

  const defaultKey = name_key?.split("_")[0];
  return (
    <div className="dropdown-select-form" ref={measureRef}>
      {!hideLabel && name && <label className="form-label">{name}</label>}

      <Popup
        trigger={(open) =>
          arrow ? (
            <div
              className={`d-flex align-items-center ${form ? "" : "px-3"} ${
                value && valueLabel && !editInline ? "inline_popup_active" : ""
              }`}
            >
              <p className="touchable-text header_text">
                {editInline
                  ? !valueLabel
                    ? `No ${defaultKey}`
                    : concatString(valueLabel, 20)
                  : concatString(title, 20)}
              </p>
              <img
                src={
                  open
                    ? "/dropdown/arrow_up_filter.svg"
                    : "/dropdown/arrow_down_filter.svg"
                }
                className="inline_dropdown_image"
              />
              {((!editInline && valueLabel) || closeInline) && value && (
                <div className="inline_close_image_container">
                  <img
                    src="/dropdown/close.svg"
                    className="inline_close_image"
                    onClick={handleClose}
                  />
                </div>
              )}
            </div>
          ) : (
            <div
              className={`popup-selected-value-box ${
                open && !disabled ? "active" : ""
              }`}
              style={{
                width,
                marginLeft: add ? "10px" : undefined,
                background: disabled ? "#F8F9F9" : undefined,
                borderLeft: required || mandatory ? "2px solid red" : undefined,
              }}
            >
              <div>
                {findInitialSelectedItem(initialSelectedId)['label'] ? (
                  <p className="value">
                    {concatString(
                      findInitialSelectedItem(initialSelectedId)['label'],
                      concatLength
                    )}
                  </p>
                ) : (
                  <p className="placeholder_text">{placeholder || "Select"}</p>
                )}
              </div>
              <div className="right_container">
                {valueLabel && !disabled && !hideClose && (
                  <div onClick={handleClose}>
                    <DropdownCloseSvgComponent size={18} />
                  </div>
                )}
                <div className="arrow_image_container">
                  <img
                    src={
                      open && !disabled
                        ? "/dropdown/arrow_up.svg"
                        : "/dropdown/arrow_down.svg"
                    }
                    className="arrow_image"
                  />
                </div>
              </div>
            </div>
          )
        }
        position="bottom center"
        ref={popupRef}
        contentStyle={{
          width: parentWidth || "280px",
          marginTop: "5px",
          padding: 0,
          zIndex: 9999,
          display: disabled ? "none" : undefined,
        }}
        onOpen={handleOpen}
        on="click"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        arrow={arrow || false}
      >
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -20, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div
            className={`dropdown-select-form options ${isOpen ? "open" : ""}`}
          >
            <div className="popup_search_container">
              <img src="/dropdown/search.svg" className="search_image" />
              <input
                className="search_input"
                ref={inputRef}
                autoFocus
                type="text"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                  handleFetchData(e.target.value);
                }}
                disabled={disabled}
                placeholder={placeholder}
                autoComplete="off"
              />
              {query && (
                <div className="input_close_image_container">
                  <img
                    src="/dropdown/close.svg"
                    className="input_close_image"
                    onClick={() => setQuery("")}
                  />
                </div>
              )}
            </div>

            <div className="popup_option_container">
              {dataSections.map((section, index) =>
                renderDataSection(section, index)
              )}
            </div>

            {add && (
              <div
                className="button_container touchable-text"
                onClick={(e) => {
                  handleClose(e);
                  props.handleOpenPopup?.();
                }}
              >
                <img src="/dropdown/add.svg" className="button_image" />
                <p className="button_input">{button_title}</p>
              </div>
            )}
          </div>
        </motion.div>
      </Popup>

      {validator && (
        <p className="form-error">
          {validator.current.message(errorLabel || name, value, "required")}
        </p>
      )}
    </div>
  );
};

export default MultiLevelSearchToolTip;
