import { Input } from "antd";
import { ChevronDown, ChevronUp, Search } from "lucide-react";
import { motion } from "framer-motion";
import "./LeftSideBar.scss";
import { useState } from "react";
import { getValue } from "@utils/lodash";
import { useStateContext } from "@context/profileProvider";

const TicketsLeftSideBar = (props: any) => {
  const {
    handleNaviagteView,
    permissions,
    ticketDetailsPage,
  } = props;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const [searchText, setSearchText] = useState("");
  const [openAccordion, setOpenAccordion] = useState(true);
  const toggleAccordion = () => {
    setOpenAccordion(!openAccordion);
  };

  const tabs = [
    {
      id: "all_tickets",
      label: "All Tickets",
    },
    {
      id: "my_open_and_pending_tickets",
      label: "My Tickets",
    },
    {
      id: "new_and_my_open_tickets",
      label: "New and My Open Tickets",
    },
    {
      id: "all_unresolved_tickets",
      label: "All Unresolved Tickets",
    },
    {
      id: "my_overdue_tickets",
      label: "My Overdue Tickets",
    },
    {
      id: "urgent_and_high_priority_tickets",
      label: "urgent and High Priority Tickets",
    },
    {
      id: "open_tickets_in_my_groups",
      label: "Open Tickets in My Groups",
    },

    {
      id: "tickets_i_raised",
      label: "Tickets I Raised",
    },
    {
      id: "tickets_im_watching",
      label: "Tickets I'm Watching",
    },
    {
      id: "spam",
      label: "Spam",
    },
    {
      id: "trash",
      label: "Trash",
    },
  ];

  const filteredTabs = tabs.filter((tab) =>
    getValue(tab, "label", "").toLowerCase().includes(searchText.toLowerCase())
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <div
      className={`${
        ticketDetailsPage
          ? "tickets-details-left-sidebar-wrapper"
          : "tickets-left-sidebar-wrapper"
      }`}
    >
      <div className="p-3 position-sticky top-0 bg-white w-100 border-bottom">
        <Input
          placeholder="Search for a view"
          className="search-ticket-view"
          value={searchText}
          onChange={handleSearch}
          prefix={<Search size={16} color="#717171" />}
        />
      </div>
      <div className="px-3 mt-3">
        <div
          className="d-flex align-items-center justify-content-between cursor-pointer "
          onClick={toggleAccordion}
        >
          <span className="header_text__16">Default</span>
          <motion.div
            animate={{ rotate: openAccordion ? 0 : 180 }}
            transition={{ duration: 0.3 }}
          >
            {openAccordion ? (
              <ChevronDown size={16} />
            ) : (
              <ChevronUp size={16} />
            )}
          </motion.div>
        </div>
        {openAccordion && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
            className="d-grid gap-1 my-3"
          >
            {filteredTabs.map((item, index) => {
              if (getValue(item, "key", "") === "view_all") {
                if (!permissions.includes("view_all")) return null;
              }
              const isSelected =
                (index === 0 &&
                  getValue(item, "id", "") === "all_tickets" &&
                  !getValue(params, "filter_status", "")) ||
                getValue(item, "id", "") ===
                  getValue(params, "filter_status", "");
              return (
                <div
                  key={index}
                  onClick={() => handleNaviagteView("filter_status", item.id)}
                  className={`${
                    isSelected
                      ? "ticket-sidebar-tab--selected"
                      : "ticket-sidebar-tab"
                  } d-flex justify-content-between align-items-center gap-3`}
                >
                  <p>{getValue(item, "label", "")}</p>
                  {/* <p>{totalCount}</p> */}
                </div>
              );
            })}
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default TicketsLeftSideBar;
