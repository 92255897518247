import Loader from "@components/common/Loader/loading";
import BotBuilderStepOne from "@pages/Private/Bots/components/bot-builder-step-1";
import BotBuilderStepTwo from "@pages/Private/Bots/components/bot-builder-step-2";
import BotBuilderStepThree from "@pages/Private/Bots/components/bot-builder-step-3";
import { geChatbotAvatar } from "@services/fb.service";
import { chatbotAvatarmediaUpload } from "@services/upload.service";
import { getValue } from "@utils/lodash";
import { useEffect, useState } from "react";
import SettingsChatInterface from ".";
import ChatView from "./chat-view";

function WidgetInterface(props: any) {
  const { request, setRequest, isLoading } = props;

  useEffect(() => {
    getAvatarList();
  }, []);
  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [avatarList, setAvatarlist] = useState([]);
  const getAvatarList = async () => {
    try {
      let resp = await geChatbotAvatar("");
      if (resp) {
        console.log(resp);
        setAvatarlist(getValue(resp, `data`, []));
      }
    } catch (error) {}
  };

  /* -------------------------------------------------------------------------- */
  /*                           Upload Avatar section                            */
  /* -------------------------------------------------------------------------- */

  const [uploadLoading, setUploadLoading] = useState(false);
  const handleUploadDocuments = async (e: any) => {
    Array.from(e.target.files).forEach(async (item: any, index: number) => {
      let formData = new FormData();
      formData.append("file", item);
      formData.append("filename", item.name);
      formData.append("is_public", "true");
      formData.append("collection", "public-images");
      try {
        setUploadLoading(true);
        let resp = await chatbotAvatarmediaUpload(formData);
        if (resp) {
          getAvatarList();
        } else {
          e = null;
          setUploadLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadLoading(false);
      }
    });
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="rounded-3 p-4">
      <SettingsChatInterface request={request} setRequest={setRequest} />
      {/* <BotBuilderStepOne request={request} setRequest={setRequest} edit /> */}
      <BotBuilderStepTwo request={request} setRequest={setRequest} />
      <BotBuilderStepThree
        request={request}
        setRequest={setRequest}
        avatarList={avatarList}
        handleUploadDocuments={handleUploadDocuments}
        hideNotification
      />
      <div className="mt-5">
        <ChatView request={request} setRequest={setRequest} />
      </div>
    </div>
  );
}

export default WidgetInterface;
