import { useState } from "react";
import { Trash, Plus } from "lucide-react";
import "./index.scss";

export default function SourceQA(props: any) {
  const { request, setRequest } = props;

  const addNewPair = () => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      qna: [
        ...prevRequest.qna,
        {
          question: "",
          answer: "",
        },
      ],
    }));
  };

  const deletePair = (index: number) => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      qna: prevRequest.qna.filter((_: any, i: number) => i !== index),
    }));
  };

  const deleteAll = () => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      qna: [
        {
          question: "",
          answer: "",
        },
      ],
    }));
  };

  const updatePair = (
    index: number,
    field: "question" | "answer",
    value: string
  ) => {
    setRequest((prevRequest: any) => ({
      ...prevRequest,
      qna: prevRequest.qna.map((pair: any, i: number) =>
        i === index ? { ...pair, [field]: value } : pair
      ),
    }));
  };

  return (
    <div className="qa-container">
      <div className="qa-header">
        <div></div>
        <div className="qa-actions">
          <button className="add-new" onClick={addNewPair}>
            <Plus />
          </button>
        </div>
      </div>

      {request.qna.map((pair: any, index: number) => (
        <div key={index} className="qa-pair">
          <div className="input-group">
            <div className="label-row d-flex align-items-center justify-content-between">
              <label>Question</label>
              <button
                className="delete-pair"
                onClick={() => deletePair(index)}
              >
                <Trash className="trash-icon" />
              </button>
            </div>
            <textarea
              value={pair.question}
              onChange={(e) => updatePair(index, "question", e.target.value)}
              rows={4}
            />
          </div>

          <div className="input-group">
            <label>Answer</label>
            <textarea
              value={pair.answer}
              onChange={(e) => updatePair(index, "answer", e.target.value)}
              rows={6}
            />
          </div>
        </div>
      ))}
    </div>
  );
}