import ContentEditor from "./Articles/ContentEditor";
import { Empty } from "antd";

const CategoryDetailsRightSide = (props: any) => {
  return (
    <div className="w-100">
      {Object.keys(props.articleDetails).length > 0 ? (
        <>
          <ContentEditor {...props} />
        </>
      ) : (
        <Empty
          description="Selected an article to continue"
          className="rounded-2 px-4 py-2 mt-5"
        />
      )}
    </div>
  );
};

export default CategoryDetailsRightSide;
