import { useEffect, useState } from "react";
import "./index.scss";
import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { dateFilters } from "@common/Date-Helpers/date-filters-helpers";
import { getValue } from "@utils/lodash";
import MultiSearchLookupTooltip from "@components/custom/Dropdown/MultiSearchLookupTooltip";
import { handleUpdateArray } from "@common/handleChange";
import MultiSearchTooltip from "@components/custom/Dropdown/MultiSearchTooltip";
import { Button } from "antd";
import { plural } from "pluralize";
import { getUrlParams } from "../helper";
import FilterDropdown from "./filter-dropdown";


function TicketsRightSection(props: any) {
  const {
    request,
    setRequest,
    dropdownFilterOptions,
    handleApplyFilter,
    resetRequest,
    filterMode,
    setFilterMode,
  } = props;

  const [changed, setChanged] = useState(true);

  // New useEffect to sync filterMode with request
  useEffect(() => {
    if (changed) {
      const newFilterMode = { ...filterMode };
      let hasUpdates = false;

      const request = getUrlParams();
      // Helper function to check arrays and determine mode
      const determineMode = (
        includeKey: string,
        notIncludeKey: string
      ): any => {
        const includeArray = request[includeKey] || [];
        const notIncludeArray = request[notIncludeKey] || [];
        // If notInclude array has values, set to not_include regardless of include array
        if (notIncludeArray.length > 0) {
          return "not_include";
        }
        // If include array has values, maintain include mode
        if (includeArray.length > 0) {
          return "include";
        }
        // If neither array has values, maintain current mode
        return filterMode[getBaseKey(includeKey)];
      };

      // Helper to get base key from include/not_include key
      const getBaseKey = (key: string): string => {
        return key
          .replace("_ids_include", "")
          .replace("_ids_not_include", "")
          .replace("_include", "")
          .replace("_not_include", "");
      };

      // List of all filter keys to check
      const filterKeys = [
        "owner",
        "contact",
        "company",
        "group",
        "priority",
        "product",
        "status",
        "segments",
        "internal_types",
        "resolutions",
        "sources",
        "categories",
      ];

      // Check each filter key
      filterKeys.forEach((key) => {
        const currentMode = filterMode[key];
        const useIds = [
          "owner",
          "contact",
          "company",
          "group",
          "priority",
          "product",
        ].includes(key);
        const includeKey = useIds ? `${key}_ids_include` : `${key}_include`;
        const notIncludeKey = useIds
          ? `${key}_ids_not_include`
          : `${key}_not_include`;

        const newMode = determineMode(includeKey, notIncludeKey);
        if (currentMode !== newMode) {
          newFilterMode[key] = newMode;
          hasUpdates = true;
        }
      });

      // Only update state if there were changes
      if (hasUpdates) {
        setFilterMode(newFilterMode);
      }
      setChanged(false);
    }
  }, [request, changed, filterMode]);

  /* -------------------------------------------------------------------------- */
  /*                               Filter Section                               */
  /* -------------------------------------------------------------------------- */

  const handleFilterChange = (value: any, fieldName: string) => {
    setRequest((prev: any) => ({
      ...prev,
      [fieldName]: handleUpdateArray(
        getValue(value, `id`, ""),
        getValue(request, `${fieldName}`, [])
      ),
    }));
  };
  const toggleFilterMode = (key: string) => {
    const oppositeMode =
      filterMode[key] === "include" ? "not_include" : "include";
    const mainMode = filterMode[key] !== "include" ? "not_include" : "include";
    const fieldToClear = `${key}_ids_${mainMode}`;

    setFilterMode((prev: any) => ({
      ...prev,
      [key]: oppositeMode,
    }));
    // Clear values in the opposite field
    setRequest((prev: any) => ({
      ...prev,
      [fieldToClear]: [],
    }));
  };
  const renderFilter = (key: string, label: string, lookupApi: string) => {
    const mode = filterMode[key];
    const fieldName = `${key}_ids_${mode}`;

    return (
      <div className="px-3 mt-2">
        <FilterDropdown
          label={label}
          mode={filterMode[key]}
          onToggle={() => toggleFilterMode(key)}
        />
        <MultiSearchLookupTooltip
          lookup_api={lookupApi}
          label={`label`}
          value={getValue(request, fieldName, [])}
          selectKey={"id"}
          // name={`${label} (${mode === "include" ? "Include" : "Not Include"})`}
          onChange={(value: any) => handleFilterChange(value, fieldName)}
        />
      </div>
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                          Dynamic Filter section                            */
  /* -------------------------------------------------------------------------- */
  const handleDynamicFilterChange = (value: any, fieldName: string) => {
    setRequest((prev: any) => ({
      ...prev,
      [fieldName]: handleUpdateArray(
        getValue(value, `value`, ""),
        getValue(request, `${fieldName}`, [])
      ),
    }));
  };

  const renderAllFilters = () =>
    Object.keys(dropdownFilterOptions).map((key) =>
      renderDynamicFilter(
        key === "status" || key === "priority" ? key : plural(key),
        key.charAt(0).toUpperCase() + key.slice(1),
        dropdownFilterOptions[key]
      )
    );

  const toggleDynamicFilterMode = (key: string) => {
    const oppositeMode =
      filterMode[key] === "include" ? "not_include" : "include";
    const mainMode = filterMode[key] !== "include" ? "not_include" : "include";
    const fieldToClear = `${key}_${mainMode}`;

    setFilterMode((prev: any) => ({
      ...prev,
      [key]: oppositeMode,
    }));
    // Clear values in the opposite field
    setRequest((prev: any) => ({
      ...prev,
      [fieldToClear]: [],
    }));
  };
  const renderDynamicFilter = (key: string, label: string, data: any) => {
    const mode = filterMode[key];
    const fieldName = `${key}_${mode}`;
    return (
      <div className="px-3 mt-2">
        {/* <label className="form-label d-flex align-items-center">
          {label} ({mode === "include" ? "Include" : "Not Include"})
          <div
            className="cursor-pointer"
            onClick={() => toggleDynamicFilterMode(key)}
          >
            <ChevronDown />
          </div>
        </label> */}
        <FilterDropdown
          label={label}
          mode={filterMode[key]}
          onToggle={() => toggleDynamicFilterMode(key)}
        />
        <MultiSearchTooltip
          data={data}
          label={`label`}
          value={getValue(request, fieldName, [])}
          selectKey={"value"}
          // name={`${label} (${mode === "include" ? "Include" : "Not Include"})`}
          onChange={(value: any) => handleDynamicFilterChange(value, fieldName)}
        />
      </div>
    );
  };

  const dateFilterKeys = [
    { key: "created_at", label: "Created Date" },
    { key: "close_date", label: "Close Date" },
    { key: "resolved_date", label: "Resolved Date" },
    { key: "due_by", label: "Due By" },
    { key: "fr_due_by", label: "First Response Due By" },
    { key: "er_due_by", label: "Next Response Due By" },
  ];

  const renderDateFilters = () =>
    dateFilterKeys.map(({ key, label }) => (
      <div className="px-3 mt-2 w-100" key={key}>
        <SearchToolTip
          data={dateFilters} // Assuming `dateFilters` is an array of date filter options
          label={`label`}
          name={label}
          selectKey={"value"}
          value={getValue(request, `filter_${key}`, "")}
          onChange={(value: any) =>
            setRequest((prev: any) => ({
              ...prev,
              [`filter_${key}`]: getValue(value, `value`, ""),
            }))
          }
        />
      </div>
    ));

  return (
    <div className="tickets-right-sidebar-wrapper">
      <div className="p-3 position-sticky top-0 bg-white w-100 border-bottom z-3 d-flex align-items-center justify-content-between">
        <h6 className="header_text__16">Filter</h6>
        <div className="d-flex align-items-center gap-1">
          <Button onClick={resetRequest}>Reset</Button>
          <Button onClick={handleApplyFilter}>Apply</Button>
        </div>
      </div>

      {/* Date Filters */}
      {renderDateFilters()}

      {/* Include/Not Include Filters */}
      {renderFilter("owner", "Owner", "org_users")}
      {renderFilter("contact", "Contact", "contacts")}
      {renderFilter("company", "Company", "companies")}
      {renderFilter("group", "Group", "groups")}
      {renderFilter("priority", "Priority", "priorities")}
      {renderFilter("product", "Product", "products")}

      {/* Include/Not Include Formdata Fileds */}
      {renderAllFilters()}
    </div>
  );
}

export default TicketsRightSection;
