import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { getValue } from "@utils/lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { QueryRequestHelper } from "common/query-request-helper";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "sonner";
import { handleNavigateURL } from "common/url-navigate";
import { allPipelines } from "@services/pipeline.service";
import {
  removeDuplicates,
  removeNullOrUndefinedProperties,
} from "common/text-helpers";
import {
  TicketsBulkUpdate,
  deleteTicket,
  getAllKanbanTickets,
  getAllTickets,
} from "@services/tickets.service";
import { useStateContext } from "context/profileProvider";
import { sortTaskFieldList } from "@components/Pages/Pipeline/helpers/create-pipeline-helper";
import { commonCustomFilter } from "@services/smart-filter.service";
import { listAllModules } from "@services/modules.service";
import useDynamicTitle from "@context/useDynamicTitle";
import MainLayout from "@layouts/HomeLayout/NewLayout";
import TicketSubHeader from "@components/Pages/Tickets/SubHeader";
import "../tickets.scss";
import {
  getAllModuleFields,
  getModuleColumns,
} from "@services/module-fields.service";
import TicketKanbanView from "./components/TicketKanbanView";

const TicketsKanban = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const UrlParams = Object.fromEntries(urlSearchParams.entries());
  const { userId, getUserIDCallback, selectedModuleId, allModules } =
    useStateContext();

  // const selectedModuleId = async () => {
  //   let resp = await listAllModules("");
  //   let moduleInfo = getValue(resp, `data`, []).find(
  //     (item: object) => getValue(item, `api_name`, "") === "tickets"
  //   );
  //   return getValue(moduleInfo, `id`, "");
  // };
  /* -------------------------------------------------------------------------- */
  /*                               Validation Section                           */
  /* -------------------------------------------------------------------------- */
  const simpleValidator1 = useRef(new SimpleReactValidator());
  const [, forceUpdate1] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    getAllModules();
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      getUserIDCallback();
      if (getValue(UrlParams, `search`, "")) {
        setSearchInput(UrlParams.search);
        setShowSearchTextbox(true);
      }
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getAllPipelines();
    }
  }, []);
  //   window.location.href,
  //   getValue(localStorage, `sf:tickets`, ""),
  //   getValue(localStorage, `s:tickets`, ""),
  // ]);

  const [moduleId, setModuleId] = useState("");
  const getAllModules = async () => {
    try {
      let resp = await listAllModules("");
      if (resp) {
        getTickets();
        let task_modules = getValue(resp, `data`, []).find(
          (item: object) => getValue(item, `api_name`, "") === "tickets"
        );
        setModuleId(getValue(task_modules, `id`, ""));
        let payload = {
          module_id: getValue(task_modules, `id`, ""),
        };
        let columnsList = await getModuleColumns(
          getValue(task_modules, `id`, "")
        );
        setHeaders(getValue(columnsList, `data`, []));

        let queryRequest = QueryRequestHelper(payload);
        let fields = await getAllModuleFields(getValue(task_modules, `id`, ""));
        if (fields) {
          let list = sortTaskFieldList(getValue(fields, `data`, []));
          for (const item of list) {
            if (getValue(item, `input_type`, "") !== "select_multiple") {
              if (
                typeof getValue(item, `default_value`, null) === "string" ||
                typeof getValue(item, `default_value`, null) === "boolean"
              ) {
                item.value = getValue(item, `default_value`, null);
              }
            } else {
              item.value = [getValue(item, `default_value`, null)];
            }
            if (item.api_name === "owner_id") {
              item.value = userId;
            }
          }
          const aDict = list.reduce((dict: any, item: any) => {
            dict[item.name] = item;
            return dict;
          }, {});
          const ReorderedList: any = [];
          // Add elements from b in the order they appear in a
          for (const name of getValue(fields, `data`, [])) {
            if (aDict[name]) {
              ReorderedList.push(aDict[name]);
            }
          }
          // Add elements from a that are not in b to the end
          for (const item of list) {
            if (!getValue(fields, `data`, []).includes(item.name)) {
              ReorderedList.push(item);
            }
          }
          setSelectedFields(removeDuplicates(ReorderedList));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getTickets = async () => {
    try {
      let payload = {
        filter_owner_id: getValue(UrlParams, `filter_owner_id`, ""),
        module_id: await selectedModuleId(),
        filter_pipeline_stage_id: getValue(
          UrlParams,
          `filter_pipeline_stage_id`,
          ""
        ),
        filter_closing_date: getValue(UrlParams, `filter_closing_date`, ""),
        filter_created_at: getValue(UrlParams, `filter_created_at`, ""),
        page_size: getValue(UrlParams, `limit`, "")
          ? getValue(UrlParams, `limit`, "")
          : limit,
        page_no: getValue(UrlParams, `page_no`, "")
          ? getValue(UrlParams, `page_no`, "")
          : page_no,
        search: getValue(UrlParams, `search`, ""),
      };
      let queryRequest = QueryRequestHelper(payload);
      setListLoading(true);
      let resp = await getAllKanbanTickets(queryRequest);
      if (resp) {
        setList(getValue(resp, `data`, []));
        setTotalCount(getValue(resp, `data.pagination.total`, 0));
        setListLoading(false);
      } else {
        setList([]);
        setListLoading(false);
      }
    } catch (error) {
      setList([]);
      setListLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(UrlParams).length === 0) {
      getAllPipelines();
      getUserIDCallback();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(UrlParams).length > 0) {
      getUserIDCallback();
      if (getValue(UrlParams, `search`, "")) {
        setSearchInput(UrlParams.search);
        setShowSearchTextbox(true);
      }
      if (getValue(UrlParams, `filter_view_id`, "")) {
        // setSelectedFilterView(UrlParams.filter_view_id);
      }
      if (UrlParams.page_no) {
        setPage_no(parseInt(UrlParams.page_no));
      }
      if (UrlParams.limit) {
        setLimit(parseInt(UrlParams.limit));
      }
      getAllModules();
      // getAllPipelines();
    }
  }, [window.location.href]);

  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const [staticFieldRequest, setStaticFieldRequest] = React.useState({
    pipeline_id: "",
    stage: "",
  });
  const [selectedIds, setSelectedIds] = useState([]);
  /**
   *
   * Get all Module pipelines
   * @requires
   *
   */

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const getAllPipelines = async () => {
    try {
      setIsLoading(true);
      let payload = {
        module_name: "tickets",
      };
      let queryRequest = QueryRequestHelper(payload);
      let resp = await allPipelines(await selectedModuleId(), queryRequest);
      if (resp) {
        // setPipelineList(
        //   getValue(resp, `data`, []).map((item: object) => ({
        //     id: getValue(item, `id`, ""),
        //     module_name: getValue(item, `module_name`, ""),
        //     module_id: getValue(item, `module_id`, ""),
        //     name: getValue(item, `name`, ""),
        //     label: getValue(item, `name`, ""),
        //     value: getValue(item, `id`, ""),
        //     org_id: getValue(item, `org_id`, ""),
        //   }))
        // );
        let list =
          getValue(resp, `data.length`, 0) > 0
            ? getValue(resp, `data`, []).map((item: object) => ({
                ...item,
                value: getValue(item, `api_name`, ""),
                label: getValue(item, `api_name`, ""),
              }))
            : [];
        //**----------------------- creating pipeline options---------------- */
        setOptions(list);
        //**----------------------- Status & filterview section ---------------- */
        if (getValue(UrlParams, `pipeline`, "")) {
          setStaticFieldRequest((prevStaticFieldRequest) => ({
            ...prevStaticFieldRequest,
            pipeline_id: getValue(UrlParams, "pipeline", ""),
          }));
          let defaultFilterView =
            getValue(resp, `data.length`, 0) > 0 &&
            getValue(resp, `data`, []).filter(
              (item: any) =>
                getValue(UrlParams, `pipeline`, "") === getValue(item, `id`, "")
            );
          // getFilterViews(
          //   getValue(UrlParams, `pipeline`, ""),
          //   getValue(defaultFilterView, `[${0}].plural_label`, "")
          // );
        } else {
          setStaticFieldRequest((prevStaticFieldRequest) => ({
            ...prevStaticFieldRequest,
            pipeline_id: getValue(list, `[${0}].id`, ""),
          }));
          // getFilterViews(
          //   getValue(list, `[${0}].id`, {}),
          //   getValue(list, `[${0}].plural_label`, {})
          // );
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setListLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setListLoading(false);
    }
  };

  /**
   *
   * Get selected pipeline list
   * @requires id
   *
   */

  const [listLoading, setListLoading] = useState(true);
  const [list, setList] = useState([]);
  const getListData = async (id: string, status: boolean) => {
    if (typeof id === "string") {
      try {
        let payload = {
          pipeline_id: id,
          // module_id: await selectedModuleId(),
          filter_owner_id: getValue(UrlParams, `filter_owner_id`, ""),
          filter_pipeline_stage_id: getValue(
            UrlParams,
            `filter_pipeline_stage_id`,
            ""
          ),
          filter_closing_date: getValue(UrlParams, `filter_closing_date`, ""),
          filter_created_at: getValue(UrlParams, `filter_created_at`, ""),
          page_size: getValue(UrlParams, `limit`, "")
            ? getValue(UrlParams, `limit`, "")
            : limit,
          page_no: getValue(UrlParams, `page_no`, "")
            ? getValue(UrlParams, `page_no`, "")
            : page_no,
          search: getValue(UrlParams, `search`, ""),
        };
        let queryRequest = QueryRequestHelper(payload);
        setListLoading(status);
        let obj = {
          has_advance_filter: getValue(localStorage, `sf:tickets`, "")
            ? true
            : false,
          has_group_by: false,
          filter_groups: getValue(localStorage, `sf:tickets`, "")
            ? JSON.parse(getValue(localStorage, `sf:tickets`, ""))
            : null,
          sort_by: getValue(localStorage, `s:tickets`, "")
            ? {
                ...JSON.parse(getValue(localStorage, `s:tickets`, "")),
                custom: false,
              }
            : {
                custom: false,
                field: "created_at",
                value: "desc",
              },
        };
        let resp = await commonCustomFilter(
          "tickets",
          queryRequest,
          removeNullOrUndefinedProperties(obj)
        );
        if (resp) {
          setList(
            getValue(resp, `data.data.tickets`, []).map((item: object) => ({
              ...item,
              stage: getValue(item, `stage`, ""),
            }))
          );
          setTotalCount(getValue(resp, `data.data.pagination.total`, 0));
          setListLoading(false);
        } else {
          setList([]);
          setListLoading(false);
        }
      } catch (error) {
        setList([]);
        setListLoading(false);
      }
    }
  };

  /**
   *
   * Create Sale Pipeline
   * @requires body
   *
   */

  const handleChangePipeline = (e: any) => {
    delete UrlParams.pipeline;
    let payload = {
      ...UrlParams,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleClickPipeline = (option: any) => {
    delete UrlParams.filter_pipeline_stage_id;
    let payload = {
      ...UrlParams,
      pipeline: getValue(option, `id`, ""),
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                     Helper Functions Section                               */
  /* -------------------------------------------------------------------------- */

  const findSelectedPipeline = (id: string) => {
    let list =
      getValue(options, `length`, 0) > 0
        ? options.filter((item: object) => getValue(item, `id`, "") === id)
        : [];
    return getValue(list, `length`, 0) > 0 ? getValue(list, `[${0}]`, "") : {};
  };
  const getSelectedPipeline = useMemo(
    () => findSelectedPipeline(getValue(staticFieldRequest, `pipeline_id`, "")),
    [getValue(staticFieldRequest, `pipeline_id`, "")]
  );

  /* -------------------------------------------------------------------------- */
  /*                     Other Functions Section                                */
  /* -------------------------------------------------------------------------- */

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const filterNavigationArray: any = [];
  const subMenuArray: any = [];

  /* -------------------------------------------------------------------------- */
  /*                                  Edit section                              */
  /* -------------------------------------------------------------------------- */
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const openEditPopup = () => {
    setIsOpenEditPopup(!isOpenEditPopup);
  };
  const [selectedEditOption, setSelectedEditOption] = useState("");
  const [editRequest, setEditRequest] = useState<any>({
    stage: "",
    owner_id: "",
  });
  const [editLoading, setEditLoading] = useState(false);
  const handleOpenFilter = (value: string) => {
    setSelectedEditOption(value);
    openEditPopup();
  };
  const handleSubmitEdit = async () => {
    try {
      setEditLoading(true);
      let resp = await TicketsBulkUpdate({
        ids: selectedIds,
        data: {
          [selectedEditOption === "stage_id"
            ? "pipeline_stage_id"
            : selectedEditOption]: editRequest[selectedEditOption],
        },
      });
      if (resp) {
        toast.success("Updated Succssfully");
        openEditPopup();
        getListData(getValue(staticFieldRequest, `pipeline_id`, ""), false);
        setSelectedEditOption("");
        setEditRequest({
          ...editRequest,
          stage: "",
          owner_id: "",
        });
        setSelectedIds([]);
        setEditLoading(false);
      } else {
        setEditLoading(false);
      }
    } catch (error) {
      setEditLoading(false);
    }
  };

  /* ----------------------------- Delete View Section  ------------------------- */
  const [deleteId1, setDeleteId1] = useState("");
  const [deleteTableValue, setDeleteTableValue] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);
  const handleModal1 = () => {
    setIsOpen1(!isOpen1);
  };
  const handleDeleteTable = (id: string) => {
    setDeleteId1(id);
    handleModal1();
  };
  const handleDeleteTableRow = async () => {
    try {
      let resp = await deleteTicket(deleteId1);
      if (resp) {
        toast.success("Deleted Successfully");
        //delete
        getListData(getValue(staticFieldRequest, `pipeline_id`, ""), false);
        setSelectedIds([]);
        handleModal1();
      }
    } catch (error) {}
  };
  /* -------------------------------------------------------------------------- */
  /*                          Create Pipline Section                            */
  /* -------------------------------------------------------------------------- */
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);

  /* -------------------------------------------------------------------------- */
  /*                       Create SubPipline Section                            */
  /* -------------------------------------------------------------------------- */

  const [isOpenSubPipeline, setIsOpenSubPipeline] = useState(false);
  const toggleOpenSubpipeline = () => {
    setIsOpenSubPipeline(!isOpenSubPipeline);
  };
  const [selectedFields, setSelectedFields] = useState([]);
  const [headers, setHeaders] = useState<any>([]);
  // const getData = async (pipeline_id: string) => {
  //   if (typeof pipeline_id === "string") {
  //     try {
  //       setOptionLoading(true);
  //       let resp = await getSpecificPipeline(
  //         await selectedModuleId(),
  //         pipeline_id
  //       );
  //       if (resp) {
  //         setSelectedOption(getValue(resp, `data`, {}));
  //         let list = sortPipelineFieldList(
  //           getValue(resp, `data.form_fields`, [])
  //         );
  //         for (const item of list) {
  //           if (getValue(item, `input_type`, "") !== "select_multiple") {
  //             if (
  //               typeof getValue(item, `default_value`, null) === "string" ||
  //               typeof getValue(item, `default_value`, null) === "boolean"
  //             ) {
  //               item.value = getValue(item, `default_value`, null);
  //             }
  //           } else {
  //             item.value = [getValue(item, `default_value`, null)];
  //           }
  //           if (item.api_name === "owner_id") {
  //             item.value = userId;
  //           }
  //         }
  //         const aDict = list.reduce((dict: any, item: any) => {
  //           dict[item.name] = item;
  //           return dict;
  //         }, {});
  //         //**----------- Creating Static Fields for table --------------- */
  //         let selected = [];
  //         if ((await selectedModuleId()) && pipeline_id) {
  //           let columnsList = await getSpecificPipelineColumns(
  //             await selectedModuleId(),
  //             pipeline_id
  //           );
  //           selected =
  //             getValue(columnsList, `data.length`, 0) > 0
  //               ? getValue(columnsList, `data`, []).filter((item: object) =>
  //                   getValue(item, `is_selected`, "")
  //                 )
  //               : [];
  //           setHeaders(getValue(columnsList, `data`, []));
  //         }
  //         const ReorderedList: any = [];
  //         // Add elements from b in the order they appear in a
  //         for (const name of selected) {
  //           if (aDict[name]) {
  //             ReorderedList.push(aDict[name]);
  //           }
  //         }
  //         // Add elements from a that are not in b to the end
  //         for (const item of list) {
  //           if (!selected.includes(item.name)) {
  //             ReorderedList.push(item);
  //           }
  //         }
  //         setSelectedFields(removeDuplicates(ReorderedList));
  //         let formList =
  //           getValue(list, `length`, 0) > 0
  //             ? list.filter((item: object) =>
  //                 getValue(item, `form_default`, false)
  //               )
  //             : [];
  //         //**------------ creating stage object -------------------- */
  //         let filteredStages = getValue(resp, `data.stages`, []).filter(
  //           (item: object) => !getValue(item, `archived`, false)
  //         );
  //         setStaticFieldRequest((prevStaticFieldRequest) => ({
  //           ...prevStaticFieldRequest,
  //           stage: getValue(filteredStages, `[${0}].id`, ""),
  //         }));
  //         setOptionLoading(false);
  //       } else {
  //         setOptionLoading(false);
  //       }
  //     } catch (error) {
  //       setOptionLoading(false);
  //     }
  //   }
  // };

  /* -------------------------------------------------------------------------- */
  /*                  Hide Body Scroll When Modal Open                          */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    if (isOpenSubPipeline) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpenSubPipeline]);

  /* -------------------------------------------------------------------------- */
  /*                       Create Filterview Section                            */
  /* -------------------------------------------------------------------------- */
  const [editFieldsOpen, setEditFieldsOpen] = useState(false);
  const handleEditFieldsModal = () => {
    setEditFieldsOpen(!editFieldsOpen);
  };
  const handleNaviagteView = (name: string, value: string) => {
    // if (!value) {
    //   getAllPipelines();
    // }
    navigate(handleNavigateURL(name, value));
  };
  /* -------------------------------------------------------------------------- */
  /*                       Advanced Filterview Sectio                           */
  /* -------------------------------------------------------------------------- */
  const [isOpenFilterView, setIsOpenFilterView] = useState(false);
  const handleOpenFilterView = () => {
    setIsOpenFilterView(!isOpenFilterView);
  };

  /* -------------------------------------------------------------------------- */
  /*                               Search section                               */
  /* -------------------------------------------------------------------------- */
  const [showSearchTextbox, setShowSearchTextbox] = useState(false);
  const [search, setSearchInput] = useState<any>("");

  const toggleSearch = () => {
    setShowSearchTextbox((prevState) => !prevState);
  };
  const toggleSearchClose = () => {
    setSearchInput("");
    toggleSearch();
    if (UrlParams.search) {
      delete UrlParams.search;
      let payload: any = { ...UrlParams };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
      // getData(getValue(staticFieldRequest, `pipeline_id`, ""));
    }
  };
  const handleChangeSearch = async (e: any) => {
    const regex = /^[a-zA-Z0-9@.]*$/;
    if (regex.test(e.target.value)) {
      setSearchInput(e.target.value);
      let payload: any = {
        ...UrlParams,
        search: e.target.value,
      };
      let queryRequest = QueryRequestHelper(payload);
      navigate(`${window.location.pathname}?${queryRequest}`);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Pagination section                           */
  /* -------------------------------------------------------------------------- */

  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page_no, setPage_no] = useState(1);

  const handleChangePagination = (page_no: string) => {
    let payload = {
      ...UrlParams,
      page_no: page_no,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };
  const handleChangeLimit = (limit: string) => {
    let payload: any = {
      ...UrlParams,
      limit: limit,
      page_no: "1",
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  /* -------------------------------------------------------------------------- */
  /*                                 Module Info                                */
  /* -------------------------------------------------------------------------- */
  const findModuleType = (id: string) => {
    let info =
      getValue(allModules, `length`, 0) > 0
        ? allModules.find((item: object) => getValue(item, `id`, "") == id)
        : [];
    return info;
  };
  const moduleInfo = useMemo(
    () => findModuleType(moduleId),
    [allModules, moduleId]
  );
  useDynamicTitle(
    getValue(moduleInfo, `linkText`, "")
      ? getValue(moduleInfo, `linkText`, "")
      : "tickets"
  );

  console.log("List", list);

  return (
    <MainLayout {...props} isLoading={listLoading}>
      <TicketSubHeader
        subHeaderListTitle={getValue(moduleInfo, "linkText", "")}
        isRightArrowVisible={true}
        isSubmenuListAvailable={true}
        subMenuArray={subMenuArray}
        options={options}
        selectedPipeline={getSelectedPipeline}
        handleChangePipeline={handleChangePipeline}
        handleClickPipeline={handleClickPipeline}
        hideClose={!getValue(UrlParams, `pipeline`, "") ? true : false}
        moduleInfo={moduleInfo}
        //
        // toggleSidebar={toggleSidebar}
        params={params}
        hideSideBar
      />
      <TicketKanbanView list={list} />
    </MainLayout>
  );
};

export default TicketsKanban;
