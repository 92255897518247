import React, { useEffect, useState } from "react";
import { Select, SelectProps } from "antd";
import { getValue } from "@utils/lodash";
import { QueryRequestHelper } from "common/query-request-helper";
import { getLookupAPIs } from "../../helpers/lookup-apis";
import { DefaultOptionType } from "antd/lib/select";

interface EnhancedSelectProps extends Omit<SelectProps, "options"> {
  label?: string;
  width?: string | number;
  required?: boolean;
  validator?: any;
  lookup_api?: any;
  forModule?: string;
  module_id?: string;
  selectKey?: string;
  errorLabel?: string;
  add?: boolean;
  button_title?: string;
  handleOpenPopup?: () => void;
  labelKey?: string;
  options?: any[];
  hideLabel?: boolean;
  optionsData?: any;
}

const EnhancedSelect: React.FC<EnhancedSelectProps> = ({
  label,
  width = "100%",
  required = false,
  validator,
  lookup_api,
  forModule,
  module_id,
  selectKey = "value",
  errorLabel,
  add = false,
  button_title,
  handleOpenPopup,
  hideLabel,
  labelKey = "label",
  options: propOptions,
  optionsData,
  ...props
}) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  // Initialize data with provided options if any
  useEffect(() => {
    if (propOptions) {
      setData(propOptions);
    }
  }, [propOptions]);

  // Fetch data if lookup_api is provided
  useEffect(() => {
    if (lookup_api && !propOptions) {
      fetchData();
    }
  }, [lookup_api]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const payload = {
        page_no: 1,
        page_size: 10,
        forModule,
        module_id,
      };
      const queryRequest = QueryRequestHelper(payload);
      const response = await getLookupAPIs(lookup_api, queryRequest);

      if (response) {
        setData(response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Transform data to AntD Select options format
  const transformedOptions: DefaultOptionType[] = data.map((item) => ({
    label: getValue(item, labelKey, ""),
    value: getValue(item, selectKey, ""),
    data: item,
  }));

  // Custom dropdown render to add "Add" button if needed
  const dropdownRender = (menu: React.ReactElement) => {
    if (!add) return menu;

    return (
      <>
        {menu}
        <div
          style={{
            padding: "8px",
            borderTop: "1px solid #e8e8e8",
            cursor: "pointer",
          }}
          onClick={() => {
            handleOpenPopup?.();
          }}
        >
          <img
            src="/dropdown/add.svg"
            style={{ width: 16, height: 16, marginRight: 8 }}
            alt="add"
          />
          <span>{button_title}</span>
        </div>
      </>
    );
  };

  return (
    <div>
      {!hideLabel && (
        <p className="form-label">
          {label} {required && <span className="text-danger">*</span>}
        </p>
      )}
      <Select
        size="large"
        className={`w-100 ${required ? "ant-select-required" : ""}`}
        style={{ width }}
        showSearch
        loading={loading}
        optionFilterProp="label"
        dropdownRender={dropdownRender}
        options={optionsData ? optionsData : transformedOptions}
        allowClear
        filterOption={(input, option) =>
          (option?.label ?? "")
            .toString()
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        {...props}
      />
      {validator?.current?.message && (
        <p className="error-text">
          {validator.current.message(
            errorLabel || label,
            getValue(props, "value", ""),
            "required"
          )}
        </p>
      )}
    </div>
  );
};

export default EnhancedSelect;
