import { capitalizeFirstLetter, concatString } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { Badge } from "antd";
import { Facebook, File, Mail, Ticket } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

const TicketList = (props: any) => {
  const navigate = useNavigate();
  const params = useParams();
  return getValue(props, "ticketList", "").map((item: any, index: any) => {
    return (
      <div
        key={index}
        className={`${
          getValue(item, "id", "") === getValue(props, "selectedTicket", "")
            ? "ticket-detail-card--selected"
            : "ticket-detail-card"
        }`}
        onClick={() =>
          navigate(
            `/${getValue(params, "orgId", "")}/tickets/detail/${getValue(
              item,
              "id",
              ""
            )}`
          )
        }
      >
        <div className="ticket-detail-card-icon">
        <Ticket size={14} color="#fff" />
        </div>
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center gap-2 ">
            <h6 className="header_text__16 ">
              {capitalizeFirstLetter(getValue(item, "ticket_name", ""))}
            </h6>
            <p className="small_text__13 d-flex align-items-center gap-1">
              1hr
              {index % 2 === 0 && <Badge status="error" />}
            </p>
          </div>

          <p className="small_text__14 mb-2" style={{ fontWeight: "500" }}>
            Owner : {getValue(item, "owner.first_name", "")}{" "}
            {getValue(item, "owner.last_name", "")}
          </p>
          <p className="small_text__14">
            {concatString(getValue(item, "ticket_description", ""), 20)}
          </p>
        </div>
        <div></div>
      </div>
    );
  });
};

export default TicketList;
