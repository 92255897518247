import SearchToolTip from "@components/custom/Dropdown/SearchTooltip";
import { getValue } from "@utils/lodash";
import { Trash2 } from "lucide-react";
import React from "react";
import OptionFieldsBuilder from "../OptionFieldsBuilder";

const DefaultActions = ({
  index,
  request,
  setRequest,
  updateFields,
  item,
  valueTypes,
  handleIfCondition,
  validator,
  findInputType,
  removeConditionUpdate,
  handleDeleteOption,
}: any) => {
  return (
    <div key={index} className="sla-condition-card p-4 border-bottom">
      <div className="w-9 d-flex align-items-center gap-3">
        <div>
          <SearchToolTip
            width={220}
            label="label"
            name="Action"
            selectKey="value"
            data={updateFields}
            value={item.fieldId}
            onChange={(e: any) => handleIfCondition(index, e)}
            hideClose
            placeholder="Select Action"
            validator={validator}
            hideLabel
          />
        </div>
        {item.fieldId && (
          <div className="ms-2">
            <OptionFieldsBuilder
              item={findInputType(getValue(item, `fieldId`, ""))}
              automationInfo={item}
              parentIndex={index}
              request={request}
              setRequest={setRequest}
              value={item.value}
              validator={validator}
            />
          </div>
        )}
      </div>
      {getValue(request, "actions.updates.length", 0) > 1 && (
        <Trash2
          size={18}
          className="delete-sla-condition"
          onClick={() =>
            getValue(item, `id`, "")
              ? removeConditionUpdate(index, "updates")
              : handleDeleteOption(index, "updates")
          }
        />
      )}
    </div>
  );
};

export default DefaultActions;
