import AccountCircleSvgComponent from "@assets/svg/account-circle";
import CameraSvgComponent from "@assets/svg/camera";
import DocumentSvgComponent from "@assets/svg/document";
import GlobeSvgComponent from "@assets/svg/globe";
import MicSvgComponent from "@assets/svg/mic";
import VideoSvgComponent from "@assets/svg/video";
import WhatsappSvgComponent from "@assets/svg/whastapp";
import { getColorCode } from "@common/colors";
import { formatDatetoDay } from "@common/date-helpers";
import {
  replaceDefaultPlaceholdersBySingleLine,
} from "@common/markdown/markdown-helper";
import {
  concatString,
  formatString,
  getFirstLetterOfWord,
  getOnlyFirstLetterOfWord,
} from "@common/text-helpers";
import Loader from "@components/common/Loader/loading";
import ProfileCircle from "@components/common/ProfileCircle";
import { getValue } from "@utils/lodash";
import { Badge, Tooltip } from "antd";

function InboxContacts(props) {
  const {
    isLoading,
    conversations,
    selectedConversation,
    handleSelectConvesation,
    handleRemovePipelineInfo,
  } = props;

  return (
    <div className="chat-lits-wrapper">
      {isLoading ? (
        <Loader small />
      ) : getValue(conversations, `length`, 0) > 0 ? (
        conversations.map((item) => {
          return (
            <div
              className={`chat-list cursor-pointer ${selectedConversation === getValue(item, `id`, "")
                ? "active"
                : ""
                }`}
              onClick={() => {
                if (selectedConversation !== getValue(item, `id`, "")) {
                  handleRemovePipelineInfo();
                  handleSelectConvesation(item);
                }
              }}
            >
              <div className="nonselected-profile-image">
                {getValue(item, `contact.first_name`, "") ||
                  getValue(item, `contact.last_name`, "") ||
                  (getValue(item, `source`, "") === "webchat" &&
                    getValue(item, `web_name`, "")) ? (
                  <ProfileCircle
                    value={
                      getValue(item, `contact.first_name`, "") ||
                      (getValue(item, `source`, "") === "webchat"
                        ? getValue(item, `web_name`, "")
                        : "")
                    }
                    value2={getValue(item, `contact.last_name`, "")}
                  />
                ) : getValue(item, `wa_profile_name`, "") &&
                  !parseInt(getValue(item, `wa_profile_name`, "")) ? (
                  <ProfileCircle
                    value={getValue(item, `wa_profile_name`, "")}
                  />
                ) : (
                  <AccountCircleSvgComponent color={"#dfe5e7"} size={40} />
                )}
              </div>

              <div className="w-100 d-flex flex-column gap-1">
                <div className="d-flex justify-content-between align-items-center">
                  {((getValue(item, `contact.first_name`, "") ||
                    getValue(item, `contact.last_name`, "") ||
                    getValue(item, `web_name`, "")) &&
                    getValue(item, `source`, "") === "webchat") ? (
                    <p className="small_text__16">
                      {
                        getValue(item, `web_name`, "") ?
                          concatString(
                            formatString(
                              getValue(item, `web_name`, "")
                                ? getValue(item, `web_name`, "")
                                : ""), 18
                          ) :
                          concatString(
                            formatString(getValue(item, `contact.first_name`, "")),
                            8
                          ) + ' ' + concatString(
                            formatString(getValue(item, `contact.last_name`, "")),
                            8
                          )}
                    </p>
                  ) : (
                    <p className="small_text__16">
                      {concatString(
                        formatString(getValue(item, `wa_profile_name`, "")),
                        26
                      )}
                    </p>
                  )}
                  <div className="d-flex align-items-center">
                    {getValue(item, `source`, "") === "webchat" ? (
                      <GlobeSvgComponent size={15} color={"#408dfb"} />
                    ) : (
                      <WhatsappSvgComponent size={15} color={"#58d68d"} />
                    )}
                    <span className="time-noti ms-1">
                      {formatDatetoDay(getValue(item, `updated_at`, ""))}
                    </span>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p>
                    {
                      getValue(item, `last_msg.text`, "")
                        ? (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: replaceDefaultPlaceholdersBySingleLine(
                                concatString(
                                  getValue(item, `last_msg.text`, "") ?
                                    getValue(item, `last_msg.text`, "") : '',
                                  30
                                )
                              ),
                            }}
                            className="desc_text small_text__12"
                          ></p>
                        ) : getValue(item, `last_msg.type`, "") === "image" ? (
                          <div className="d-flex align-items-center">
                            <CameraSvgComponent size={18} color={"gray"} />
                            <p className="small_text__12 ms-1">Image</p>
                          </div>
                        ) : getValue(item, `last_msg.type`, "") === "audio" ? (
                          <div className="d-flex align-items-center">
                            <MicSvgComponent size={18} color={"gray"} />
                            <p className="small_text__12 ms-1">Audio</p>
                          </div>
                        ) : getValue(item, `last_msg.type`, "") === "video" ? (
                          <div className="d-flex align-items-center">
                            <VideoSvgComponent size={18} color={"gray"} />
                            <p className="small_text__12 ms-1">Video</p>
                          </div>
                        ) : getValue(item, `last_msg.type`, "") === "document" ? (
                          <div className="d-flex align-items-center">
                            <DocumentSvgComponent size={18} color={"gray"} />
                            <p className="small_text__12 ms-1">Document</p>
                          </div>
                        ) : (
                          <p className="desc_text small_text__12 color_gray">
                            {getValue(item, `source`, "") === "webchat"
                              ? ""
                              : "Hey there! I am using WhatsApp"}
                          </p>
                        )}
                  </p>
                  <div className="d-flex align-items-center">
                    {(getValue(item, `unread_count`, 0) > 0) && (
                      <Badge
                        color="#25d366"
                        count={

                          getValue(item, `unread_count`, 0)
                        }
                      />
                    )}
                    {(getValue(item, `org_user.first_name`, "") ||
                      getValue(item, `org_user.last_name`, "")) && (
                        <div className="ms-1">
                          <Tooltip
                            title={`${getValue(
                              item,
                              `org_user.first_name`,
                              ""
                            )}${" "}${getValue(item, `org_user.last_name`, "")}`}
                          >
                            <Badge
                              color={getColorCode(
                                getOnlyFirstLetterOfWord(
                                  getValue(item, `org_user.first_name`, "")
                                    ? getValue(item, `org_user.first_name`, "")
                                    : getValue(item, `org_user.last_name`, "")
                                )
                              )}
                              count={`${getFirstLetterOfWord(
                                getValue(item, `org_user.first_name`, "")
                              )}${getFirstLetterOfWord(
                                getValue(item, `org_user.last_name`, "")
                              )}`}
                            />
                          </Tooltip>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <p className="header_text__16 d-flex justify-content-center mt-5">
          No Conversations Found
        </p>
      )}
    </div>
  );
}

export default InboxContacts;
