import { config } from "env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const chatbotDocumentList = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/documents/list-links?${queryRequest}`);

export const chatbotDocumentText = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/documents/text?${queryRequest}`);

export const chatbotDocumentqna = (queryRequest: string) =>
  get(`${config.API_URL}/chatbot/documents/qna?${queryRequest}`);

export const trainChatbotDocument = (payload: object) =>
  post(`${config.API_URL}/chatbot/documents/train`, payload);

export const addChatbotDocumentLink = (payload: object) =>
  post(`${config.API_URL}/chatbot/documents/add/link`, payload);

export const deletechatbotDocumentList = (id: string) =>
  Delete(`${config.API_URL}/chatbot/documents/link/${id}`);
