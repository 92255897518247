import BrandingSvgComponent from "assets/svg/branding";
import PhoneSvgComponent from "assets/svg/custom/phone";
import RemoveCircleSvgComponent from "assets/svg/remove-circle";
import CopySvgComponents from "assets/svg/copy";
import { CopyToClipboard } from "@common/text-helpers";
import { getValue } from "@utils/lodash";
import { useNavigate, useParams } from "react-router-dom";
import PersonSvgComponent from "assets/svg/person";
import GraphSvgComponent from "assets/svg/graph";
import MailSvgComponent from "assets/svg/mail";
import DateSvgComponent from "@assets/svg/custom/date";
import { convertCurrentDate } from "@common/date-helpers";
import CurrencySvgComponent from "@assets/svg/custom/currency";
import { useStateContext } from "@context/profileProvider";
function DescriptionInfo(props: any) {
  const { index, item } = props;
  const navigate = useNavigate();
  const params = useParams();

  const handleNavigate = (id: string) => {
    navigate(
      `/${getValue(params, `orgId`, "")}/crm/${getValue(
        props,
        `module`,
        ""
      )}/detail/${id}`
    );
  };
  const getImages = (module: string) => {
    switch (module) {
      case "contacts":
        return <PersonSvgComponent size={22} color={"#3e6178"} />;
      case "sales":
      case "deals":
        return <GraphSvgComponent size={22} color={"#3e6178"} />;
      default:
        return <BrandingSvgComponent size={20} color={"#3e6178"} />;
    }
  };
  const { orgInfo } = useStateContext();
  return (
    <div>
      <li
        key={`pending-${index}`}
        className={`pendingtaskright-wrapper__company-details position-relative`}
      >
        <div className={"pending-task_userinformation__more-image_container"}>
          <div
            onClick={() =>
              props.handleDeleteView(
                getValue(item, `id`, ""),
                `${getValue(item, `name`, "")}`
              )
            }
          >
            <RemoveCircleSvgComponent />
          </div>
        </div>
        {getValue(item, `first_name`, "") || getValue(item, `last_name`, "") ? (
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={() => handleNavigate(getValue(item, `id`, ""))}
          >
            {getImages(getValue(props, `module`, ""))}
            <h6 className={"header_blue_text__14 ms-2"}>
              {getValue(item, `first_name`, "")}{" "}
              {getValue(item, `last_name`, "")}
            </h6>
          </div>
        ) : (
          !getValue(item, `name`, "") && (
            <div
              className="d-flex align-items-center cursor-pointer"
              onClick={() => handleNavigate(getValue(item, `id`, ""))}
            >
              {getImages(getValue(props, `module`, ""))}
              <h6 className={"header_blue_text__14 ms-2"}>Unknown</h6>
            </div>
          )
        )}
        {getValue(item, `name`, "") && (
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={() => handleNavigate(getValue(item, `id`, ""))}
          >
            {getImages(getValue(props, `module`, ""))}
            <h6 className={"header_blue_text__14 ms-2"}>
              {getValue(item, `name`, "")}
            </h6>
          </div>
        )}
        {getValue(item, `closing_date`, "") && (
          <div
            className="d-flex align-items-center cursor-pointer mt-1"
            onClick={() => handleNavigate(getValue(item, `id`, ""))}
          >
            <DateSvgComponent size={20} />
            <p className="small_text__14 ms-2">Closing Date : </p>
            <h6 className={"header_blue_text__14 ms-2"}>
              {convertCurrentDate(getValue(item, `closing_date`, ""))}
            </h6>
          </div>
        )}
        {getValue(item, `amount`, "") && (
          <div
            className="d-flex align-items-center cursor-pointer mt-1"
            onClick={() => handleNavigate(getValue(item, `id`, ""))}
          >
            <CurrencySvgComponent size={20} />
            <h6 className={"header_blue_text__14 ms-2"}>
              {getValue(orgInfo, `currency`, "")} {getValue(item, `amount`, "")}
            </h6>
          </div>
        )}
        {getValue(item, `email`, "") && (
          <div className="d-flex align-items-center">
            <MailSvgComponent size={20} color={"#3e6178"} />
            <p className="small_text__14 ms-2">{getValue(item, `email`, "")}</p>
            <div
              className="ms-2 cursor-pointer"
              onClick={() => CopyToClipboard(getValue(item, `email`, ""))}
            >
              <CopySvgComponents size={"20"} />
            </div>
          </div>
        )}
        {getValue(item, `phone_number`, "") && (
          <div className="d-flex align-items-center">
            <PhoneSvgComponent size={20} color={"#3e6178"} />
            <p className="small_text__14 ms-2">
              {getValue(item, `phone_number`, "")}
            </p>
          </div>
        )}
      </li>
    </div>
  );
}

export default DescriptionInfo;
