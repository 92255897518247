import React, { useEffect } from "react";
import { Tabs } from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getValue } from "@utils/lodash";
import { formatString } from "@common/text-helpers";

const AIAgentTabHeaders = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const orgId = getValue(params, "orgId", "");

  // const items = [
  //   {
  //     key: "playground",
  //     label: "Playground",
  //     route: `/${orgId}/chatbot/${getValue(
  //       params,
  //       "chatbotId",
  //       ""
  //     )}/ai/detail/playground`,
  //   },
  //   {
  //     key: "responses",
  //     label: "Responses",
  //     route: `/${orgId}/chatbot/${getValue(
  //       params,
  //       "chatbotId",
  //       ""
  //     )}/ai/detail/responses`,
  //   },
  //   {
  //     key: "training-data",
  //     label: "Training Data",
  //     route: `/${orgId}/chatbot/${getValue(
  //       params,
  //       "chatbotId",
  //       ""
  //     )}/ai/detail/training-data`,
  //   },
  //   {
  //     key: "sources",
  //     label: "Sources",
  //     route: `/${orgId}/chatbot/${getValue(
  //       params,
  //       "chatbotId",
  //       ""
  //     )}/ai/detail/sources`,
  //   },
  //   {
  //     key: "actions",
  //     label: "Actions",
  //     route: `/${orgId}/chatbot/${getValue(
  //       params,
  //       "chatbotId",
  //       ""
  //     )}/ai/detail/actions`,
  //   },
  //   {
  //     key: "map-to-channel",
  //     label: "Map to Channel",
  //     route: `/${orgId}/chatbot/${getValue(
  //       params,
  //       "chatbotId",
  //       ""
  //     )}/ai/detail/map-to-channel`,
  //   },
  //   {
  //     key: "share",
  //     label: "Share",
  //     route: `/${orgId}/chatbot/${getValue(
  //       params,
  //       "chatbotId",
  //       ""
  //     )}/ai/detail/share`,
  //   },
  //   {
  //     key: "settings",
  //     label: "Settings",
  //     route: `/${orgId}/chatbot/${getValue(
  //       params,
  //       "chatbotId",
  //       ""
  //     )}/ai/detail/settings`,
  //   },
  // ];

  const items = [
    {
      key: "playground",
      value: "Playground",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/playground`,
    },
    {
      key: "chat-logs",
      value: "Chat Logs",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/chat-logs`,
    },
    // {
    //   key: "training-data",
    //   label: "Training Data",
    //   route: `/${orgId}/chatbot/${getValue(
    //     params,
    //     "chatbotId",
    //     ""
    //   )}/ai/detail/training-data`,
    // },
    {
      key: "sources",
      value: "Sources",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/sources`,
    },
    {
      key: "actions",
      value: "Actions",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/actions`,
    },
    // {
    //   key: "map-to-channel",
    //   value: "Map to Channel",
    //   route: `/${orgId}/chatbot/${getValue(
    //     params,
    //     "chatbotId",
    //     ""
    //   )}/ai/detail/map-to-channel`,
    // },
    {
      key: "share",
      value: "Share",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/share`,
    },
    {
      key: "settings",
      value: "Settings",
      route: `/${orgId}/chatbot/${getValue(
        params,
        "chatbotId",
        ""
      )}/ai/detail/settings`,
    },
  ];

  const getActiveTab = () => {
    const path = location.pathname;
    const activeItem = items.find((item) => item.route === path);
    return activeItem?.key || "responses";
  };

  const handleTabChange = (key: any) => {
    const selectedTab = items.find((item) => item.key === key);
    if (selectedTab) {
      navigate(selectedTab.route);
    }
  };

  return (
    <div className={"tabWrapper"}>
      <div className={"tabContainer"}>
        {/* <Tabs
          items={items.map((item) => ({ key: item.key, label: item.label }))}
          activeKey={getActiveTab()}
          onChange={handleTabChange}
          className={"tabs"}
          tabBarStyle={{
            margin: 0,
          }}
          tabBarGutter={32}
          centered
        /> */}
        <div
          className="d-flex align-items-center justify-content-center pt-3"
          style={{ borderBottom: "1px solid #efefef" }}
        >
          {items.map((linkObj: object, index: number) => {
            return (
              <li
                key={`pending-${index}`}
                className={`pt-1 ${
                  getValue(location, `pathname`, "") ==
                  getValue(linkObj, `route`, "")
                    ? "activebar__main-body-list_active"
                    : "activebar__main-body-list"
                }`}
                onClick={() => navigate(getValue(linkObj, `route`, ""))}
              >
                <a className={"activebar__main-body-link p-2 "}>
                  {formatString(getValue(linkObj, `value`, ""))}
                </a>
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AIAgentTabHeaders;
