export const getUrlParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // Predefined keys for array values
  const arrayKeys = [
    "owner_ids_include[]",
    "owner_ids_not_include[]",
    "group_ids_include[]",
    "group_ids_not_include[]",
    "contact_ids_include[]",
    "contact_ids_not_include[]",
    "company_ids_include[]",
    "company_ids_not_include[]",
    "priority_ids_include[]",
    "priority_ids_not_include[]",
    "product_ids_include[]",
    "product_ids_not_include[]",
    "status_include[]",
    "status_not_include[]",
    "internal_types_include[]",
    "internal_types_not_include[]",
    "sources_include[]",
    "sources_not_include[]",
    "categories_include[]",
    "categories_not_include[]",
    "resolutions_include[]",
    "resolutions_not_include[]",
    "segments_include[]",
    "segments_not_include[]",
  ];

  const result: any = {};
  arrayKeys.forEach((key) => {
    result[key.replace(/\[\]$/, "")] = urlSearchParams.getAll(key);
  });

  // Handle non-array fields
  Object.keys(params).forEach((key) => {
    if (!arrayKeys.includes(key)) {
      result[key] = params[key];
    }
  });
  return result;
};

export const getUrlParamsObj = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  // Predefined keys for array values
  const arrayKeys = [
    "owner_ids_include[]",
    "owner_ids_not_include[]",
    "group_ids_include[]",
    "group_ids_not_include[]",
    "contact_ids_include[]",
    "contact_ids_not_include[]",
    "company_ids_include[]",
    "company_ids_not_include[]",
    "priority_ids_include[]",
    "priority_ids_not_include[]",
    "product_ids_include[]",
    "product_ids_not_include[]",
    "status_include[]",
    "status_not_include[]",
    "internal_types_include[]",
    "internal_types_not_include[]",
    "sources_include[]",
    "sources_not_include[]",
    "categories_include[]",
    "categories_not_include[]",
    "resolutions_include[]",
    "resolutions_not_include[]",
    "segments_include[]",
    "segments_not_include[]",
  ];

  const result: any = {};

  // Handle array keys
  arrayKeys.forEach((key) => {
    const values = urlSearchParams.getAll(key);
    const normalizedKey = key.replace(/\[\]$/, ""); // Remove '[]' for normalization
    if (values.length === 1) {
      result[normalizedKey] = values[0]; // Store as a single value if only one
    } else if (values.length > 1) {
      result[normalizedKey] = values; // Store as an array if multiple
    }
  });

  // Handle non-array fields
  Object.keys(params).forEach((key) => {
    if (!arrayKeys.includes(key)) {
      result[key] = params[key];
    }
  });
  console.log(result,"-----")
  return result;
};

export const filterInitialState = {
  filter_created_at: "",
  filter_close_date: "",
  filter_resolved_date: "",
  filter_due_by: "",
  filter_fr_due_by: "",
  filter_er_due_by: "",
  owner_ids_include: [],
  owner_ids_not_include: [],
  group_ids_include: [],
  group_ids_not_include: [],
  contact_ids_include: [],
  contact_ids_not_include: [],
  company_ids_include: [],
  company_ids_not_include: [],
  priority_ids_include: [],
  priority_ids_not_include: [],
  product_ids_include: [],
  product_ids_not_include: [],
  status_include: [],
  status_not_include: [],
  internal_types_include: [],
  internal_types_not_include: [],
  sources_include: [],
  sources_not_include: [],
  categories_include: [],
  categories_not_include: [],
  resolutions_include: [],
  resolutions_not_include: [],
  segments_include: [],
  segments_not_include: [],
};

export const filterModeState = {
  owner: "include",
  contact: "include",
  company: "include",
  group: "include",
  priority: "include",
  product: "include",
  status: "include",
  segments: "include",
  internal_types: "include",
  resolutions: "include",
  sources: "include",
  categories: "include",
};
