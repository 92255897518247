import { config } from "../env";
import { Delete, get, patch, post } from "./helpers/http-handler";

export const getAllAutomation = (queryRequest: string) =>
    get(`${config.API_URL}/automations?${queryRequest}`);

export const getAutomationsConditionFields = () =>
    get(`${config.API_URL}/automations/condition-fields`);

export const getAllAutomationsUpdateFields = () =>
    get(`${config.API_URL}/automations/update-fields`);

export const getSpecificAutomations = (id: string) =>
    get(`${config.API_URL}/automations/${id}`);

export const getAllAutomationsKeys = () =>
    get(`${config.API_URL}/automations/email/get-keys`);

export const createAutomation = (payload: object) =>
    post(`${config.API_URL}/automations/manage`, payload);

export const updateAutomationPosition = (payload: object) =>
    patch(`${config.API_URL}/automations/position/swap`, payload);

export const updateAutomationStatus = (payload: object) =>
    patch(`${config.API_URL}/automations/bulk/active-inactive`, payload);

export const deleteAutomation = (id: string, ) =>
    Delete(`${config.API_URL}/automations/${id}`);