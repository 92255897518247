import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getValue } from "@utils/lodash";
import { concatString, formatString } from "common/text-helpers";
import Popup from "reactjs-popup";
import { QueryRequestHelper } from "common/query-request-helper";
import { motion } from "framer-motion";
import DropdownCloseSvgComponent from "@assets/svg/dropdown-close";
import { getLookupAPIs } from "@components/helpers/lookup-apis";
import { useStateContext } from "@context/profileProvider";
import AddCircleSvgComponent from "@assets/svg/add-circle";
import RemoveCircleSvgComponent from "@assets/svg/remove-circle";
import { convertCurrentDate } from "@common/date-helpers";

const TicketMergeSearchToolTip = (props) => {
  const {
    label,
    id,
    value,
    onChange,
    width,
    selectKey,
    name,
    data,
    isLoading,
    disabled,
    mandatory,
    required,
    errorLabel,
    concatLength,
    hideLabel,
    module_id,
    showWhatsappIcon,
  } = props;
  const ref = useRef();
  const inputRef = useRef(null);
  const popupRef = useRef(null);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [inputChange, setInputChange] = useState(false);
  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (getValue(props, `lookup_api`, "")) {
      fetchDefaultData();
    }
  }, [getValue(props, `lookup_api`, "")]);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      if (popupRef.current) {
        popupRef.current.close();
      }
    } else {
    }
  };
  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    setQuery("");
  }, [props.resetInput]);

  useEffect(() => {
    if (query) {
      setInputChange(true);
    }
  }, [query]);

  /* -------------------------------------------------------------------------- */
  /*                                     API Section                            */
  /* -------------------------------------------------------------------------- */

  const handleClickOption = (option) => {
    if (!getValue(props, `disabled`, false)) {
      onChange && onChange(option);
    }
    setInputChange(false);
    setQuery("");
    if (popupRef.current) {
      popupRef.current.close();
    }
  };

  const onClickAdd = () => {
    props.handleOpenPopup();
  };
  /* -------------------------------------------------------------------------- */
  /*                                     API Section                            */
  /* -------------------------------------------------------------------------- */
  const { selectedModuleId, modules } = useStateContext();
  const [dataInfo, setData] = useState([]);
  const [isLoadingInfo, setIsLoading] = useState(true);
  const handleFetchData = async (search_text) => {
    const ticketInfo = modules.find(
      (item) => getValue(item, `api_name`, "") === "tickets"
    );
    if (search_text)
      try {
        let payload = {
          search: search_text,
          page_no: 1,
          page_size: 10,
          module_id: await selectedModuleId("tickets"),
        };
        setIsLoading(true);
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getLookupAPIs(
          getValue(props, `lookup_api`, ""),
          queryRequest
        );
        if (resp) {
          setData(resp);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    else onChange(null);
  };
  const fetchDefaultData = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 10,
        forModule: props.forModule,
        module_id: await selectedModuleId("tickets"),
      };
      setIsLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getLookupAPIs(
        getValue(props, `lookup_api`, ""),
        queryRequest
      );
      if (resp) {
        setData(resp);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* -------------------------------------------------------------------------- */
  /*                               Onchange section                             */
  /* -------------------------------------------------------------------------- */
  const filter = (options) => {
    return getValue(options, `length`, 0) > 0
      ? options.filter((option) => {
          const queryLower = query ? query.toLowerCase() : "";
          const labelValue = getValue(option, `[${label}]`, "");
          const countryValue = getValue(option, `country`, "");
          const valueValue =
            getValue(option, `value`, "") &&
            getValue(option, `value`, "").toString();

          // Check if labelValue is a string before calling .toLowerCase()
          const labelValueLower =
            typeof labelValue === "string" ? labelValue.toLowerCase() : "";
          // Check if valueValue is a string before calling .toLowerCase()
          const valueValueLower =
            typeof valueValue === "string" ? valueValue.toLowerCase() : "";

          const countryValueLower =
            countryValue && typeof countryValue === "string"
              ? countryValue.toLowerCase()
              : "";

          return (
            labelValueLower.indexOf(queryLower) > -1 ||
            valueValueLower.indexOf(queryLower) > -1 ||
            countryValueLower.indexOf(queryLower) > -1
          );
        })
      : [];
  };

  const findSelectedValue = (id) => {
    let list =
      getValue(
        getValue(props, `lookup_api`, "") ? dataInfo : data,
        `length`,
        0
      ) > 0
        ? getValue(props, `lookup_api`, "")
          ? dataInfo.filter(
              (item) =>
                getValue(item, `[${selectKey ? selectKey : "id"}]`, "") == id
            )
          : data.filter(
              (item) =>
                getValue(item, `[${selectKey ? selectKey : "id"}]`, "") == id
            )
        : [];
    return getValue(list, `length`, 0) > 0
      ? getValue(list, `[${0}].label`, "")
      : "";
  };
  const valueLabel = useMemo(() => findSelectedValue(value), [
    value,
    data,
    dataInfo,
    getValue(data, `length`, 0) > 0,
    getValue(dataInfo, `length`, 0) > 0,
    getValue(props, `custom_type`, ""),
  ]);

  const handleClose = (e) => {
    e.stopPropagation();
    onChange(null);
    if (popupRef.current) {
      popupRef.current.close();
    }
    document.body.style.overflow = "auto";
  };
  const handleOpen = () => {
    if (popupRef.current) {
      popupRef.current.open();
    }
    setClick(true);
    document.body.style.overflow = "hidden";
  };
  const POSITION_TYPES = [
    "bottom center",
    "top center",
    "left top",
    "left bottom",
    "right top",
    "top left",
    "top center",
    "right center",
    "right bottom",
    "bottom left",
    "bottom right",
    "left center",
    "center center",
  ];
  /* -------------------------------------------------------------------------- */
  /*                                Scroll section                              */
  /* -------------------------------------------------------------------------- */
  const selectedOptionRef = useRef(null);
  const [click, setClick] = useState(false);
  // Scroll to the selected option when the component updates
  useEffect(() => {
    if (selectedOptionRef.current) {
      selectedOptionRef.current.scrollIntoView({ behavior: "instant" });
      setClick(false);
    }
  }, [click]);
  /* -------------------------------------------------------------------------- */
  /*                           Dynamic Width section                            */
  /* -------------------------------------------------------------------------- */
  const [parentWidth, setParentWidth] = useState(null);
  const div = useCallback((node) => {
    if (node !== null) {
      setParentWidth(node.getBoundingClientRect().width);
    }
  });
  let defaultKey =
    getValue(props, `name_key`, "") &&
    getValue(props, `name_key`, "").split("_")[0];
  return (
    <>
      <div className={"dropdown-select-form"} ref={div}>
        {!hideLabel && name && (
          <label className="form-label">
            {name}{" "}
            {(getValue(props, `required`, false) ||
              getValue(props, `hasRequired`, false)) && (
              <span className=" small_text__16">*</span>
            )}
          </label>
        )}

        <Popup
          trigger={(open) =>
            props.arrow ? (
              <div
                className={`d-flex align-items-center ${
                  props.form ? "" : "px-3"
                } ${
                  value && valueLabel && !props.editInline
                    ? "inline_popup_active"
                    : ""
                }`}
              >
                <p className="touchable-text header_text">
                  {props.editInline
                    ? !valueLabel
                      ? `No ${formatString(defaultKey)}`
                      : concatString(valueLabel, 20)
                    : concatString(getValue(props, `title`, ""), 20)}
                </p>
                <img
                  src={
                    open
                      ? "/dropdown/arrow_up_filter.svg"
                      : "/dropdown/arrow_down_filter.svg"
                  }
                  className="inline_dropdown_image"
                />
                {((!props.editInline && valueLabel) || props.closeInline) &&
                  value && (
                    <div className="inline_close_image_container">
                      <img
                        src={"/dropdown/close.svg"}
                        className="inline_close_image"
                        onClick={(e) => handleClose(e)}
                        alt=""
                      />
                    </div>
                  )}
              </div>
            ) : (
              <div
                className={`popup-selected-value-box ${
                  open && !disabled ? "active" : ""
                }`}
                style={
                  props.validator &&
                  getValue(props, `required`, false) &&
                  !value
                    ? {
                        width: width,
                        marginLeft: props.add && "10px",
                        background: disabled ? "#F8F9F9" : "",
                        borderLeft:
                          getValue(props, `required`, false) ||
                          getValue(props, `hasRequired`, false)
                            ? "2px solid red"
                            : "",
                      }
                    : {
                        width: width,
                        marginLeft: props.add && "10px",
                        background: disabled ? "#F8F9F9" : "",
                        borderLeft:
                          getValue(props, `required`, false) ||
                          getValue(props, `hasRequired`, false)
                            ? "2px solid red"
                            : "",
                      }
                }
              >
                <div>
                  {valueLabel ? (
                    <p className="value">
                      {concatString(
                        valueLabel,
                        concatLength ? concatLength : 40
                      )}
                    </p>
                  ) : (
                    <p className="placeholder_text">
                      {getValue(props, `placeholder`, "")
                        ? getValue(props, `placeholder`, "")
                        : "Select"}
                    </p>
                  )}
                </div>
                <div className="right_container">
                  {valueLabel &&
                    !getValue(props, `disabled`, false) &&
                    !getValue(props, `hideClose`, false) && (
                      <div onClick={(e) => handleClose(e)}>
                        <DropdownCloseSvgComponent size={18} />
                      </div>
                    )}
                  <div className="arrow_image_container">
                    <img
                      src={
                        open && !disabled
                          ? "/dropdown/arrow_up.svg"
                          : "/dropdown/arrow_down.svg"
                      }
                      className="arrow_image"
                    />
                  </div>
                </div>
              </div>
            )
          }
          position={POSITION_TYPES}
          ref={popupRef}
          contentStyle={{
            width: parentWidth ? parentWidth : "280px",
            marginTop: "5px",
            padding: "0",
            zIndex: 9999,
            display: disabled && "none",
          }}
          onOpen={handleOpen}
          on={"click"}
          closeOnDocumentClick
          mouseLeaveDelay={300}
          mouseEnterDelay={0}
          onClose={() =>
            popupRef && popupRef.current && popupRef.current.close()
          }
          lockScroll
          type="tooltip"
          arrow={props.arrow ? props.arrow : false}
        >
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -20, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div
              className={`dropdown-select-form options ${isOpen ? "open" : ""}`}
            >
              <div className="popup_search_container">
                <img
                  src={"/dropdown/search.svg"}
                  className="search_image"
                  alt=""
                />
                <input
                  className="search_input"
                  ref={inputRef}
                  autoFocus
                  type="text"
                  value={query}
                  name="searchTerm"
                  autoComplete="off"
                  placeholder={getValue(props, `placeholder`, "search here...")}
                  onChange={(e) => {
                    setQuery(e.target.value);
                    handleFetchData(e.target.value);
                  }}
                  disabled={disabled}
                />
                {query && (
                  <div className="input_close_image_container">
                    <img
                      src={"/dropdown/close.svg"}
                      className="input_close_image"
                      onClick={(e) => {
                        setQuery("");
                      }}
                      alt=""
                    />
                  </div>
                )}
              </div>
              <div>
                <div className="popup_option_container">
                  {isLoadingInfo ? (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        marginBottom: "5px",
                      }}
                    >
                      <img
                        src={`/dropdown/loader1.gif`}
                        className="loader"
                        alt=""
                      />
                    </div>
                  ) : filter(dataInfo).length > 0 ? (
                    filter(dataInfo).map((option, index) => {
                      return (
                        <div className="p-2 cursor-pointer">
                          <div
                            onClick={() => {
                              handleClickOption(option);
                            }}
                            className={`d-flex justify-content-around"`}
                            key={`${id}-${index}`}
                          >
                            <div className="d-flex align-items-center">
                              {getValue(props, `selectedPrimaryTicket`, "") ===
                                getValue(option, `id`) ||
                              getValue(props, `selectedSecondaryTicket`, "") ===
                                getValue(option, `id`) ? (
                                <div>
                                  <RemoveCircleSvgComponent />
                                </div>
                              ) : (
                                <div>
                                  <AddCircleSvgComponent />
                                </div>
                              )}
                            </div>
                            <div className="ms-3">
                              <p>#{getValue(option, `record_num`, "")}</p>
                              <p>{getValue(option, `ticket_name`, "")}</p>
                              <p className="small_text__14">
                                Created On
                                {convertCurrentDate(
                                  getValue(option, `created_at`, "")
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="nodata">No Data Found</div>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        </Popup>
      </div>
    </>
  );
};

export default TicketMergeSearchToolTip;
